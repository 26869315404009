import { ExternalTicketData } from '../../../../generated/graphql';
import { TicketPreview } from './TicketPreview';
import Button, { ButtonVariant } from '../../../baseComponents/Button';

interface TicketLinkerProps {
  selectedTicketData: ExternalTicketData | null;
  linkTicket: ({ ticketData }: { ticketData: ExternalTicketData }) => Promise<void>;
  integrationLogo: string;
  integrationTitle: string;
  loading?: boolean;
}

export const TicketLinker = ({ selectedTicketData, linkTicket, integrationLogo, integrationTitle, loading }: TicketLinkerProps) => {
  return (
    <>
      {selectedTicketData ? (
        <div className="my-4">
          <h1>You have selected the following action item:</h1>
          <TicketPreview ticket={selectedTicketData} integrationLogo={integrationLogo} integrationTitle={integrationTitle} />
        </div>
      ) : null}
      <Button
        text={loading ? 'Linking action item...' : 'Link action item'}
        loadingConfirm={loading}
        expandWidth
        variant={ButtonVariant.Secondary}
        disabled={!selectedTicketData || loading}
        disabledTooltip="Please select a ticket to link."
        id="link-external-ticket-button"
        onClick={() => {
          if (selectedTicketData) linkTicket({ ticketData: selectedTicketData });
        }}
      />
    </>
  );
};

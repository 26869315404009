import { LightBulbIcon } from '@heroicons/react/24/outline';
import { ToolbarBaseButton } from './ToolbarBaseButton';

export function EditDigestButton({ onClick, buttonRounding }: { onClick: () => void; buttonRounding?: 'left' | 'right' | 'full'}) {
  return (
    <ToolbarBaseButton
      id="toolbar-copy-group-url-button"
      icon={<LightBulbIcon className="h-5 w-5" />}
      label={'Edit Digest'}
      onClick={onClick}
      buttonRounding={buttonRounding}
    />
  );
}

import { Dispatch } from 'react';

export enum SuggestedQuestionsStatus {
  loading,
  done,
  failed,
}

export enum SuggestedQuestionsActions {
  LOAD_SUGGESTED_QUESTIONS,
  UPDATE_SUGGESTED_QUESTIONS,
  UPDATE_SUGGESTED_QUESTIONS_STATUS
}

export interface SuggestedQuestionsState {
  load: boolean;
  questions: string[];
  page: number,
  status: SuggestedQuestionsStatus;
}

interface UpdateSuggestedQuestionsPayload {
  questions: string[];
}
interface UpdateSuggestedQuestionsStatusPayload {
  status: SuggestedQuestionsStatus;
}

type LoadSuggestedQuestionsPayload = {
  page: number;
};

type Payload = UpdateSuggestedQuestionsPayload | UpdateSuggestedQuestionsStatusPayload | LoadSuggestedQuestionsPayload;

export interface UpdateSuggestedQuestionsAction {
  type: SuggestedQuestionsActions;
  payload: Payload;
}

export type SuggestedQuestionsDispatch = Dispatch<{
  type: SuggestedQuestionsActions;
  payload: Payload
}>;


export const SuggestedQuestionsReducer = (state: SuggestedQuestionsState, action: UpdateSuggestedQuestionsAction) => {

  switch(action.type) {
    case SuggestedQuestionsActions.LOAD_SUGGESTED_QUESTIONS: {
      const page = (action.payload as LoadSuggestedQuestionsPayload).page;
      return {
        ...state,
        load: true,
        page: page
      }
    }

    case SuggestedQuestionsActions.UPDATE_SUGGESTED_QUESTIONS: {
      return {
        ...state,
        load: false,
        status: SuggestedQuestionsStatus.done,
        questions: (action.payload as UpdateSuggestedQuestionsPayload).questions,
      }
    }

    case SuggestedQuestionsActions.UPDATE_SUGGESTED_QUESTIONS_STATUS: {
      const status = (action.payload as UpdateSuggestedQuestionsStatusPayload).status;
      return {
        ...state,
        status: status
      }
    }

    default: {
      return state;
    }
  }
}
import { Ancestry, TaxonomyGroup } from '../../../v2/hooks/GroupHook';
import { useContext } from 'react';
import { TaxonomyContext } from '../../context/TaxonomyContext';
import { getGroupPageUrl } from '../../lib/groups';
import { useValidTeamAppContext } from '../../../v2/contexts/AppContext';
import { AppRoutes } from '../../../Routes';

export function GroupBreadcrumbs(props: { group: TaxonomyGroup}) {
  const {  curTeamId: teamId, curOrgId: orgId, currentOrg } = useValidTeamAppContext();

  const taxonomy = useContext(TaxonomyContext);
  let groups = taxonomy.get(props.group.id)?.ancestors ?? [];
  const {group} = props;

  groups = groups.concat({id: group.id, title: group.title!});

  return <p className="text-gray-400 text-sm">
    {
      groups.map((group, index) => {
        const groupPageUrl = getGroupPageUrl(teamId, orgId, group.id, AppRoutes.v3FullPath.explore);
        return <span key={group.id}>
          <a href={groupPageUrl} className={'hover:text-white hover:pointer'}>{group.title}</a>
          {(index < (groups.length - 1)) && ' > '}
        </span>
      })
    }
  </p>;
}
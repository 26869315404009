import { FeedbackSegmentInput, SegmentGroupQuery } from '../../../generated/graphql';
import { IFilterRow } from '../../../v3/sections/Filters/FiltersTypes';
import { CSVError, CSVRow, HeaderCSVRow, HeaderEntry, HeaderSegmentsRow, ParsedCSV, SegmentMetaData } from '../csvTypes';
import { FeedbackSegmentConfig } from '../csvUploader';

/**
 * @description provides utilties to `csvUploader` hook for populating a ParsedCSV with segments data
 */
export class FormatCSVSegments {
  /**
   * Generates meta data (from csv obj) necessary for creating segment groups on the api.
   */
  public generateSegmentGroupMetadata(csvWithNoSegmentMetadata: ParsedCSV, segmentMetaDataAsFilters: IFilterRow[]): ParsedCSV {
    const headerSegmentRow: HeaderSegmentsRow | undefined = csvWithNoSegmentMetadata.segmentHeader;

    // case 1) no segments exist
    if (!headerSegmentRow || headerSegmentRow.segHeader.length === 0) {
      throw new CSVError(`Unexpected Error: Trying to create SegmentGroupMeta when no segments exist or are selected`);
    }

    // case 2) segment data exists, populate the segmentsHeader with metadata
    const populatedHeaderSegmentRow: HeaderEntry[] = headerSegmentRow.segHeader.map((segHeader: HeaderEntry) => {
      const matchingFilter: IFilterRow = this.getFilterMatchingColumn(segHeader.fieldTitle, segmentMetaDataAsFilters);
      return {
        ...segHeader,
        segmentMetaData: {
          shouldUpload: matchingFilter.isSelected,
          segmentGroup: matchingFilter.filterName,
          doesSegGroupExist: matchingFilter.doesFilterCategoryExist,
        } as SegmentMetaData,
      } as HeaderEntry;
    });

    return { ...csvWithNoSegmentMetadata, segmentHeader: { segHeader: populatedHeaderSegmentRow } };
  }

  /**
   * @description updates `csvWithSegmentMetaData` to contain the groupIDs matched from `segGroups`
   * @param csvWithSegmentMetaData CSV containing segmentGroup names but not segmentGroupIds
   * @param segGroups an array of segmentGroups that contains SegmentGroupIds
   * @returns a ParsedCSV populated with SegmentGroupIds
   * @throws CSVError if there are no segments in the CSV or the SegmentGroupQuery
   */
  public populateSegmentGroupIds(csvWithSegmentMetaData: ParsedCSV, segGroups: SegmentGroupQuery | undefined): ParsedCSV {
    // case 0) segments do not exist
    if (!csvWithSegmentMetaData.segmentHeader || csvWithSegmentMetaData.segmentHeader.segHeader.length === 0 || !segGroups || !segGroups.segments) {
      throw new CSVError(`Unexpected Error: Attempting to populate segments when segments do not exist`);
    }

    const segHeaderWithGroupIds: HeaderEntry[] = csvWithSegmentMetaData.segmentHeader.segHeader
      .filter((seg) => seg.segmentMetaData?.shouldUpload)
      .map((segment: HeaderEntry) => {
        // get db group that matches the segment
        const targetGroupId = segGroups.segments!.filter((seg) => seg.displayName === segment.segmentMetaData?.segmentGroup).at(0)?.id;

        // case 1) no matching group in db
        if (!targetGroupId) {
          throw new CSVError(`Unexpected Error: Unable to match ${segment.fieldTitle} to a Group in the DB`);
        }

        // case 2) segment group in db exists
        return {
          ...segment,
          segmentMetaData: {
            ...segment.segmentMetaData,
            groupId: targetGroupId,
          } as SegmentMetaData,
        } as HeaderEntry;
      }) as HeaderEntry[];

    return { ...csvWithSegmentMetaData, segmentHeader: { segHeader: segHeaderWithGroupIds } };
  }

  /**
   * @description helper to be called in `uploadIntegrationAndConfigIds`
   * @param csvWithGroupIds a CSV containing groupIds from the `populateSegmentGroupIds`
   * @param configs the newly written `FeedbackSegmentConfig`s
   * @returns ParsedCSV with populated ConfigIds
   */
  public populateConfigIds(csvWithGroupIds: ParsedCSV, configs: FeedbackSegmentConfig[]): ParsedCSV {
    // case 0) no segments exists
    if (!csvWithGroupIds.segmentHeader) {
      throw new CSVError(`Unexpected Error: Calling populate ConfigIds but csv has no segments`);
    }

    // case 1) segments exist
    const csvWithConfigIds = csvWithGroupIds.segmentHeader?.segHeader
      .filter((seg) => seg.segmentMetaData?.shouldUpload)
      .map((seg) => {
        const targetConfig: FeedbackSegmentConfig | undefined = configs.filter((feedbackSegConfig) => feedbackSegConfig.path === seg.fieldTitle).at(0);

        // case 1) segmentConfig was not written correctly during createFeedbackIntegration step
        if (!targetConfig) {
          throw new CSVError(`Unexpected Error: Unable to match ${seg.fieldTitle} to a config in the DB`);
        }

        // case 2) targetConfigs exist
        return {
          ...seg,
          segmentMetaData: {
            ...seg.segmentMetaData,
            configId: targetConfig.id,
          } as SegmentMetaData,
        } as HeaderEntry;
      }) as HeaderEntry[];

    return { ...csvWithGroupIds, segmentHeader: { segHeader: csvWithConfigIds } };
  }

  /**
   * @description formats the row's segments to be uploaded through `FeedbackEntriesUpload` Mutation
   * @param row the csv row being uploaded
   * @param segmentHeader the csv's segment headers
   * @returns an `FeedbackSegmentInput[]` representing the segment values to be uploaded
   */
  public createFeedbackSegmentInput(row: CSVRow, segmentHeader: HeaderEntry[] | undefined, concatenationSeparator: string): FeedbackSegmentInput[] {
    // case 0) no segments exist
    if (!segmentHeader || !row.segments) {
      return [] as FeedbackSegmentInput[];
    }

    // step 1) generate `FeedbackSegmentInput[]` for Entries Upload
    const segmentInputs: FeedbackSegmentInput[] = row.segments
      // remove segments that should not be uploaded
      .filter((seg) => {
        const matchingHeader = segmentHeader.filter((header) => header.fieldTitle === seg.name).at(0);
        return matchingHeader?.segmentMetaData?.shouldUpload;
      })
      .flatMap((seg) => {
        // step 2A) find matching meta data
        const matchingHeader = segmentHeader.filter((header) => header.fieldTitle === seg.name).at(0);

        // step 3) verify the segment values are non-null
        if (!matchingHeader) throw new CSVError(`Unexpected Error: Segment ${seg.name} has no matching header`);
        if (!matchingHeader.segmentMetaData) throw new CSVError(`Unexpected Error: Segment ${seg.name} has no meta data`);
        if (!matchingHeader.segmentMetaData.configId) throw new CSVError(`Unexpected Error: Segment ${seg.name} has no config ID`);
        if (!matchingHeader.segmentMetaData.groupId) throw new CSVError(`Unexpected Error: Segment ${seg.name} has no group ID`);

        // step 4) create Segment
        return seg.value
          .split(concatenationSeparator)
          .filter((value) => value !== '')
          .map((value) => {
            return {
              // The '!' is because typescript is dumb and can't see that we've validated the segmentMetaData above.
              feedbackSegmentConfigId: matchingHeader.segmentMetaData!.configId,
              feedbackSegmentGroupId: matchingHeader.segmentMetaData!.groupId,
              value: value,
            } as FeedbackSegmentInput;
          });
      }) as FeedbackSegmentInput[];

    return segmentInputs;
  }

  /**
   * @description helper function for `createSegmentGroupMetadata` to match `segmentGroup` names from filters in `SegmentConfigTable`
   * @param columnName the CSV Column name
   * @param filters the filter table from `SegmentConfigTable`
   * @returns the row containing the filter
   * @throws CSVError if there is no match between the csvColumn and any Filter Row
   */
  private getFilterMatchingColumn(columnName: string, filters: IFilterRow[]): IFilterRow {
    // get the filter which matches the given segmentConfig
    const filterWithSegment: IFilterRow | undefined = filters.filter((filter) => filter.filterSource === columnName).at(0);

    // case 1) No match (this should never happen since each column is mapped to a filter)
    if (!filterWithSegment) throw new CSVError(`Unexpected Upload Error: Unable to match column name ${columnName} to a filter`);

    // case 2) Match exists, return this filter
    return filterWithSegment;
  }
}

import { useEffect } from 'react';
import { useNavigate, useLocation, Routes, Route } from 'react-router-dom';
import { AppRoutes } from '../../../Routes';
import { Group_Status } from '../../../generated/graphql';
import { useValidTeamAppContext } from '../../../v2/contexts/AppContext';
import { getFiltersFromUrl } from '../../../v2/util';
import { useExploreGroupHook } from '../../hooks/ExploreGroupHook';
import { useFilterHook } from '../../hooks/FilterHook';
import { denominatorOptionsDropdown, DenominatorOptions } from '../../sections/Filters/FiltersTypes';
import { ExplorePage } from '../ExplorePage';
import GroupPage from '../GroupPage';
import { getGroupPageUrl } from '../../lib/groups';

interface ExplorePageRouterProps {
  pageName: string;
}

export const pageSize = 10;

export const ExplorePageRouter = ({ pageName }: ExplorePageRouterProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { curTeamId: teamId, currentTeam, curOrgId: orgId, currentOrg } = useValidTeamAppContext();

  const urlSearchParams = new URLSearchParams(location.search);
  const expanded = urlSearchParams.get('expanded');

  const filterHook = useFilterHook({ teamId, orgId, disableGroupFilterFromUrl: expanded === 'drawer' });

  const groupHook = useExploreGroupHook({
    teamId,
    orgId,
    teamName: currentTeam?.name,
    orgName: currentOrg?.name,
    pageName,
    filterInput: filterHook.filters,
    status: Group_Status.Monitored,
    selectedDenominator: denominatorOptionsDropdown.find((d) => d.name === DenominatorOptions.DateFilteredFeedback),
    pageSize: pageSize,
    sentencesTake: 0,
  });

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search);
    const filtersFromUrl = getFiltersFromUrl('group', teamId);
    if (!filtersFromUrl) return;
    const expanded = urlSearchParams.get('expanded');
    if (expanded === 'drawer' && filtersFromUrl.groupFilter && filtersFromUrl.groupFilter[0] && filtersFromUrl.groupFilter[0].group[0].id) {
      const groupId = filtersFromUrl.groupFilter[0].group[0].id;
      const filtersToForward = { ...filtersFromUrl, groupFilter: undefined };
      navigate(getGroupPageUrl(teamId, orgId, groupId, AppRoutes.v3FullPath.explore, [`&group=${JSON.stringify(filtersToForward)}`]), {
        replace: true,
      });
    }
  }, []);

  useEffect(() => {
    // we need to unset group when we navigate away from the subroute via any method
    if (!location.pathname.includes('group')) {
      groupHook.setCurrentGroup(undefined);
    }
  }, [location.pathname, groupHook.currentGroup]);

  return (
    <div>
      <ExplorePage pageName={pageName} groupHook={groupHook} filterHook={filterHook} />
      <Routes>
        <Route path="/group/:groupId" element={<GroupPage groupHook={groupHook} currentFilters={filterHook.filters} />} />
      </Routes>
    </div>
  );
};

import { ExternalTicketFragment } from '../../generated/graphql';

export interface ExternalTicketsState {
  selectedIntegrationId: number | null;
  externalTickets: ExternalTicketFragment[];
}

export const initialExternalTicketsState: ExternalTicketsState = {
  selectedIntegrationId: null,
  externalTickets: [],
};

export enum ExternalTicketsActionTypes {
  SET_SELECTED_INTEGRATION = 'SET_SELECTED_INTEGRATION',
  SET_EXTERNAL_TICKETS = 'SET_EXTERNAL_TICKETS',
  REMOVE_EXTERNAL_TICKET = 'REMOVE_EXTERNAL_TICKET',
}

type SetViewPayload = {
  newView: 'list' | 'link';
};

type SetSelectedIntegrationPayload = {
  integrationId: number | null;
};

type SetExternalTicketsPayload = {
  tickets: ExternalTicketFragment[];
};

type RemoveExternalTicketPayload = {
  ticketId: number;
};

export type ExternalTicketsAction =
  | { type: ExternalTicketsActionTypes.SET_SELECTED_INTEGRATION; payload: SetSelectedIntegrationPayload }
  | { type: ExternalTicketsActionTypes.SET_EXTERNAL_TICKETS; payload: SetExternalTicketsPayload }
  | { type: ExternalTicketsActionTypes.REMOVE_EXTERNAL_TICKET; payload: RemoveExternalTicketPayload };

export const ExternalTicketsReducer = (state: ExternalTicketsState, action: ExternalTicketsAction): ExternalTicketsState => {
  switch (action.type) {
    case ExternalTicketsActionTypes.SET_SELECTED_INTEGRATION:
      return setSelectedIntegration(state, action.payload);
    case ExternalTicketsActionTypes.SET_EXTERNAL_TICKETS:
      return setExternalTickets(state, action.payload);
    case ExternalTicketsActionTypes.REMOVE_EXTERNAL_TICKET:
      return removeExternalTicket(state, action.payload);
    default:
      throw new Error('Unhandled action type');
  }
};

const setSelectedIntegration = (state: ExternalTicketsState, payload: SetSelectedIntegrationPayload): ExternalTicketsState => {
  return { ...state, selectedIntegrationId: payload.integrationId };
};

const setExternalTickets = (state: ExternalTicketsState, payload: SetExternalTicketsPayload): ExternalTicketsState => {
  return { ...state, externalTickets: payload.tickets };
};

const removeExternalTicket = (state: ExternalTicketsState, payload: RemoveExternalTicketPayload): ExternalTicketsState => {
  return {
    ...state,
    externalTickets: state.externalTickets.filter((ticket) => ticket.id !== payload.ticketId),
  };
};

import { useContext, useReducer } from 'react';
import { PageWrapper } from './PageWrapper';
import { DigestDataContext, DigestDataDispatchContext } from '../../context/digestDataContext';
import { DigestDataReducer, DigestObjectType, DigestSeriesDataType, getInitialState } from '../../reducers/digests/digestDataReducer';
import Badge from '../components/Badge';
import DigestSideBarSection from '../components/digests/DigestSidebarSection';
import DigestMainSection from '../components/digests/DigestMainSection';
import DigestSeriesMainSection from '../components/digests/DigestSeriesMainSection';

const DigestPage = () => {
  const [digestData, dispatchDigestData] = useReducer(DigestDataReducer, {}, getInitialState);

  return (
    <DigestDataContext.Provider value={digestData}>
      <DigestDataDispatchContext.Provider value={dispatchDigestData}>
        <PageWrapper title="">
          <div className="gap-y-4">
            <div className="grid grid-cols-12 rounded-3xl w-full">
              <div className="col-span-3 border-r border-gray-100 pr-2">
                <DigestSideBarSection />
              </div>
              <div className="col-span-9 pl-4 h-screen flex flex-col">
                <DigestMainLayoutSection />
              </div>
            </div>
          </div>
        </PageWrapper>
      </DigestDataDispatchContext.Provider>
    </DigestDataContext.Provider>
  );
};

const DigestMainLayoutSection = () => {
  const digestData = useContext(DigestDataContext);
  if (!digestData.selectedDigestObject) {
    return <></>;
  }
  if (digestData.selectedDigestObjectType === DigestObjectType.Series && digestData.selectedDigestObject) {
    return <DigestSeriesMainSection digestSeriesObject={digestData.selectedDigestObject as DigestSeriesDataType} />;
  } else {
    return <DigestMainSection />;
  }
};

export default DigestPage;


export const TextLoading = () => <div data-testid={'text-loading'} className="w-full animate-pulse px-4 py-5 bg-gray-50 rounded-lg min-w-[600px]">
  <div className={'animate-expand-slow'}>
    <div
      className="block w-[100%] h-2 mb-4 font-sans text-5xl antialiased font-semibold leading-tight tracking-normal bg-gray-500 rounded-full text-inherit">
      &nbsp;
    </div>
    <div
      className="block h-2 mb-3 font-sans text-base antialiased font-light leading-relaxed bg-gray-500 rounded-full text-inherit w-[70%]">
      &nbsp;
    </div>
    <div
      className="block h-2 mb-3 font-sans text-base antialiased font-light leading-relaxed bg-gray-500 rounded-full text-inherit w-[95%]">
      &nbsp;
    </div>
    <div
      className="block h-2 mb-2 font-sans text-base antialiased font-light leading-relaxed bg-gray-500 rounded-full text-inherit w-[60%]">
      &nbsp;
    </div>
    <div
      className="block h-2 mb-2 font-sans text-base antialiased font-light leading-relaxed bg-gray-500 rounded-full text-inherit w-[70%]">
      &nbsp;
    </div>
  </div>
</div>
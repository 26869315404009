import { Auth } from 'aws-amplify';

export interface IAuthConfig {
  issuer: string;
  userPoolId: string;
  /**
   *  Be careful when adding this! This will prevent new users from signing in with email and password!
   *  This is the externalIDP that the user will be redirected to for sign in.
   */
  externalIDP?: string;
  userPoolWebClientId: string;
  tenantId?: string;
  cognitoDomain?: string;
  identityProviderId?: string;
  emailDomain?: string;
  redirectSignIn?: string;
}

export const STANDARD_ISSUER = 'standard';
export const ISSUER_KEY = 'issuer';
export const TENANT_KEY = 'tenant';

/**
 * AuthConfigs
 */
export const AuthConfigs: IAuthConfig[] = [
  {
    /** This is the standard config used by non-okta users */
    issuer: STANDARD_ISSUER,
    userPoolId: process.env.REACT_APP_USER_POOL_ID!,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID!,
    cognitoDomain: process.env.REACT_APP_COGNITO_DOMAIN,
    redirectSignIn: `${window.location.origin}/auth/google`,
  },
  {
    /** Unwrap account okta config for okta org dev-43763926 member - these are different depending on stage */
    // http://localhost:3000/login/okta?iss=dev-43763926.okta.com&tenant=unwrap-test-member
    // https://alpha-ui.unwrap.ai/login/okta?iss=dev-43763926.okta.com&tenant=unwrap-test-member
    issuer: process.env.REACT_APP_OKTA_ISSUER ?? '',
    userPoolId: process.env.REACT_APP_OKTA_USER_POOL_ID ?? '',
    userPoolWebClientId: process.env.REACT_APP_OKTA_USER_POOL_CLIENT_ID ?? '',
    cognitoDomain: process.env.REACT_APP_OKTA_COGNITO_DOMAIN ?? '',
    identityProviderId: process.env.REACT_APP_OKTA_IDENTITY_PROVIDER_ID ?? '',
    tenantId: 'unwrap-test-member',
    redirectSignIn: `${window.location.origin}/auth/okta`,
  },
  {
    /** Unwrap Prod account okta config for lyft org */
    emailDomain: 'lyft.com',
    issuer: 'lyft.okta.com',
    externalIDP: 'Okta',
    userPoolId: 'us-east-2_MKxSOINwA',
    userPoolWebClientId: '3vl5d5c9oihiecujip9r143fhr',
    cognitoDomain: 'lyft.auth.prod.unwrap.ai',
    identityProviderId: 'produnwrapservicOktaAuth05E9DE10',
    tenantId: 'lyft-driver-member',
    redirectSignIn: `${window.location.origin}/auth/okta`,
  },
  {
    /** Unwrap Prod account okta config for github org */
    emailDomain: 'github.com',
    issuer: 'github.okta.com',
    externalIDP: 'Okta',
    userPoolId: 'us-east-2_wqlttQ7Ho',
    userPoolWebClientId: 'al612hetb9802i58trettddsi',
    cognitoDomain: 'github.auth.prod.unwrap.ai',
    identityProviderId: 'produnwrapservicOktaAuthD2576B8B',
    tenantId: 'github-member',
    redirectSignIn: `${window.location.origin}/auth/okta`,
  },
  {
    /** Unwrap Prod account okta config for github org */
    emailDomain: 'github.com',
    issuer: 'github.okta.com',
    externalIDP: 'Okta',
    userPoolId: 'us-east-2_wqlttQ7Ho',
    userPoolWebClientId: 'al612hetb9802i58trettddsi',
    cognitoDomain: 'github.auth.prod.unwrap.ai',
    identityProviderId: 'github-saml',
    tenantId: 'github-saml',
    redirectSignIn: `${window.location.origin}/auth/okta`,
  },
  {
    emailDomain: 'jordan-william.com',
    issuer: 'jordan-william.google.com',
    externalIDP: 'Google',
    userPoolId: 'us-east-2_tcqV7HMi4',
    userPoolWebClientId: '3vkti5adlhhp9op24tlr8ravqq',
    cognitoDomain: 'jordanwilliam-test.auth.us-east-2.amazoncognito.com',
    identityProviderId: 'jordanwilliam-saml',
    tenantId: 'jordanwilliam-saml',
    redirectSignIn: `${window.location.origin}/auth/session/jordanwilliam-saml`,
  },
  {
    emailDomain: 'ouraring.com',
    issuer: 'ouraring.com',
    userPoolId: 'us-east-2_7SV0raxBd',
    userPoolWebClientId: '1ts9cnmv4o3j9p3b5vgoiaehnt',
    cognitoDomain: 'oura.auth.us-east-2.amazoncognito.com',
    identityProviderId: 'oura-saml',
    tenantId: 'oura-saml',
    redirectSignIn: `${window.location.origin}/auth/session/oura-saml`,
  },
  {
    /** Unwrap Prod account okta config for procore org SAML */
    // emailDomain: 'procore.com',
    issuer: 'procore.okta.com',
    externalIDP: 'Okta',
    userPoolId: 'us-east-2_wf5uRZhPP',
    userPoolWebClientId: '516h463t1c6s8j2p63cmv50ff7',
    cognitoDomain: 'procore.auth.us-east-2.amazoncognito.com',
    identityProviderId: 'procore-saml',
    tenantId: 'procore-saml',
    redirectSignIn: `${window.location.origin}/auth/okta`,
  },
  {
    /** Unwrap Prod account okta config for Weave org SAML */
    // emailDomain: 'weave.com',
    issuer: 'weaveworkforce.okta.com',
    externalIDP: 'Okta',
    userPoolId: 'us-east-2_l5FxbNoXT',
    userPoolWebClientId: '53at1b054gpie1j9oqff20olas',
    cognitoDomain: 'weave-saml.auth.us-east-2.amazoncognito.com',
    identityProviderId: 'weave-saml',
    tenantId: 'weave-saml',
    redirectSignIn: `${window.location.origin}/auth/okta`,
  },
  {
    /** Unwrap Prod account okta config for Honeybook org SAML */
    // emailDomain: 'weave.com',
    issuer: 'honeybook.okta.com',
    externalIDP: 'Okta',
    userPoolId: 'us-east-2_rCAgipCKP',
    userPoolWebClientId: '6lvcb1rhd1npigtk6e95tsotkp',
    cognitoDomain: 'honeybook-saml.auth.us-east-2.amazoncognito.com',
    identityProviderId: 'honeybook-saml',
    tenantId: 'honeybook-saml',
    redirectSignIn: `${window.location.origin}/auth/okta`,
  },
  {
    //  Unwrap Prod account config for lululemon org SAML
    issuer: 'lulu.azure.com',
    externalIDP: 'Azure',
    userPoolId: 'us-east-2_msz3GQcBe',
    userPoolWebClientId: '71s5vhodfkrep4tj4ied5s5i8d',
    cognitoDomain: 'lulu.auth.us-east-2.amazoncognito.com',
    identityProviderId: 'lulu-saml',
    tenantId: 'lulu-saml',
    redirectSignIn: `${window.location.origin}/auth/okta`,
  },
  {
    //https://amplitude.okta.com
    // emailDomain: 'amplitude.com',
    issuer: 'amplitude.okta.com',
    externalIDP: 'Okta',
    userPoolId: 'us-east-2_9QyqannQf',
    userPoolWebClientId: '3g0pd3089sjqmq1dqcetblouer',
    cognitoDomain: 'amplitude-saml.auth.us-east-2.amazoncognito.com',
    identityProviderId: 'amplitude-saml',
    tenantId: 'amplitude-saml',
    redirectSignIn: `${window.location.origin}/auth/okta`,
  },
  {
    issuer: 'bclc.sso.com',
    externalIDP: 'Okta',
    userPoolId: 'us-east-2_ewKUJLyqs',
    userPoolWebClientId: '462a598b6dv6jetkkd8gjjtfkt',
    cognitoDomain: 'bclc.auth.us-east-2.amazoncognito.com',
    identityProviderId: 'bclc-saml',
    tenantId: 'bclc-saml',
    redirectSignIn: `${window.location.origin}/auth/sso`,
  },
  {
    emailDomain: 'stripe.com',
    issuer: 'stripe.com',
    externalIDP: 'Stripe',
    userPoolId: 'us-east-2_ErRsb04pO',
    userPoolWebClientId: '3mlgrrbqbpc9n6cfj6j8g8l3rp',
    cognitoDomain: 'stripe.auth.us-east-2.amazoncognito.com',
    identityProviderId: 'stripe-saml',
    tenantId: 'stripe-saml',
    redirectSignIn: `${window.location.origin}/auth/sso`,
  },
];

export const getAuthConfigByEmailDomain = (email: string): IAuthConfig | undefined => {
  const emailDomain = email.split('@')[1];
  return AuthConfigs.find((authConfig) => authConfig.emailDomain?.toLowerCase() === emailDomain?.toLowerCase());
};

/**
 * Given an issuer configure the amplify Auth module to create auth tokens against.
 *
 * This caches the issuer and tenant in local storage so that when the user comes back to the tool we can refetch their credentials from
 * the same source.
 *
 * */
export const configureAmplifyAuth = (issuer: string, tenant?: string): IAuthConfig => {
  issuer = issuer.replace('https://', '');

  let authConfig = AuthConfigs.find((authConfig) => authConfig.issuer === issuer && authConfig.tenantId === tenant);
  if (!authConfig) {
    throw new Error(`No auth config found for issuer: ${issuer} and tenant: ${tenant}`);
  }
  if (issuer !== STANDARD_ISSUER) {
    if (!tenant) {
      throw new Error('Configuration error: tenant must be provided when using a non-standard issuer.');
    }
    // if we're using a non standard issuer, store it in local storage so we can load it in next time.
    localStorage.setItem(ISSUER_KEY, issuer);
    localStorage.setItem(TENANT_KEY, tenant ?? '');
  } else {
    // if we're requesting the standard issuer check if we have a stored non-standard issuer and use that instead.
    // this means a person has used the non standard issuer before and we should use that instead.
    const storedIssuer = localStorage.getItem(ISSUER_KEY);
    const storedTenant = localStorage.getItem(TENANT_KEY);
    if (storedIssuer && storedTenant) {
      // check for the stored config and if it exists use it. Otherwise use the standard config.
      const storedAuthConfig = AuthConfigs.find((authConfig) => authConfig.issuer === storedIssuer && authConfig.tenantId === storedTenant);
      if (storedAuthConfig) {
        issuer = storedIssuer;
        authConfig = storedAuthConfig;
      }
    }
  }

  const { cognitoDomain, userPoolId, userPoolWebClientId, redirectSignIn } = authConfig;
  const config = {
    region: process.env.REACT_APP_REGION,
    userPoolId,
    userPoolWebClientId,
    oauth: {
      domain: cognitoDomain,
      scope: ['openid', 'email', 'profile', 'aws.cognito.signin.user.admin'],
      redirectSignIn: redirectSignIn,
      redirectSignOut: window.location.origin,
      responseType: 'code',
    },
  };
  Auth.configure(config);
  return authConfig;
};

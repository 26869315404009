//@ts-nocheck
import './index.css';
import { ApolloClient, createHttpLink, from } from '@apollo/client';
import { cache } from './cache';
import { setContext } from '@apollo/client/link/context';
import { getAuthToken } from './v2/contexts/UserContext';
import { onError } from '@apollo/client/link/error';
import { apolloClientBus, Channels, ErrorCodes } from './ApolloClientBus';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_API,
});

export interface ActionStatement {
  resource: string,
  action: string,
}

const authLink = setContext(async (_, { headers }) => {
  let token = await getAuthToken();
  const timezoneOffset = new Date().getTimezoneOffset();
  return {
    headers: {
      ...headers,
      Authorization: token,
      'X-Timezone-Offset': timezoneOffset,
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError, forward, operation }) => {
  graphQLErrors?.forEach((error) => {
    if(error.extensions?.code === 'UNAUTHORIZED') {
      const actionStatement: ActionStatement = error.extensions?.actionStatement;
      apolloClientBus.emit(Channels.ERRORS, {
        code: ErrorCodes.UNAUTHORIZED,
        actionStatement: actionStatement,
      })
    }
  })
})

export const client = new ApolloClient({
  cache,
  link: from([errorLink, authLink, httpLink]),
  connectToDevTools: true,
});

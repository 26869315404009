import { Dispatch, ReactNode, createContext, useContext, useReducer } from 'react';
import { BoardsListState, BoardsListReducer, boardsListInitialState, BoardsListAction } from '../reducers/boards/boardsListReducer';

const BoardsListStateContext = createContext<BoardsListState | undefined>(undefined);
const BoardsListDispatchContext = createContext<Dispatch<BoardsListAction> | undefined>(undefined);

export const BoardsListProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(BoardsListReducer, boardsListInitialState);
  return (
    <BoardsListStateContext.Provider value={state}>
      <BoardsListDispatchContext.Provider value={dispatch}>{children}</BoardsListDispatchContext.Provider>
    </BoardsListStateContext.Provider>
  );
};

export const useBoardsListState = () => {
  const context = useContext(BoardsListStateContext);
  if (context === undefined) throw new Error('useBoardsListState must be used within an BoardsListProvider');

  return context;
};

export const useBoardsListDispatch = () => {
  const context = useContext(BoardsListDispatchContext);
  if (context === undefined) throw new Error('useBoardsListDispatch must be used within an BoardsListProvider');

  return context;
};

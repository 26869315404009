import { BellAlertIcon } from '@heroicons/react/24/outline';
import { GroupFull } from '../../../../../v2/hooks/GroupHook';
import { ToolbarBaseButton } from './ToolbarBaseButton';
import { useContext, useState } from 'react';
import { GroupSubscriptionPopover } from '../../../Popovers/GroupSubscriptionPopover';
import { Resource, Action } from '../../../../../generated/graphql';
import { PermissionsContext } from '../../../../../v2/contexts/PermissionsContext';

export function GroupSubscriptionsButton(props: { group: GroupFull; buttonRounding?: 'left' | 'right' }) {
  const [popoverKey, setPopoverKey] = useState<number>(0);
  
  const {hasPermission} = useContext(PermissionsContext);
  const canCreateAlerts = hasPermission(Resource.Alerts, Action.Create);
  const disabledTooltip = 'You do not have permission to create group subscriptions. Please contact your administrator and request the appropriate role.';
  if(!canCreateAlerts) {
    return <ToolbarBaseButton
        disabled={true}
        disabledTooltip={disabledTooltip}
        id="toolbar-group-subscriptions-button"
        icon={<BellAlertIcon className="h-5 w-5 stroke-2" />}
        label={'Configure Subscriptions'}
        buttonRounding={props.buttonRounding}
      />
  }

  return (
    <div
      id={`group-subscriptions`}
      onClick={(event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
      }}
    >
      <GroupSubscriptionPopover
        groupId={props.group?.id ?? -1}
        updateKey={setPopoverKey}
        key={popoverKey}
        customIconElem={
          <ToolbarBaseButton
            dataCy="toolbar-group-subscriptions-button"
            id="toolbar-group-subscriptions-button"
            icon={<BellAlertIcon className="h-5 w-5 stroke-2" />}
            label={'Configure Subscriptions'}
            buttonRounding={props.buttonRounding}
          />
        }
      />
    </div>
  );
}

import { ReactNode } from 'react';
import { SkeletonBar } from '../../../components/skeletons/SkeletonBar';

export const BoardHeaderSectionSkeleton = ({ backButton }: { backButton?: ReactNode }) => {
  return (
    <div className="flex flex-row gap-x-1">
      <div className="flex flex-col w-5/6 gap-y-2">
        <div className="flex flex-row gap-x-4 items-center">
          {backButton}
          <SkeletonBar width="w-2/3" height="h-10" />
        </div>
        <SkeletonBar width="w-3/4" height="h-4" />
      </div>
      <div className="flex flex-row w-1/6 items-start justify-end pt-1">
        <SkeletonBar height="h-10" />
      </div>
    </div>
  );
};

import { Role } from '../../../generated/graphql';
import { IDropDownItem } from '../../baseComponents/DropDown';

const ORG_ROLES = [Role.Admin, Role.Member];
const DEFAULT_ROLES = [Role.Admin, Role.Contributor, Role.Replier, Role.Viewer];

const toTitleCase = (str: string) => {
  return str.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

const createDropdownItems = (roles: Role[]): IDropDownItem[] => {
  return roles.map((role, idx) => ({
    title: toTitleCase(role),
    id: idx,
    name: toTitleCase(role),
    value: role,
  }));
};

export const IDROPDOWN_ORG_ROLES = createDropdownItems(ORG_ROLES);
export const IDROPDOWN_DEFAULT_ROLES = createDropdownItems(DEFAULT_ROLES);

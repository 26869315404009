import { Popover, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { GroupFull } from '../../../../v2/hooks/GroupHook';
import { ExploreGroupHook } from '../../../hooks/ExploreGroupHook';
import { HomeGroupHook } from '../../../hooks/HomeGroupHook';
import { alphabeticalSort, capitalizeFirstLetter } from '../../../../v2/util';
import Badge from '../../../../baseComponents/Badge';
import { ComboBox } from '../../../../baseComponents/ComboBox';
import toast from 'react-hot-toast';

export const TagsPopover = (props: { group: GroupFull | undefined; groupHook: ExploreGroupHook | HomeGroupHook }) => {
  return (
    <Popover className="relative items-center font-sofiapro">
      <Popover.Button className="flex focus:outline-none">
        <div className="flex flex-row gap-x-2 h-8 bg-silver items-center rounded-xl hover:cursor-pointer px-3 hover:bg-silver-darker duration-150">
          <h1 className="font-light text-blueberry w-full flex flex-row gap-x-1">
            Tags: <b className="font-semibold">{props.group?.tags?.length ?? 0}</b>
          </h1>
        </div>
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-150"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute flex-col gap-y-4 right-0 z-50 text-blueberry bg-milk rounded-lg shadow-lg p-4 flex w-[24rem] px-8">
          {({ close }) => <TagsPopoverBody group={props.group} groupHook={props.groupHook} />}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

const TagsPopoverBody = (props: { group: GroupFull | undefined; groupHook: ExploreGroupHook | HomeGroupHook }) => {
  const { group, groupHook } = props;
  const [removedTagId, setRemovedTagId] = useState<number>();
  return (
    <div id="tags-popover-body" className="flex flex-col gap-y-3">
      <div className="flex w-full">
        <ComboBox
          placeholder="Search for tag or create one..."
          comboBoxData={
            groupHook.tags
              ?.filter((tag) => !group?.tags?.some((t) => t.id === tag.id))
              .sort((a, b) => alphabeticalSort(a.name, b.name))
              .map((tag) => {
                return { ...tag, name: capitalizeFirstLetter(tag.name) };
              }) ?? []
          }
          defaultOption={true}
          setSelectedItem={(item) => {
            if (!group) return;

            if (item) {
              if (!groupHook.tags?.some((tag) => tag.id === item.id)) {
                const toastId = toast.loading('Creating tag...');
                groupHook.handleCreateTag(group.id, item.name, () => {
                  toast.dismiss(toastId);
                });
              } else {
                const toastId = toast.loading('Creating tag...');
                groupHook.handleTagGroup(group.id, item.id, () => {
                  toast.dismiss(toastId);
                });
              }
            }
          }}
        />
      </div>
      <div className="flex flex-row flex-wrap gap-x-2">
        {group?.tags?.map((tag) => {
          return (
            <div className="flex-none">
              <Badge
                color="bg-silver-darker"
                textColor="text-blueberry"
                key={tag.id}
                capitalize={true}
                badge={{ id: tag.id.toString(), text: tag.name }}
                onRemove={(item) => {
                  setRemovedTagId(tag.id);
                  groupHook.handleRemoveTag(group.id, tag.id, () => setRemovedTagId(undefined));
                }}
                loading={removedTagId === tag.id}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

import { LinkIcon } from '@heroicons/react/24/outline';
import { GroupFull, getGroupLink, writeToastMessage } from '../../../../../v2/hooks/GroupHook';
import { ToolbarBaseButton } from './ToolbarBaseButton';
import { useContext } from 'react';
import { FilterInput } from '../../../../../generated/graphql';
import AppContext from '../../../../../v2/contexts/AppContext';

export function CopyGroupUrlButton(props: { group: GroupFull; filters: FilterInput; buttonRounding?: 'left' | 'right' }) {
  const { curOrgId, curTeamId } = useContext(AppContext);
  async function handleCopy() {
    const groupLink = getGroupLink(props.group.id, props.filters, curTeamId!, curOrgId!, 'group');
    await navigator.clipboard.writeText(groupLink);
    writeToastMessage('Copied group link to clipboard');
  }
  return (
    <ToolbarBaseButton
      id="toolbar-copy-group-url-button"
      dataCy="toolbar-copy-group-url-button"
      icon={<LinkIcon className="h-5 w-5 stroke-2" />}
      label={'Copy Group URL'}
      onClick={handleCopy}
      buttonRounding={props.buttonRounding}
    />
  );
}

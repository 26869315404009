import { useEffect, useState } from 'react';
import { Chart_Bin_Type, Chart_Type, FilterInput, useGetCustomChart2DLazyQuery, useGetCustomChartLazyQuery } from '../../generated/graphql';

interface CustomChartCardHookProps {
  teamId: number;
  orgId: number;
  chartId: number;
  filterInput?: FilterInput;
  binType?: Chart_Bin_Type;
}

export const useCustomChartCardHook = ({ teamId, chartId, filterInput, binType }: CustomChartCardHookProps) => {
  const [loadFailed, setLoadFailed] = useState<boolean>(false);

  const [getChart, { data: chartData, loading: chartLoading }] = useGetCustomChart2DLazyQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  });

  const getFullChartInfo = async () => {
    setLoadFailed(false);
    getChart({
      variables: {
        chartId,
        teamId,
        filterInput,
        chartBinType: binType,
      },
      onCompleted(data) {
        if (!data.getChart2D) {
          setLoadFailed(true);
        }
      },
      onError() {
        setLoadFailed(true);
      },
    });
  };

  useEffect(() => {
    getFullChartInfo();
  }, [filterInput, binType]);

  return {
    chartData: chartData?.getChart2D
      ? {
          id: chartData?.getChart2D.id,
          title: chartData?.getChart2D.title,
          type: chartData?.getChart2D.config.type,
          y_axis_data: chartData?.getChart2D.config.y_axis_data,
          breakdown: chartData?.getChart2D.config.breakdown,
          seriesData: [
            {
              aggregateData: chartData?.getChart2D.series,
              normalizedData: chartData?.getChart2D.series,
              tooltipLabels: chartData?.getChart2D.tooltipLabels,
              chartLabels: chartData?.getChart2D.xAxisLabels,
              breakdownLabels: chartData?.getChart2D.legend,
              breakdown: chartData?.getChart2D.config.breakdown,
              filterInput: chartData?.getChart2D.config.seriesConfig?.[0].filterInput ?? {},
              team: chartData?.getChart2D.config.seriesConfig?.[0].team ?? { id: -1, name: 'All Teams' },
              segmentGroupId: chartData?.getChart2D.config.seriesConfig?.[0].segmentGroupId,
            },
          ],
        }
      : undefined,
    chartLoading: chartLoading,
    loadFailed,
  };
};

import React, { useState, useRef, useEffect, useCallback } from 'react';
import { LegendItem } from 'chart.js';
import { Popover } from '@headlessui/react';
import { Float } from '@headlessui-float/react';
import { classNames } from '../../v2/util';

interface ChartLegendProps {
  items: LegendItem[];
  onLegendClick: (index: number) => void;
}

/**
 * ChartLegend component displays a responsive legend for charts.
 *
 * This component dynamically adjusts its layout based on available space:
 * - It shows as many legend items as possible in the main view.
 * - When space is limited, it moves excess items into a "more" popover.
 *
 * It also allows clicking on items to toggle their visibility in the chart.
 * The "more" popover opens on mouse hover and closes on mouse leave. No need to click.
 *
 * @param items - Array of legend items to display.
 * @param onLegendClick - Callback function when a legend item is clicked.
 */
const ChartLegend = ({ items, onLegendClick }: ChartLegendProps) => {
  const [visibleItems, setVisibleItems] = useState<LegendItem[]>([]);
  const [popoverItems, setPopoverItems] = useState<LegendItem[]>([]);
  const legendRef = useRef<HTMLDivElement>(null);
  const resizeObserverRef = useRef<ResizeObserver | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const calculateVisibleItems = useCallback(() => {
    if (!legendRef.current) return;

    const containerWidth = legendRef.current.offsetWidth;
    let currentWidth = 0;
    const newVisibleItems: LegendItem[] = [];
    const newPopoverItems: LegendItem[] = [];

    // Reserve space for "n more..." text
    const moreTextWidth = getTextWidth('99 more...') + 10; // Add some padding
    const availableWidth = containerWidth - moreTextWidth;

    items.forEach((item, index) => {
      const itemWidth = getTextWidth(item.text) + 30; // 30px for padding and color box
      if (currentWidth + itemWidth < availableWidth) {
        newVisibleItems.push(item);
        currentWidth += itemWidth;
      } else {
        newPopoverItems.push(item);
      }
    });

    setVisibleItems(newVisibleItems);
    setPopoverItems(newPopoverItems);
  }, [items]);

  useEffect(() => {
    const setupResizeObserver = () => {
      if (legendRef.current && !resizeObserverRef.current) {
        resizeObserverRef.current = new ResizeObserver(calculateVisibleItems);
        resizeObserverRef.current.observe(legendRef.current);
      }
    };

    calculateVisibleItems();
    const timer = setTimeout(setupResizeObserver, 100);

    return () => {
      clearTimeout(timer);
      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect();
        resizeObserverRef.current = null;
      }
    };
  }, [calculateVisibleItems]);

  const handleItemClick = (item: LegendItem) => {
    onLegendClick(item.index ?? 0);
  };

  const getTextWidth = (text: string) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (context) {
      context.font = '12px SofiaPro';
      return context.measureText(text).width;
    }
    return 0;
  };

  const handleMouseEnter = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setIsOpen(false);
    }, 100); // Small delay to allow moving to panel
  };

  return (
    <div className="bg-silver py-2.5 rounded-b-md text-blueberry" ref={legendRef}>
      <div className="flex items-center justify-center gap-x-3 overflow-hidden">
        {visibleItems.map((item) => {
          return (
            <div
              key={item.index}
              className="flex flex-row gap-x-1 items-center cursor-pointer whitespace-nowrap hover:opacity-80 transition-opacity"
              onClick={() => handleItemClick(item)}
            >
              <div className="w-3 h-3 flex-shrink-0" style={{ backgroundColor: item.fillStyle as string, opacity: item.hidden ? 0.5 : 1 }} />
              <span className={`text-xs ${item.hidden ? 'line-through opacity-50' : ''}`}>{item.text}</span>
            </div>
          );
        })}
        {popoverItems.length > 0 && (
          <Popover className="relative">
            <Float
              show={isOpen}
              placement="bottom-end"
              offset={4}
              shift={6}
              flip={10}
              portal={true}
              enter="transition duration-200 ease-out"
              enterFrom="scale-95 opacity-0"
              enterTo="scale-100 opacity-100"
              leave="transition duration-150 ease-in"
              leaveFrom="scale-100 opacity-100"
              leaveTo="scale-95 opacity-0"
            >
              <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <Popover.Button className="flex flex-row items-center focus:outline-none gap-x-1 text-xs cursor-pointer whitespace-nowrap hover:opacity-80 transition-opacity">
                  <div className="w-3 h-3 flex-shrink-0 bg-slate-500" />
                  {popoverItems.length} more...
                </Popover.Button>
              </div>

              <Popover.Panel
                static
                className=" bg-white font-sofiapro border border-gray-200 px-4 py-2 rounded shadow-lg"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <div className="flex flex-row gap-x-2 max-w-lg flex-wrap gap-y-2">
                  {popoverItems.map((item) => (
                    <button
                      key={item.index}
                      className="flex flex-row gap-x-1 items-center cursor-pointer whitespace-nowrap hover:opacity-80 transition-opacity"
                      onClick={() => handleItemClick(item)}
                    >
                      <div className="w-3 h-3 flex-shrink-0" style={{ backgroundColor: item.fillStyle as string }} />
                      <span className={`text-xs ${item.hidden ? 'line-through opacity-50' : ''}`}>{item.text}</span>
                    </button>
                  ))}
                </div>
              </Popover.Panel>
            </Float>
          </Popover>
        )}
      </div>
    </div>
  );
};

export default ChartLegend;

import { BoardLightFragment, GetBoardsQuery, Widget } from '../../generated/graphql';

export interface BoardsListState {
  boards: BoardLightFragment[];
}

export const boardsListInitialState: BoardsListState = {
  boards: [],
};

export enum BoardsListActionTypes {
  SetBoards,
  AddBoard,
  TogglePinBoard,
}

type SetBoardsPayload = { boards: BoardLightFragment[] };
type AddBoardPayload = { board: BoardLightFragment };
type TogglePinBoardPayload = { boardId: number };

export type BoardsListAction =
  | { type: BoardsListActionTypes.SetBoards; payload: SetBoardsPayload }
  | { type: BoardsListActionTypes.AddBoard; payload: AddBoardPayload }
  | { type: BoardsListActionTypes.TogglePinBoard; payload: TogglePinBoardPayload };

export const BoardsListReducer = (state: BoardsListState, action: BoardsListAction): BoardsListState => {
  switch (action.type) {
    case BoardsListActionTypes.SetBoards:
      //sort by pinned first
      const sortedBoards = [...action.payload.boards].sort((a, b) => (a.isPinnedByUser === b.isPinnedByUser ? 0 : a.isPinnedByUser ? -1 : 1));
      return { ...state, boards: sortedBoards };
    case BoardsListActionTypes.AddBoard:
      return { ...state, boards: [...state.boards, action.payload.board] };
    case BoardsListActionTypes.TogglePinBoard:
      return handleTogglePinBoard(state, action.payload);

    default:
      throw new Error(`Unhandled action type`);
  }
};

const handleTogglePinBoard = (state: BoardsListState, payload: TogglePinBoardPayload): BoardsListState => {
  const boardIndex = state.boards.findIndex((board) => board.id === payload.boardId);
  if (boardIndex === -1) throw new Error(`Board with id ${payload.boardId} not found`);

  const board = state.boards[boardIndex];
  const newPinStatus = !board.isPinnedByUser;
  const updatedBoard = { ...board, isPinnedByUser: newPinStatus };

  const newBoards = [...state.boards];
  newBoards.splice(boardIndex, 1);

  // Find the last pinned index to add after it (findLastIndex doesn't work with TS < 5.0.0)
  let lastPinnedIndex = -1;
  for (let i = newBoards.length - 1; i >= 0; i--) {
    if (newBoards[i].isPinnedByUser) {
      lastPinnedIndex = i;
      break;
    }
  }
  newBoards.splice(lastPinnedIndex + 1, 0, updatedBoard);

  return { ...state, boards: newBoards };
};

import { useContext } from 'react';
import { DigestDataActions, DigestObjectType, DigestSeriesDataType } from '../../../reducers/digests/digestDataReducer';
import { DigestDataDispatchContext } from '../../../context/digestDataContext';

export interface DigestSeriesListItemProps {
  item: DigestSeriesDataType;
}
const DigestSeriesListItem = ({ item }: DigestSeriesListItemProps) => {
  const digestDataDispatch = useContext(DigestDataDispatchContext);
  return (
    <div
      className="w-full border-t py-2 flex flex-col text-blueberry"
      onClick={() =>
        digestDataDispatch?.({ type: DigestDataActions.SetSelectedDigestObject, payload: { selectedObject: item, digestObjectType: DigestObjectType.Series } })
      }
    >
      <div className="grid grid-cols-2">
        <div className="text-lg font-semibold flex justify-start self-end">
          <div>{item.title}</div>
        </div>
        <div className="text-xs font-light flex justify-end items-end">
          <div>{new Date(item.dateCreated).toDateString()}</div>
        </div>
      </div>
      <div className="text-sm font-light">
        {item.creator.firstName} {item.creator.lastName}
      </div>
      <div className="text-sm font-light">
        {item.organization.name} ({item.organization.id})
      </div>
    </div>
  );
};

export default DigestSeriesListItem;

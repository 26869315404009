import { createContext, useReducer, Dispatch, useContext, ReactNode } from 'react';
import {
  ExternalTicketsState,
  ExternalTicketsReducer,
  ExternalTicketsAction,
  initialExternalTicketsState,
} from '../reducers/externalTickets/externalTicketsReducer';

const ExternalTicketsStateContext = createContext<ExternalTicketsState | undefined>(undefined);
const ExternalTicketsDispatchContext = createContext<Dispatch<ExternalTicketsAction> | undefined>(undefined);

//Testing a slightly different way to use Providers, with a component that has both Data and Dispatch, and use() functions.

export const ExternalTicketsProvider = ({ children, overridenInitialState }: { children: ReactNode; overridenInitialState?: ExternalTicketsState }) => {
  //The overridenInitialState is used for tests.
  const [state, dispatch] = useReducer(ExternalTicketsReducer, overridenInitialState ?? initialExternalTicketsState);
  return (
    <ExternalTicketsStateContext.Provider value={state}>
      <ExternalTicketsDispatchContext.Provider value={dispatch}>{children}</ExternalTicketsDispatchContext.Provider>
    </ExternalTicketsStateContext.Provider>
  );
};

export const useExternalTicketsState = () => {
  const context = useContext(ExternalTicketsStateContext);
  if (context === undefined) throw new Error('useExternalTicketsState must be used within an ExternalTicketsProvider');

  return context;
};

export const useExternalTicketsDispatch = () => {
  const context = useContext(ExternalTicketsDispatchContext);
  if (context === undefined) throw new Error('useExternalTicketsDispatch must be used within an ExternalTicketsProvider');

  return context;
};

import { BoardFragment, BoardLightFragment, FilterInput, SavedFilterInput, Widget } from '../../generated/graphql';

export interface BoardState {
  board: BoardFragment;
}

export enum BoardActionTypes {
  SetBoard,
  AddWidgets,
  SetWidgets,
  RemoveWidget,
  SetTitle,
  SetDescription,
  SetPinned,
  SetFilterInput,
}

type SetBoardPayload = { board: BoardFragment };
type AddWidgetsPayload = { widgets: Widget[] };
type SetWidgetsPayload = { widgets: Widget[] };
type RemoveWidgetPayload = { widgetId: number };
type SetTitlePayload = { title: string };
type SetDescriptionPayload = { description: string };
type SetPinnedPayload = { isPinned: boolean };
type SetFilterInputPayload = { filterInput: SavedFilterInput };

export type BoardAction =
  | { type: BoardActionTypes.SetBoard; payload: SetBoardPayload }
  | { type: BoardActionTypes.AddWidgets; payload: AddWidgetsPayload }
  | { type: BoardActionTypes.SetWidgets; payload: SetWidgetsPayload }
  | { type: BoardActionTypes.RemoveWidget; payload: RemoveWidgetPayload }
  | { type: BoardActionTypes.SetTitle; payload: SetTitlePayload }
  | { type: BoardActionTypes.SetDescription; payload: SetDescriptionPayload }
  | { type: BoardActionTypes.SetPinned; payload: SetPinnedPayload }
  | { type: BoardActionTypes.SetFilterInput; payload: SetFilterInputPayload };

export const BoardReducer = (state: BoardState, action: BoardAction): BoardState => {
  if (action.type === BoardActionTypes.SetBoard) {
    return { ...state, board: action.payload.board };
  } else {
    if (!state.board) throw new Error('Cannot execute action without a board');
    switch (action.type) {
      case BoardActionTypes.SetWidgets:
        return {
          ...state,
          board: {
            ...state.board,
            widgets: action.payload.widgets,
          },
        };
      case BoardActionTypes.AddWidgets:
        return {
          ...state,
          board: {
            ...state.board,
            widgets: [...state.board.widgets, ...action.payload.widgets],
          },
        };
      case BoardActionTypes.SetTitle:
        return {
          ...state,
          board: {
            ...state.board,
            title: action.payload.title,
          },
        };
      case BoardActionTypes.SetDescription:
        return {
          ...state,
          board: {
            ...state.board,
            description: action.payload.description,
          },
        };
      case BoardActionTypes.SetPinned:
        return {
          ...state,
          board: {
            ...state.board,
            isPinnedByUser: action.payload.isPinned,
          },
        };
      case BoardActionTypes.RemoveWidget:
        return {
          ...state,
          board: {
            ...state.board,
            widgets: state.board.widgets.filter((widget) => widget.id !== action.payload.widgetId),
          },
        };
      case BoardActionTypes.SetFilterInput:
        return {
          ...state,
          board: {
            ...state.board,
            filterInput: action.payload.filterInput,
          },
        };
      default:
        throw new Error(`Unhandled action type`);
    }
  }
};

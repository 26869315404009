import { Creator } from '../../../../v2/hooks/GroupHook';
import { formattedDate } from '../../../baseComponents/DatePicker';
interface CreatedByProps {
  creator: Creator;
  dateCreated: number;
}

/*
creator: string 
dateCreated: number (Unix timestamp in ms)
*/
const CreatedBy = ({ creator, dateCreated }: CreatedByProps) => {
  // If we don't have a creator email, we assume it's unwrap generated
  const isUnwrapGenerated = creator.isUnwrapGenerated || !creator.creatorEmail;

  // convert unix timestamp to local date in format MMM DD, YYYY (format from DatePicker)
  const localDateCreated = formattedDate(new Date(dateCreated))

  return (
    <div className="flex flex-row items-center gap-x-1 flex-wrap">
      <div className="text-gray-400">Created by</div>
      <div className="break-all">{isUnwrapGenerated ? 'Unwrap.ai' : creator.creatorEmail}</div>
      <div className="text-gray-400">on</div>
      <div>{localDateCreated}</div>
    </div>
  );
};

export default CreatedBy;

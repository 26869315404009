interface InteractiveSectionProps {
    title?: string,
    description?: string,
    children?: React.ReactNode
}

const InteractiveSection =
    ({
        title,
        description,
        children
    }
        : InteractiveSectionProps): JSX.Element => {
        return (
            <>
                {(title || description || children) &&
                    <div className="mt-6 flex flex-col gap-y-3 overflow-x-hidden overflow-y-hidden">
                        {/* #. Title of section */}
                        {title && <p>{title}</p>}

                        {/* Small text explaining the section */}
                        {description &&
                            <p className='text-sm font-light ml-3 -mt-2'>{description}</p>
                        }

                        {/* The configurations/input the user provides */}
                        {children &&
                            <div className='mx-3'>
                                {children}
                            </div>
                        }
                    </div>}
            </>
        )
    }

export default InteractiveSection;

import { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { classNames } from '../../../v2/util';
import { Cog6ToothIcon } from '@heroicons/react/24/outline';

const NewGroupToggle = ({ exclude, toggleExclude }: { exclude: boolean; toggleExclude: (exclude: boolean) => void }) => {
  return (
    <Popover>
      <Popover.Group>
        <Popover.Button
          className={classNames(
            'items-center group flex flex-row gap-x-1 duration-150 py-1 px-2 text-blueberry text-xs flex-wrap whitespace-nowrap cursor-pointer hover:bg-gray-200 rounded-full'
          )}
        >
          <Cog6ToothIcon className="h-8 w-8" />
        </Popover.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel
            className={`absolute z-50 w-36 px-6 py-2 transform sm:px-0 bg-milk shadow-lg rounded-md`}
            onPointerDown={(e) => e.stopPropagation()}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="p-2 rounded-md flex flex-col justify-center items-start gap-y-2">
              <p className="text-md">Admin:</p>
              <div className="flex flex-row gap-x-2 justify-center items-center">
                <input
                  type="checkbox"
                  data-testid="exclude-from-new-filter"
                  name="exclude-from-new-filter"
                  checked={exclude}
                  onChange={() => toggleExclude(!exclude)}
                />
                <p className="text-xs">Exclude Group From New Filter</p>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover.Group>
    </Popover>
  );
};

export default NewGroupToggle;

import React, { useState, useEffect, useContext } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import {
  OrganizationUserFragment,
  useCreateOrganizationMutation,
  useCreateTeamMutation,
  useDeleteOrganizationMutation,
  useDeleteTeamMutation,
  useEditOrganizationMutation,
  useGetOrganizationUsersLazyQuery,
} from '../../generated/graphql';
import AppContext from '../contexts/AppContext';
import UserContext from '../contexts/UserContext';

export const SELECTED_ORG_KEY = 'selectedOrgV2';
export const SELECTED_TEAM_KEY = 'selectedTeamV2';

/*

This hook provides functions to modify/add/delete teams and organizations.
It uses the AppContext to determine the current team and org to be modified, in cases where it's not required by params.

After executing the mutation, it updates the AppContext with the new data.

To read the data, use the AppContext directly.

*/

export const useOrganizationHook = () => {
  const appContext = useContext(AppContext);
  const { user } = useContext(UserContext);
  const [deleteOrgMutation] = useDeleteOrganizationMutation();
  const [deleteTeamMutation] = useDeleteTeamMutation();
  const [editOrganizationMutation] = useEditOrganizationMutation();
  const [createOrg] = useCreateOrganizationMutation();
  const [createTeam] = useCreateTeamMutation();
  const [getOrgUsers, orgUsers] = useGetOrganizationUsersLazyQuery();
  useEffect(() => {
    if (appContext.curOrgId) {
      getOrgUsers({
        variables: { orgId: appContext.curOrgId },
      });
    }
  }, [appContext.curOrgId]);

  const editOrganization = async (name: string) => {
    await editOrganizationMutation({
      variables: { name, orgId: appContext.curOrgId! },
      onCompleted: (data) => {
        appContext.updateOrg(data.editOrganization);
      },
    });
  };

  const addTeam = (teamName: string, logoUrl: string, demoMode: string) => {
    createTeam({
      variables: {
        name: teamName,
        logoUrl: logoUrl,
        orgId: appContext.curOrgId!,
      },
      onCompleted: async (data) => {
        appContext.addTeamToOrg(data.createTeam);
      },
    });
  };

  const deleteTeam = async (teamId: number) => {
    await deleteTeamMutation({
      variables: {
        teamId,
        orgId: appContext.curOrgId!,
      },
      onCompleted: () => {
        appContext.removeTeamFromOrg(teamId);
      },
    });
  };

  const deleteOrg = (orgId: number) => {
    deleteOrgMutation({
      variables: {
        orgId: orgId,
      },
      onCompleted: () => {
        appContext.removeOrg(orgId);
      },
    });
  };

  const addOrganization = (name: string) => {
    createOrg({
      variables: {
        name: name,
      },
      onCompleted: (data) => {
        appContext.addOrg(data.createOrganization);
      },
    });
  };

  const filterUnwrapEmails = (orgUsers: OrganizationUserFragment[] | null | undefined) => {
    if (!orgUsers) return [];
    return orgUsers.filter((orgUser) => {
      if (user?.isUnwrapper) return true;
      return !orgUser.email.includes('@unwrap.ai');
    });
  };

  return {
    orgUsers: orgUsers.data?.getOrganizationUsers ?? [],
    filteredOrgUsers: filterUnwrapEmails(orgUsers.data?.getOrganizationUsers) ?? [],
    editOrganization,
    addTeam,
    addOrganization,
    deleteOrg,
    deleteTeam,
  };
};

import { useContext, useEffect, useState } from 'react';
import { GroupDataContext } from '../../../context/groupContext';
import ChartLoading from '../../../v2/sections/Charts/ChartLoading';
import ClusterSparkChart from '../ClusterSparkChart';
import moment from 'moment';
import ReactMarkdown from 'react-markdown';
import { FilterInput } from '../../../generated/graphql';

export interface InfoBoxChartProps {
  aggregateData: number[];
  normalizedData: number[];
  tooltipLabels: string[];
  chartLabels: string[];
}

const GroupPageInfoBox = (props: { chart?: InfoBoxChartProps; filters: FilterInput }) => {
  const { chart, filters } = props;
  const { groupData: group, auxillaryGroupDataLoading } = useContext(GroupDataContext);
  const [chartWidth, setChartWidth] = useState('w-1/2');
  const [summaryWidth, setSummaryWidth] = useState('w-1/2');

  useEffect(() => {
    // if there is no summary text then make the chart take up the full width and hide the summary
    if (!group?.summaryText) {
      setChartWidth('w-full');
      setSummaryWidth('hidden');
    } else {
      setChartWidth('w-1/2');
      setSummaryWidth('w-1/2');
    }
  }, [group]);
  return (
    <div className="flex flex-row w-full gap-x-2">
      <div className={`h-[15rem] ${chartWidth}`}>
        {!chart ? (
          <ChartLoading />
        ) : (
          <ClusterSparkChart
            artificialStartDate={undefined}
            autoHeight
            aggregateData={chart.aggregateData}
            normalizedData={chart.normalizedData}
            tooltipLabels={chart.tooltipLabels}
            chartLabels={chart.chartLabels}
            filterInput={
              filters
                ? filters
                : {
                    startDate: moment().subtract(360, 'days').startOf('day').toDate(),
                    endDate: moment().toDate(),
                  }
            }
          />
        )}
      </div>
      <div className={`${summaryWidth} h-[15rem] overflow-y-scroll overflow-x-clip bg-silver border-silver border-2 rounded-md relative`}>
        {auxillaryGroupDataLoading || !group ? (
          <SummaryLoadingSkeleton />
        ) : (
          <div className="px-5 py-2">
            <SummaryMarkdown text={group.summaryText ?? ''} />
          </div>
        )}
        <div className="sticky bottom-0 left-0 right-0 h-12 bg-gradient-to-b from-transparent to-milk pointer-events-none"></div>{' '}
      </div>
    </div>
  );
};

const SummaryMarkdown = ({ text }: { text: string }) => {
  // strip ``` from the start and end of the string
  const textToDisplay = text.replace(/^```/g, '').replace(/```$/g, '');

  return (
    <div className={'flex flex-col w-full text-blueberry'}>
      <h1 className="font-bold">Summary</h1>
      <ReactMarkdown
        components={{
          h1: ({ node, ...props }) => <h1 className={'text-xl mb-4 flex-wrap'} {...props} />,
          h2: ({ node, ...props }) => <h2 className={'text-xl mb-3 flex-wrap'} {...props} />,
          h3: ({ node, ...props }) => <h3 className={'text-lg mb-2 flex-wrap'} {...props} />,
          a: ({ node, ...props }) => <a className={'underline flex-wrap'} target="_blank" rel="noopener noreferrer" {...props} />,
          h4: ({ node, ...props }) => <h4 className={'text-medium flex-wrap'} {...props} />,
          ul: ({ node, ...props }) => <ul className={'list-disc ml-6 flex-wrap'} {...props} />,
          li: ({ node, ...props }) => <li className={'mb-2 flex-wrap'} {...props} />,
        }}
        children={textToDisplay}
      />
    </div>
  );
};

const SummaryLoadingSkeleton = () => {
  return (
    <div
      data-testid="summary-loading-skeleton"
      className="custom-chart-card-skeleton space-y-5 rounded-lg bg-gray-100 relative 
        before:absolute before:inset-0
        before:-translate-x-full
        before:animate-[shimmer_2s_infinite]
        before:bg-gradient-to-r before:from-transparent before:via-blueberry  before:opacity-[0.2]
        isolate
        overflow-hidden
        before:border-t before:border-gray-100 opacity-70 h-full"
    ></div>
  );
};

export default GroupPageInfoBox;

import { classNames, truncateAndEllipsis } from '../../v2/util';
import { useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { Fragment } from 'react';
export const SegmentLabel = ({
  segment,
  remainingSegments,
}: {
  segment?: { groupName: string; value: string };
  remainingSegments?: { groupName: string; value: string }[];
}) => {
  const showRemainingSegments = !segment;
  // we have this state here to control showing the popover on hover instead of click
  const [isShowing, setIsShowing] = useState(false);

  return (
    <div className="">
      <Popover>
        <Popover.Group>
          <Popover.Button
            onMouseEnter={() => setIsShowing(true)}
            onMouseLeave={() => setIsShowing(false)}
            className={classNames(
              'items-center  group flex flex-row gap-x-1 duration-300 py-1 px-2 bg-gray-200 text-blueberry rounded-lg text-xs cursor-default flex-wrap whitespace-nowrap'
            )}
          >
            {segment != null ? (
              <h1>
                {segment.groupName}: {truncateAndEllipsis(segment.value, 60)}
              </h1>
            ) : (
              <h1> +{remainingSegments!.length}</h1>
            )}
          </Popover.Button>

          {showRemainingSegments && (
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
              show={isShowing}
            >
              <Popover.Panel
                className={`absolute z-10 w-96 px-4 py-3 transform left-[95%] -translate-x-[95%] sm:px-0`}
                onMouseEnter={() => setIsShowing(true)}
                onMouseLeave={() => setIsShowing(false)}
              >
                <div className="overflow-auto max-h-32 bg-white rounded-lg shadow-md ring-1 ring-black ring-opacity-5 p-2 flex flex-row gap-y-1 overflow-y-scroll flex-wrap">
                  {remainingSegments!.map((segment, index) => (
                    <div
                      key={index}
                      className={classNames(
                        ' flex-none group relative  items-start justify-start flex-row duration-300 py-1 px-2 bg-gray-200 text-blueberry rounded-lg text-xs cursor-default mx-0.5'
                      )}
                    >
                      <h1>
                        {segment.groupName}: {segment.value}
                      </h1>
                    </div>
                  ))}
                </div>
              </Popover.Panel>
            </Transition>
          )}
        </Popover.Group>
      </Popover>
    </div>
  );
};

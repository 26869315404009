import {
  ChartData,
  ChartEvent,
  ChartOptions,
  LegendElement,
  LegendItem,
  DoughnutController,
  TooltipItem,
  BarController,
  BarElement,
  CategoryScale,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Tooltip,
  Title,
  SubTitle,
  Legend,
  ArcElement,
  PieController,
} from 'chart.js';
import moment from 'moment';
import { Chart } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';
import { bind } from 'underscore';
import { IChartableItem } from '../generated/graphql';
ChartJS.register(
  BarElement,
  BarController,
  LineElement,
  LineController,
  PointElement,
  LinearScale,
  CategoryScale,
  ArcElement,
  PieController,
  DoughnutController,
  Tooltip,
  Title,
  Legend,
  SubTitle
);
export enum ChartPeriod {
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
}

export interface IChartingSeries {
  label: string;
  color?: string;
  dataSeries: IChartableItem[];
  isDenominator?: boolean;
  totalEntries?: number;
}

/** This holds data to actually get charted */
export interface IChartableSeries extends IChartingSeries {
  yaxis: number[];
}

interface ChartComponentProps {
  period: ChartPeriod;
  title: string;
  startDate: Date;
  endDate: Date;
  data: IChartingSeries[];
  yaxisLabel: string;
  denominator?: IChartingSeries;
  disableLegend?: boolean;
}

const colors = ['#8f929b', '#cd5a76', '#b95473', '#a64f70', '#92496d', '#7f436a', '#6b3e67', '#573864', '#413361', '#282d5e'];
const VerticalStackedBarChart = ({ startDate, endDate, period, data, denominator, yaxisLabel, title, disableLegend }: ChartComponentProps) => {
  const options: ChartOptions<'bar'> = {
    plugins: {
      title: {
        text: title,
        position: 'top',
        display: true,
      },
      legend: {
        display: disableLegend ? false : true,
        position: 'right',
        align: 'start',
        labels: {
          boxWidth: 10,
          boxHeight: 10,
          padding: 2,
        },
        onClick: (e: ChartEvent, legendItem: LegendItem, legend: LegendElement<'bar'>) => {
          e.native?.preventDefault();
        },
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: (data: TooltipItem<'bar'>) => {
            if (denominator) {
              const value = Math.round(parseFloat(data.formattedValue.replace(/,/, '.')) * 10) / 10;
              //@ts-ignore
              const entries = data.dataset.unnormalized[data.dataIndex];

              return [data.dataset.label + ': ' + value + '% (' + entries + ' entries) of feedback', 'during this time period matches the selected filter'];
            }
            return data.dataset.label + ': ' + data.formattedValue;
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        ticks: {
          align: 'center',
        },
        title: {
          display: false,
        },
      },
      y: {
        stacked: true,
        title: {
          display: true,
          text: yaxisLabel,
        },
      },
    },
  };

  // convert data from IChartableItem to the data expected.
  // this means we need to aggregate the data by time period, month or week, then use this
  const chartData = transformToChartData(data, startDate, endDate, denominator);

  return (
    <div>
      <div className="h-96">
        <Chart type="bar" options={options} data={chartData} />
      </div>
    </div>
  );
};

export default VerticalStackedBarChart;

// const getEndDate = (data: IChartingSeries[]): Date => {
//   let maxValue = 0;
//   data.forEach((series) =>
//     series.dataSeries.forEach((value) => {
//       if (value.date.valueOf() > maxValue) {
//         maxValue = value.date.valueOf();
//       }
//     })
//   );
//   return moment.utc(maxValue).toDate();
// };

// const getStartDate = (data: IChartingSeries[]): Date => {
//   let minValue = Number.MAX_VALUE;
//   data.forEach((series) =>
//     series.dataSeries.forEach((value) => {
//       if (value.date.valueOf() < minValue) {
//         minValue = value.date.valueOf();
//       }
//     })
//   );
//   return moment.utc(minValue).toDate();
// };

export const transformToChartData = (
  data: IChartingSeries[],
  startDate: Date,
  endDate: Date,
  denominator?: IChartingSeries
): ChartData<'bar', number[], string> => {
  const bins = getLabelRange(startDate, endDate);
  let denominatorAggregate: IChartableSeries | undefined = undefined;
  let aggregateData;
  if (denominator) {
    aggregateData = getAggregateData([...data, denominator], bins);
    denominatorAggregate = aggregateData.pop();
  } else {
    aggregateData = getAggregateData(data, bins);
  }
  return {
    // if we have more steps it means we're looking at a wider time window.
    // edge case: we treat a 7 day time window the same as charts w/ bins <= 6
    labels:
      bins.length <= 6 || (bins.length === 7 && moment(endDate).diff(startDate, 'days') === 7)
        ? bins.map((bin, index) => {
            let next = bins[index + 1];
            if (!next) {
              next = endDate!.valueOf();
            }
            return moment.utc(bin).format('DD MMM') + ' - ' + moment.utc(next).format('DD MMM');
          })
        : bins.map((bin) => moment.utc(bin).format("MMM 'YY")),
    datasets: aggregateData.map((series, index) => {
      series.yaxis.pop();
      let yaxis: number[] = series.yaxis;

      if (denominatorAggregate) {
        //@ts-ignore
        yaxis = yaxis.map((value, index) => {
          if (value === 0) {
            return 0;
          }
          //@ts-ignore
          return (value / denominatorAggregate.yaxis[index]) * 100;
        });
      }
      return {
        data: yaxis,
        label: series.label,
        backgroundColor: series.color ?? colors[colors.length - 1 - index],
        unnormalized: series.yaxis,
      };
    }),
  };
};

/**
 *
 * @param aggregateData
 * @returns
 */
export const getLabelRange = (start: Date, end: Date): number[] => {
  if (moment(end).diff(start, 'days') <= 7) {
    const numberOfDays = Math.ceil(moment.duration(moment(end).diff(start)).asDays());
    const bins: Date[] = [];
    for (let i = 0; i < numberOfDays; i++) {
      bins.push(moment(start).add(i, 'days').startOf('day').toDate());
    }
    bins.push(end);
    return bins.map((bin) => bin.valueOf());
  }
  const month = start.getUTCMonth() + 1;
  let monthString = '';
  if (month < 10) {
    monthString = '0' + month.toString();
  } else {
    monthString = month.toString();
  }

  const startMoment = moment(`${start.getUTCFullYear()}-${monthString}-01`);
  const endMoment = moment(end);
  const numberOfMonths = Math.ceil(moment.duration(endMoment.diff(startMoment)).asMonths());
  const bins: Date[] = [new Date(startMoment.toISOString())];
  for (let i = 0; i < numberOfMonths; i++) {
    bins.push(startMoment.add(1, 'months').toDate());
  }

  // optionally shorten the bin range for smaller time range choices.
  const labelRange = range(start.valueOf(), end.valueOf(), 2.628e9);
  if (labelRange.length < 6) {
    const shorterStep = (end.valueOf() - start.valueOf()) / 6;
    return range(start.valueOf(), end.valueOf(), shorterStep);
  }

  // Just show month explicitly
  // only use the bins if we're in a spot where we want to show month explicitly.
  return bins.map((bin) => bin.valueOf());
};

/**
 * Aggregates all data in chart series by time according to period.
 * @param data
 * @param bins - date range binned to bars that I want to show
 * @returns
 *  */
export const getAggregateData = (data: IChartingSeries[], bins: number[]): IChartableSeries[] => {
  const aggregateData: IChartableSeries[] = []; // <groupedPeriod, aggregateSeriesValue>[]
  data.map((series) => {
    // datapoints within the series
    const seriesAggregate: number[] = new Array(bins.length).fill(0);
    series?.dataSeries?.map((dataPoint) => {
      const index = bins.findIndex((value) => {
        if (value >= dataPoint.date) {
          return true;
        }
      });
      seriesAggregate[index - 1] += dataPoint.count;
    });
    aggregateData.push({
      ...series,
      yaxis: seriesAggregate,
    });
  });
  bins.pop();
  return aggregateData;
};

const range = (start: number, stop: number, step: number = 1) =>
  Array(Math.ceil((stop >= start ? stop - start : 0) / step) + 1)
    .fill(start)
    .map((x, y) => x + y * step);

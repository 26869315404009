import { Dispatch } from 'react';
import { Segment } from '../../v3/components/GroupBreakdownChartPreview';


export enum AssistantStatus {
  idle,
  loading,
  failed,
  streaming,
  done
}

export interface AssistantState {
  status: AssistantStatus;
  components: ComponentMap;
  summaries: string[];
  question: string;
  userInput: string;
  questionId: number;
}

export interface ComponentObject {
  type: string;
  props: {
    [key: string]: any;
  };
}

export interface ComponentMap {
  [key: string]: ComponentObject
}

export enum AssistantActions {
  UPDATE_COMPONENT,
  SUBMIT_QUESTION,
  UPDATE_ASSISTANT_STATUS,
  RESET_ASSISTANT,
  UPDATE_USER_INPUT
}

interface MarkdownComponentProps {
  text: string;
}

interface GroupedChartComponentProps {
  groupIds: string[];
  startDate: string;
  endDate: string;
  segment?: Segment;
}

interface UpdateComponentPayload {
  type: string;
  id: string;
  props?: GroupedChartComponentProps | MarkdownComponentProps;
}

interface UpdateAssistantStatusPayload {
  status: AssistantStatus;
}

interface SubmitQuestionPayload {
  question: string;
}

interface UpdateUserInputPayload {
  userInput: string;
}

type EmptyPayload = {}

type AssistantPayload = EmptyPayload | UpdateComponentPayload | SubmitQuestionPayload | UpdateUserInputPayload

export interface AssistantAction {
  type: AssistantActions;
  payload: AssistantPayload;
}

export type AssistantDispatch = Dispatch<{
  type: AssistantActions;
  payload: AssistantPayload;
}>;

export const AssistantReducer = (state: AssistantState, action: AssistantAction): AssistantState => {
  switch (action.type) {
    case AssistantActions.UPDATE_ASSISTANT_STATUS: {
      const payload = action.payload as UpdateAssistantStatusPayload;
      return {
        ...state,
        status: payload.status
      };
    }
    case AssistantActions.RESET_ASSISTANT: {
      return {
        ...state,
        status: AssistantStatus.idle,
        summaries: [],
        components: {},
        question: '',
      };
    }
    case AssistantActions.SUBMIT_QUESTION: {
      const payload = action.payload as SubmitQuestionPayload;
      return {
        ...state,
        components: {},
        questionId: state.questionId + 1,
        question: payload.question,
        status: AssistantStatus.loading,
      };
    }
    case AssistantActions.UPDATE_USER_INPUT: {
      const payload = action.payload as UpdateUserInputPayload;
      return {
        ...state,
        userInput: payload.userInput
      };
    }
    case AssistantActions.UPDATE_COMPONENT: {
      let payload = action.payload as UpdateComponentPayload;
      const component = state.components[payload.id];
      let newComponent = component;

      if(!component) {
        return {
          ...state,
          status: AssistantStatus.streaming,
          components: {
            ...state.components,
            [payload.id]: {
              type: payload.type,
              props: {}
            }
          }
        };
      }

      switch(component.type) {
        case('chart'): {
          const props = payload.props as GroupedChartComponentProps;
          if(!props || !props.groupIds)  {
            return {
              ...state,
              status: AssistantStatus.streaming,
            }
          }

          newComponent = {
            ...component,
            props: {
              groupIds: props.groupIds ?? [],
              startDate: props.startDate,
              endDate: props.endDate,
              segment: props.segment,
            }
          };
          return {
            ...state,
            status: AssistantStatus.streaming,
            components: {
              ...state.components,
              [payload.id]: newComponent
            }
          };
        }
        case('markdown'): {
          const props = payload.props as MarkdownComponentProps;
          if(!props || !props.text)  {
            return {
              ...state,
              status: AssistantStatus.streaming,
            }
          }

          newComponent = {
            ...component,
            props: {
              text: (component.props?.text ?? '') + (props?.text ?? '')
            }
          };
          return {
            ...state,
            status: AssistantStatus.streaming,
            components: {
              ...state.components,
              [payload.id]: newComponent
            }
          };
        }
        default:
          throw new Error(`invalid component type ${component.type}`)
      }
    }

    default:
      throw new Error(`I do no know how to do that action ${action.type}`);
  }
};

import { Popover, Transition } from '@headlessui/react';
import { TrashIcon } from '@heroicons/react/24/solid';
import Tippy from '@tippyjs/react';
import { Fragment, useContext, useState, useRef } from 'react';
import toast from 'react-hot-toast';
import 'tippy.js/themes/light.css';
import AdjustableLoadingIcon from '../../baseComponents/AdjustableLoadingIcon';
import {
  Action,
  EntryFragment,
  GroupLightFragment,
  GroupMembershipFragment,
  Group_Membership_Action,
  Group_Status,
  Resource,
  useGetGroupsLightQuery,
} from '../../generated/graphql';
import { AppRoutes } from '../../Routes';
import { useValidTeamAppContext } from '../../v2/contexts/AppContext';
import { PermissionsContext } from '../../v2/contexts/PermissionsContext';
import { classNames, truncateAndEllipsis } from '../../v2/util';
import SearchInput from '../baseComponents/SearchInput';
import { useEditGroupMembershipHook } from '../hooks/EditGroupMembershipHook';
import { getGroupPageUrl } from '../lib/groups';
import YesCancelModal from './Modals/YesCancelModal';
import { SmallSpinner } from './SmallSpinner';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { Float } from '@headlessui-float/react';
import { MinusCircleIcon, PlusCircleIcon } from '@heroicons/react/24/outline';

const moreGroupsInfoCss = {
  normal: 'bg-gray-200 text-blueberry',
  flashing: 'bg-raspberry text-white scale-125',
};

//If the groupMembership.id is in some other groupMembership.ancestors, then we don't want to add it to the finalGroupMemberships
//In other words, if the taxonomy is A->B->C, and we have memberships in A, B, C, we only want C to be added to this list.
const getFinalGroupMemberships = (groupMemberships: GroupMembershipFragment[]): GroupMembershipFragment[] => {
  const finalGroupMemberships: GroupMembershipFragment[] = [];
  for (const groupMembership of groupMemberships) {
    if (!groupMemberships.some((otherGroupMembership) => otherGroupMembership.ancestors?.some((ancestor) => ancestor.id === groupMembership.id))) {
      finalGroupMemberships.push(groupMembership);
    }
  }
  return finalGroupMemberships;
};

export const GroupMembershipSection = ({ entry, amountToShow = 4 }: { entry: EntryFragment; amountToShow?: number }) => {
  const [groupMemberships, setGroupMemberships] = useState<GroupMembershipFragment[]>(getFinalGroupMemberships(entry.groupMemberships ?? []));

  const [isShowing, setIsShowing] = useState(false);
  const [moreGroupsInfoColor, setMoreGroupsInfoColor] = useState<string>(moreGroupsInfoCss['normal']);
  const { curTeamId: teamId, curOrgId: orgId } = useValidTeamAppContext();

  const [showAll, setShowAll] = useState(false);

  const editGroupMembershipHook = useEditGroupMembershipHook();

  const flashMoreGroupsInfoBg = () => {
    setMoreGroupsInfoColor(moreGroupsInfoCss['flashing']);
    setTimeout(() => {
      setMoreGroupsInfoColor(moreGroupsInfoCss['normal']);
    }, 750);
  };

  /**
   * Modifies the relationship between a group and the entry.
   *
   * I removed references to the GroupHook. The caveat of this is that if you're on the group page and you delete the group entry
   * relationship via this mechanism the entry wont dissappear from the list on the group page.
   *
   * (eh) I think technically we'd want to delete the entry from the list but this is an edge case that I'm intentionally not covering.
   *
   * If we need to come cover this edge case we'll need to prompt the GroupEntriesDispatch up to context and get the dispatch from context.
   * Not doing this now though.
   */
  const handleModifyMembership = async (groupId: string, action: Group_Membership_Action) => {
    try {
      const updatedMemberships = await editGroupMembershipHook.handleModifyMembership({ entryId: entry.id, teamId, groupId, action });
      setGroupMemberships(getFinalGroupMemberships(updatedMemberships));
      flashMoreGroupsInfoBg();
      if (action === 'add') toast.success('Successfully added entry to the group and any inherited parent groups');
      else toast.success('Removed entry from the group and any inherited parent groups');
    } catch (err) {
      console.error(err);
      if (action === 'add') toast.error('Failed to add entry to group');
      else toast.error('Failed to remove entry from the group');
    }
  };

  const amountToHide = groupMemberships.length - amountToShow;

  return (
    <div className={classNames('flex flex-row gap-x-2 gap-y-1 flex-wrap', showAll ? ' items-start mt-2' : 'items-center')}>
      <p className="text-sm font-semibold text-gray-500">
        <i>Groups:</i>
      </p>
      {!showAll ? (
        <>
          {groupMemberships.slice(0, amountToShow).map((groupLight, index) => {
            return (
              <GroupOnEntryBadge
                groupLight={groupLight}
                key={index}
                handleRemoveMembership={(groupId: string) => handleModifyMembership(groupId, Group_Membership_Action.Remove)}
                trimTitle={true}
              />
            );
          })}
          {amountToHide > 0 ? (
            <Popover>
              <Popover.Group>
                <Popover.Button
                  onMouseEnter={() => setIsShowing(true)}
                  onMouseLeave={() => setIsShowing(false)}
                  className={classNames(
                    'items-center group flex flex-row gap-x-1 duration-300 py-1 px-3 rounded-lg text-xs cursor-default flex-wrap whitespace-nowrap',
                    moreGroupsInfoColor
                  )}
                >
                  <h1>+{amountToHide}</h1>
                </Popover.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                  show={isShowing}
                >
                  <Popover.Panel
                    className={`absolute z-10 w-96 px-6 py-3 transform  -translate-x-[90%]  sm:px-0`}
                    onMouseEnter={() => setIsShowing(true)}
                    onMouseLeave={() => setIsShowing(false)}
                    onPointerDown={(e) => e.stopPropagation()}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div className="max-h-32 gap-x-1 gap-y-1 bg-white rounded-lg shadow-md ring-1 ring-black ring-opacity-5 px-3 py-2 flex flex-row overflow-x-hidden overflow-y-auto flex-wrap">
                      {groupMemberships.slice(amountToShow)!.map((groupLight, index) => (
                        <GroupOnEntryBadge
                          groupLight={groupLight}
                          key={index}
                          handleRemoveMembership={(groupId: string) => handleModifyMembership(groupId, Group_Membership_Action.Remove)}
                          trimTitle={true}
                          isInsidePopover={true}
                        />
                      ))}
                    </div>
                  </Popover.Panel>
                </Transition>
              </Popover.Group>
            </Popover>
          ) : null}
          {groupMemberships.length > 0 ? (
            <div
              role="button"
              className="flex flex-row gap-x-1 text-blueberry items-center"
              onClick={(e) => {
                e.stopPropagation();
                setShowAll(true);
              }}
            >
              <p className="underline text-xs">Show all</p>
            </div>
          ) : null}
        </>
      ) : (
        <>
          <AllMemberships groupMemberships={groupMemberships} />
          <div
            role="button"
            className="flex flex-row gap-x-1 text-blueberry items-center"
            onClick={(e) => {
              e.stopPropagation();
              setShowAll(false);
            }}
          >
            <p className="underline text-xs">Hide</p>
          </div>
        </>
      )}
      <ChooseGroupPopover
        existingMemberships={groupMemberships}
        handleAddMembership={(groupId: string) => handleModifyMembership(groupId, Group_Membership_Action.Add)}
      />
    </div>
  );
};

const AllMemberships = ({ groupMemberships }: { groupMemberships: GroupMembershipFragment[] }) => {
  return (
    <div className="flex flex-col gap-y-1.5">
      {groupMemberships.map((groupMembership, index) => (
        <AncestorsBadge ancestors={groupMembership.ancestors ?? []} currentGroup={groupMembership} />
      ))}
    </div>
  );
};

const GroupOnEntryBadge = ({
  groupLight,
  key,
  handleRemoveMembership,
  trimTitle,
  isInsidePopover,
}: {
  groupLight: GroupMembershipFragment;
  key: number;
  handleRemoveMembership: (groupId: string) => Promise<void>;
  trimTitle?: boolean;
  isInsidePopover?: boolean;
}) => {
  const { curTeamId: teamId, curOrgId: orgId } = useValidTeamAppContext();
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [removeModalOpen, setRemoveModalOpen] = useState(false);
  const { hasPermission } = useContext(PermissionsContext);

  const handleRemoveFromGroup = async () => {
    setLoadingDelete(true);

    await handleRemoveMembership(groupLight.id);
    setLoadingDelete(false);
    setRemoveModalOpen(false);
  };

  // we do not want to render the trash icon if a user can't delete entries
  const deletionComponent = hasPermission(Resource.Entries, Action.Delete) ? (
    <div className="z-10 absolute -right-1.5 -top-1.5 opacity-0 transition-opacity duration-100 h-4 w-4 p-0.5 bg-red-600 rounded-full cursor-pointer text-milk hover:bg-red-700 group-hover/grouponentry:opacity-100">
      <Tippy theme="dark" delay={200} placement="top" content={<p className="text-center">Remove this feedback entry from this group</p>}>
        <TrashIcon
          id="remove-entry-from-group"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setRemoveModalOpen(true);
            setIsPopoverOpen(false);
          }}
        />
      </Tippy>
    </div>
  ) : (
    <></>
  );

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleMouseEnter = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setIsPopoverOpen(true);
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setIsPopoverOpen(false);
    }, 100); // Small delay to allow moving to panel
  };

  return (
    <Popover className="relative">
      <Float
        show={isPopoverOpen}
        placement={isInsidePopover ? 'top-start' : 'bottom-start'}
        offset={4}
        shift={6}
        flip={10}
        portal={true}
        enter="transition duration-200 ease-out"
        enterFrom="scale-95 opacity-0"
        enterTo="scale-100 opacity-100"
        leave="transition duration-100 ease-in"
        leaveFrom="scale-100 opacity-100"
        leaveTo="scale-95 opacity-0"
      >
        <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <Popover.Button className="flex flex-row items-center">
            <a
              href={getGroupPageUrl(teamId, orgId, groupLight.id, AppRoutes.v3FullPath.explore)}
              target="_blank"
              className="group/grouponentry relative items-center group flex flex-row duration-200 py-1 px-3 bg-gray-200 text-blueberry rounded-lg text-xs cursor-pointer flex-wrap whitespace-nowrap hover:bg-gray-300"
              onClick={async (e) => e.stopPropagation()}
            >
              {removeModalOpen ? (
                <YesCancelModal
                  text={`Are you sure you want to remove this entry from group "${groupLight.title}"?`}
                  subtext="This will also remove it from all children and inherited parents"
                  modalOpen={removeModalOpen}
                  confirmText="Yes, remove"
                  confirmButton={handleRemoveFromGroup}
                  callbackModal={() => {
                    setRemoveModalOpen(false);
                  }}
                  loadingConfirm={loadingDelete}
                />
              ) : null}
              <div>
                {loadingDelete ? (
                  <div
                    data-testid="sentence-remove-loading"
                    className="z-10 absolute -right-1.5 -top-1.5 duration-100 h-3.5 w-3.5 p-1 bg-gray-500 rounded-full cursor-pointer text-milk opacity-80 ring-0 focus:ring-0"
                  >
                    <AdjustableLoadingIcon color={'text-milk'} width={1.5} height={1.5} />
                  </div>
                ) : (
                  deletionComponent
                )}
              </div>
              <p className="text-xxs pt-0.5">{trimTitle ? truncateAndEllipsis(groupLight.title, 40) : groupLight.title}</p>
            </a>
          </Popover.Button>
        </div>

        <Popover.Panel
          static
          className="bg-[#333333] flex flex-row min-w-max line-clamp-1 font-sofiapro px-3 py-0.5 rounded-md shadow-lg z-50"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <AncestorsPopover ancestors={groupLight.ancestors ?? []} currentGroup={groupLight} />
        </Popover.Panel>
      </Float>
    </Popover>
  );
};

const AncestorsChain = ({
  ancestors,
  currentGroup,
  isDarkMode = false,
}: {
  ancestors: GroupLightFragment[];
  currentGroup: GroupLightFragment;
  isDarkMode?: boolean;
}) => {
  const { curTeamId: teamId, curOrgId: orgId } = useValidTeamAppContext();
  return (
    <div className="flex flex-row line-clamp-1">
      {ancestors.map((ancestor, index) => (
        <div key={ancestor.id} className="flex flex-row items-center">
          <a
            href={getGroupPageUrl(teamId, orgId, ancestor.id, AppRoutes.v3FullPath.explore)}
            target="_blank"
            className={`text-sm ${isDarkMode ? 'text-white' : 'text-blueberry font-semibold'} hover:underline`}
          >
            {isDarkMode ? ancestor.title : truncateAndEllipsis(ancestor.title, 30)}
          </a>
          <ChevronRightIcon className={`h-5 w-5 ${isDarkMode ? 'text-gray-400' : 'text-blueberry'} mx-1`} aria-hidden="true" />
        </div>
      ))}
      <a
        href={getGroupPageUrl(teamId, orgId, currentGroup.id, AppRoutes.v3FullPath.explore)}
        target="_blank"
        className={`text-sm ${isDarkMode ? 'text-gray-300' : 'text-blueberry'} hover:underline`}
      >
        {currentGroup.title}
      </a>
    </div>
  );
};

const AncestorsBadge = ({ ancestors, currentGroup }: { ancestors: GroupLightFragment[]; currentGroup: GroupLightFragment }) => {
  return (
    <div className="flex flex-row line-clamp-1 bg-gray-200 rounded-md px-2 py-0.5">
      <AncestorsChain ancestors={ancestors} currentGroup={currentGroup} />
    </div>
  );
};

const AncestorsPopover = ({ ancestors, currentGroup }: { ancestors: GroupLightFragment[]; currentGroup: GroupLightFragment }) => {
  return <AncestorsChain ancestors={ancestors} currentGroup={currentGroup} isDarkMode={true} />;
};

const ChooseGroupPopover = ({
  existingMemberships,
  handleAddMembership,
}: {
  existingMemberships: GroupMembershipFragment[];
  handleAddMembership: (groupId: string) => Promise<void>;
}) => {
  const { curTeamId: teamId, curOrgId: orgId } = useValidTeamAppContext();
  //This is cached so it's only fetched once.
  const { data: allGroupsData, loading: allGroupsLoading } = useGetGroupsLightQuery({ variables: { teamId, status: Group_Status.Monitored } });
  const groupsToShow = allGroupsData?.getAllGroups.filter((group) => !existingMemberships.some((membership) => membership.id === group.id));
  const { hasPermission } = useContext(PermissionsContext);
  const [loadingAdd, setLoadingAdd] = useState(false);

  const [searchString, setSearchString] = useState<string>('');

  const filteredGroups = groupsToShow?.filter((group) => group?.title.toLowerCase().includes(searchString.toLowerCase()));
  return (
    <Popover>
      <Popover.Group>
        {hasPermission(Resource.Entries, Action.Update) ? (
          <Popover.Button
            className={classNames('group flex flex-row duration-150 focus:outline-none text-blueberry flex-wrap whitespace-nowrap cursor-pointer')}
          >
            <PlusCircleIcon className="h-5 w-5" />
          </Popover.Button>
        ) : null}
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel
            className={`absolute z-50 w-96 px-6 py-3 transform sm:px-0`}
            onPointerDown={(e) => e.stopPropagation()}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="max-h-64 gap-x-1 gap-y-1 bg-white rounded-lg shadow-md px-3 py-2 flex flex-row  overflow-y-auto flex-wrap">
              <div className="flex flex-row gap-x-3 items-center">
                <h1 className="font-bold">{loadingAdd ? 'Adding entry to group...' : 'Add entry to group'}</h1>
                {loadingAdd ? <SmallSpinner /> : null}
              </div>
              <SearchInput
                noPadding
                onSearch={() => {}}
                setQueryString={(string) => setSearchString(string ?? '')}
                queryString={searchString}
                placeholder="Search group..."
              />
              <div className={classNames(loadingAdd ? 'opacity-70' : '', 'w-full')}>
                {filteredGroups?.map((groupLight, index) => (
                  <div
                    key={index}
                    className={classNames(
                      'text-blueberry w-full px-2 py-1 duration-150 rounded-md',
                      loadingAdd ? 'cursor-default' : 'hover:bg-blueberry hover:text-white w-full px-2 py-1 duration-150 cursor-pointer rounded-md'
                    )}
                    onClick={async (e) => {
                      e.stopPropagation();
                      if (!groupLight || loadingAdd) return;
                      setLoadingAdd(true);
                      await handleAddMembership(groupLight.id);
                      setSearchString('');
                      setLoadingAdd(false);
                    }}
                  >
                    <h1 className="text-sm">{groupLight?.title}</h1>
                  </div>
                ))}
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover.Group>
    </Popover>
  );
};

import { GroupDataFragment } from '../../generated/graphql';
import { AddEntryPayload, CreateGroupPayload, RemoveEntryPayload, SearchPreviewAction, SetSearchTermPayload } from '../actions/searchPreview';
import { defaultGroup } from '../lib/searchPreview';

export interface SearchPreviewReducerResult {
  group: GroupDataFragment;
  belongingIds: string[];
  excludedIds: string[];
  searchTerm: string;
  excludeFromNewFilter: boolean;
}

export const searchPreviewReducer = (searchState: SearchPreviewReducerResult, action: SearchPreviewAction): SearchPreviewReducerResult => {
  switch (action.type) {
    case 'removeEntry': {
      const payload = action.payload as RemoveEntryPayload;
      return {
        ...searchState,
        belongingIds: searchState.belongingIds.filter((id) => id !== payload.id),
        excludedIds: [...searchState.excludedIds, payload.id],
      };
    }

    case 'addEntry': {
      const payload = action.payload as AddEntryPayload;
      return {
        ...searchState,
        belongingIds: [...searchState.belongingIds, payload.id],
        excludedIds: searchState.excludedIds.filter((id) => id !== payload.id),
      };
    }
    case 'createGroup': {
      const payload = action.payload as CreateGroupPayload;
      const group = payload.group;
      payload.onCompletedCallback?.();
      return {
        ...searchState,
        group: group,
        belongingIds:
          (group.groupEntries
            ?.filter((ge) => ge.belongsToGroup)
            .map((ge) => ge.mappedSentences[0].entry?.id)
            .filter((ge) => ge != null) as string[]) || [],
        excludedIds:
          (group.groupEntries
            ?.filter((ge) => !ge.belongsToGroup)
            .map((ge) => ge.mappedSentences[0].entry?.id)
            .filter((ge) => ge != null) as string[]) || [],
      };
    }
    case 'setSearchTerm': {
      const payload = action.payload as SetSearchTermPayload;
      return {
        ...searchState,
        searchTerm: payload.searchTerm,
      };
    }
    case 'clearGroup': {
      return {
        group: defaultGroup,
        belongingIds: [],
        excludedIds: [],
        searchTerm: '',
        excludeFromNewFilter: false,
      };
    }
    case 'toggleExclude': {
      return {
        ...searchState,
        excludeFromNewFilter: !searchState.excludeFromNewFilter,
      };
    }
    default: {
      return searchState;
    }
  }
};

export enum Status {
    idle,
    initializing,
    processing,
    finished,
    error,
}

export interface ProgressState {
    status: Status,
    percent: number;
}

/**
 * A progress monitor is a class that handles some long-running process that needs progress observability for the UX.
 * Classes implementing this interface can be queried for the status of the job (what percentage of the work has been done?)
 * Example: A long export that the user waits for.
 */
export interface ProgressMonitor {
    getProgress() : ProgressState
}
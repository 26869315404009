import { Feedback_Integration_Type, Integration_Type, Integration_Type_Requirement } from '../../../../../generated/graphql';
import { DataImportIntegrationForm } from './forms/DataImportIntegrationForm';
import { ExternalTicketsIntegrationForm } from './forms/ExternalTicketsIntegrationForm';

interface EditIntegrationModalProps {
  modalOpen: boolean;
  setModalOpen: (open: boolean) => void;
  integration: Integration_Type;
  requirements: Integration_Type_Requirement[];
  setRequirements: (reqs: Integration_Type_Requirement[]) => void;
  setSuccessModalOpen?: (open: boolean) => void;
}

export const IntegrationForm = ({ modalOpen, setModalOpen, requirements, setRequirements, integration, setSuccessModalOpen }: EditIntegrationModalProps) => {
  if (integration.feedbackIntegrationType === Feedback_Integration_Type.DataImport) {
    return (
      <DataImportIntegrationForm
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        requirements={requirements}
        setRequirements={setRequirements}
        integration={integration}
        setSuccessModalOpen={setSuccessModalOpen}
      />
    );
  }
  if (integration.feedbackIntegrationType === Feedback_Integration_Type.ExternalTickets) {
    return (
      <ExternalTicketsIntegrationForm
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        requirements={requirements}
        setRequirements={setRequirements}
        integration={integration}
        setSuccessModalOpen={setSuccessModalOpen}
      />
    );
  }
  return <></>;
};

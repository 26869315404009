import { useState } from 'react';
import { useOrgInfoState } from '../../../context/orgInfoContext';
import { Domain, Role_Attachments, TeamDataFragment, Users } from '../../../generated/graphql';
import { sortOrgUsersByName } from '../../../utilities';
import SearchInput from '../../baseComponents/SearchInput';
import { PermissionsEditorForAView } from './PermissionsEditor';

//Displays all users with the roles they have on the current view
//If no view is passed (aka a new view is being created), it will use the defaults.
export const UsersPermissionsEditingSection = ({ view }: { view?: TeamDataFragment }) => {
  const [searchInput, setSearchInput] = useState('');
  const { organization } = useOrgInfoState();
  const validUsers = sortOrgUsersByName(organization.orgUser)
    .map((orgUser) => orgUser.user)
    .filter((user) => !!user) as Users[];

  const filteredUsers = validUsers.filter((user) => {
    if (searchInput === '') return true;
    const fullName = `${user.firstName || ''} ${user.lastName || ''}`.toLowerCase();
    const email = user.email.toLowerCase();
    const search = searchInput.toLowerCase();

    return email.includes(search) || fullName.includes(search);
  });

  const viewRoleAttachments: Role_Attachments[] = validUsers
    .flatMap((validUser) => validUser.roles)
    .filter((role) => role.domain === Domain.Team && role.domainId === (view?.id ?? -1));

  return (
    <div className="flex flex-col items-center font-semibold gap-y-3 text-blueberry">
      <h1>Permissions per user</h1>
      <div className="flex w-full">
        <SearchInput
          noPadding
          rounded="md"
          placeholder={`Browse ${organization.orgUser.length} members...`}
          onSearch={() => {}}
          onChange={(e) => setSearchInput(e.target.value)}
          onClear={() => setSearchInput('')}
        />
      </div>
      <PermissionsEditorForAView users={filteredUsers} currentRoles={view ? viewRoleAttachments : []} isFiltered={searchInput !== ''} />
    </div>
  );
};

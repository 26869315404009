import { ArrowDownTrayIcon } from '@heroicons/react/24/solid';
import { exportFeedbackWithProgressUpdate } from '../../../../../eventHandlers/exports/feedbackExport';
import { FilterInput } from '../../../../../generated/graphql';
import Tippy from '@tippyjs/react';

export const ExportToCSV = ({
  teamId,
  filterInput,
  exportProgress,
  updateExportProgress,
}: {
  teamId: number;
  filterInput: FilterInput;
  exportProgress: any;
  updateExportProgress: any;
}) => {
  return (
    <Tippy theme="dark" content="Export to CSV" delay={200}>
      <div
        className="rounded-full h-full gap-x-2 bg-silver text-blueberry flex flex-row justify-center items-center cursor-pointer p-2 duration-200 hover:bg-blueberry hover:text-milk"
        onClick={() => exportFeedbackWithProgressUpdate({ teamId: teamId, filterInput }, exportProgress, updateExportProgress)}
      >
        <div>
          <ArrowDownTrayIcon className="h-5 w-5 stroke-2" />
        </div>
      </div>
    </Tippy>
  );
};

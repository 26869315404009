type EventCallback = (data?: any) => void;

export enum Channels {
    ERRORS = 'ERRORS',
}

export enum ErrorCodes {
    UNAUTHORIZED = 'UNAUTHORIZED'
}

export interface ErrorPayload {
    code?: string,
}

class ApolloClientBus {
    private channels: Map<string, EventCallback[]>;

    constructor() {
        this.channels = new Map();
    }

    subscribe(channel: string, callback: EventCallback) {
        if (!this.channels.has(channel)) {
            this.channels.set(channel, []);
        }
        this.channels.get(channel)?.push(callback);
    }

    unsubscribe(channel: string, callback: EventCallback) {
        if (this.channels.has(channel)) {
            const updatedCallbacks = this.channels.get(channel)?.filter((cb) => cb !== callback);
            this.channels.set(channel, updatedCallbacks || []);
        }
    }

    emit(channel: string, data?: any) {
        if (this.channels.has(channel)) {
            this.channels.get(channel)?.forEach((callback) => callback(data));
        }
    }
}

export const apolloClientBus = new ApolloClientBus();

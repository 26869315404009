import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon, EllipsisVerticalIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import { IDropDownItem } from '../../baseComponents/DropDown';
import { classNames } from '../../../v2/util';

export enum Align {
  Left,
  Right,
}

interface DropDownProps {
  id?: string;
  dropDownData?: IDropDownItem[];
  selectedItem?: IDropDownItem;
  /** When an item is selected the drop down item is passed back. You don't have to pass in an object that is only {name, id} it
   * can have other fields. It just has to have name & id. When this callback is invoked the full object will be passed back as the
   * selectedItem.
   * Checkout where this is used in the AliasComponent and you can see what I mean.
   */
  setSelectedItem: (selectedItem: IDropDownItem) => void;
  disabled?: boolean;
  align?: Align;
  useDisplayName?: boolean;
  placeholderText?: string;
  fixedPosition?: boolean;
  fullWidthText?: boolean;
  darkMode?: boolean;
}

const menuClassName = (align?: Align): string => {
  if (align === Align.Left) {
    return 'origin-top-left absolute mt-2 w-56 rounded-md shadow-md border border-silver bg-white z-40 outline-0 ring-0';
  }
  return 'origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-md border border-silver bg-white z-40 outline-0 ring-0';
};

const TemplateDropDown = ({
  id,
  dropDownData,
  selectedItem,
  setSelectedItem,
  useDisplayName = false,
  disabled,
  align,
  placeholderText,
  fixedPosition,
  fullWidthText,
  darkMode,
  ...props
}: DropDownProps): JSX.Element => {
  return (
    <Menu as="div" className="relative text-left focus:outline-0" id={id}>
      <div>
        <Menu.Button
          className={classNames(
            'inline-flex w-full justify-center rounded-md text-sm font-medium  outline-0 p-2 duration-200 transition-all',
            darkMode ? '  text-gray-400 hover:bg-black/25' : '  text-gray-400 hover:text-gray-500'
          )}
          disabled={disabled}
        >
          <span className="block truncate pr-2">
            <p>Templates</p>
          </span>
          <span className=" inset-y-0 right-0 flex items-center">
            <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
          </span>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className={menuClassName(align)} style={{ ...(fixedPosition && { position: 'fixed' }) }}>
          <div className="max-h-64 overflow-y-auto py-1 ">
            <Menu.Item>
              <div className="flex flex-row justify-between items-center cursor-default break-words px-4 py-2 text-sm text-gray-900 duration-100">
                <p>Choose a template</p>
                <Tippy content="Use one of our templates of common responses to feedback" theme="light" placement="right">
                  <InformationCircleIcon className="w-5 h-5" />
                </Tippy>
              </div>
            </Menu.Item>
            {dropDownData?.map((item) => {
              return (
                <Menu.Item key={item.id}>
                  <div
                    id={item.htmlId}
                    className="block cursor-pointer break-words px-4 py-2 text-sm text-gray-900 duration-100 hover:bg-indigo-50"
                    onClick={() => {
                      setSelectedItem(item);
                    }}
                  >
                    {item.displayName ?? item.name ?? item.title}
                  </div>
                </Menu.Item>
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default TemplateDropDown;

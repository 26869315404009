import { defaultDataIdFromObject, InMemoryCache } from '@apollo/client';

export const cache: InMemoryCache = new InMemoryCache({
  dataIdFromObject: (object) => {
    switch (object.__typename) {
      case 'ThemeSentimentStat':
        return JSON.stringify(object);
      default:
        return defaultDataIdFromObject(object);
    }
  },
  typePolicies: {
    Query: {
      fields: {
        entries: {
          // this cache definition supports the feedback page.
          keyArgs: (args: any) => {
            return JSON.stringify({
              teamId: args['teamId'],
              repliableOnly: args['repliableOnly'],
              filterInput: args['filterInput'],
              // do not include take and skip here.
              // we should not use this cache to store paginated data, this is super indirect and very difficult to understand what's happening here.
              // skip: args['skip'],
              // take: args['take'],
            });
          },
          merge(existing = [], incoming) {
            return [...existing, ...incoming];
          },
        },
      },
    },
  },
});

/**
 * Set initial values when we create cache variables.
 */

import React, { useContext } from 'react';
import { AnswerComponentFactory } from './answering/AnswerComponentFactory';
import { AssistantDataContext } from '../../../context/assistantContext';
import { AssistantStatus } from '../../../reducers/assistant/AssistantDispatch';
import { ErrorIcon } from 'react-hot-toast';
import { TextLoading } from './answering/TextLoading';
import { UserRating } from './UserRating';

function SummaryBubble({label} : {label: string}) {
  return <div
    className={'py-1 px-3 inline-flex items-center rounded-3xl border-0 text-center text-sm outline-none bg-silver text-blueberry'}>{label}
  </div>;
}

export function OverviewSection() {
  const summaries = useContext(AssistantDataContext).summaries;

  return <div>
    <div className="flex flex-row width-full">
      { (summaries.length ? <h1 className={'text-xl font-semibold text-blueberry'}>Here is what I found</h1> : <></>) }
    </div>
    <div className="flex flex-row width-full my-2">
      {summaries.map((summary) => {
        return <SummaryBubble label={summary} />;
      })}
    </div>
  </div>
}

const renderComponentForStatus = (status: AssistantStatus) => {
  switch (status) {
    case AssistantStatus.idle:
      return <></>;
    case AssistantStatus.done:
    case AssistantStatus.streaming:
      return <div data-testid='answer-section'>
        <OverviewSection />
        <div className="gap-x-2 my-4">
          <AnswerComponentFactory />
        </div>
      </div>;
    case AssistantStatus.failed:
      return <div className={"flex flex-row text-error mt-3.5 ml-[-0.5rem]"}>
        <ErrorIcon className={'mx-2 flex-none'}/>
        <p>Sorry, we weren't able to get an answer to that question. We're constantly improving our capabilities, and we've made a note of this question so we can improve the future experience for you!</p>
      </div>;
    case AssistantStatus.loading:
      return <div className="flex flex-row justify-between gap-x-2 w-full my-4">
        <div className={'flex w-1/2 flex-col text-blueberry-light'}>
          <TextLoading />
        </div>
      </div>
    default:
      throw new Error('Unknown Assistant Status');
  }
}

export function AnswerSection() {
  const { status, question } = useContext(AssistantDataContext);
  return (
    <div>
      {question && <h1 className={'text-blueberry font-light text-3xl'}><strong className={'font-medium'}>Question: </strong> {question}</h1>}
      <hr className={'my-2'}/>

      {question && <div className={'flex flex-wrap'}>
        <strong className={'text-blueberry font-medium text-3xl'}>Answer</strong>
        {(status === AssistantStatus.loading || status === AssistantStatus.streaming) &&

          <div className={'flex flex-col items-center ml-4'}>
            <div className={'inline-block rounded-full animate-fancy-spin border-b-2 border-blueberry h-4 w-4'} />
          </div>
        }
      </div>}
      {renderComponentForStatus(status)}
      <div className={'mt-4 flex flex-row'}>
        <div className={'flex flex-col justify-center w-1/2'}>
          {status === AssistantStatus.done && <UserRating/>}
        </div>
      </div>
    </div>)
}

import { useEffect, useState } from 'react';
import { Feedback_Integration_Type, Integration_Type, useIntegrationsQuery } from '../../../generated/graphql';
import { useValidTeamAppContext } from '../../../v2/contexts/AppContext';
import { IIntegrationRedirect, IntegrationModalState, paramsToObject } from '../../pages/IntegrationsPage';
import InformationModal from '../Modals/InformationModal';
import IntegrationCard from '../IntegrationCard';

export const OrgIntegrationsList = () => {
  const { curTeamId: teamId, curOrgId: orgId } = useValidTeamAppContext();

  const { data } = useIntegrationsQuery({
    variables: { teamId, orgId, feedbackIntegrationType: Feedback_Integration_Type.ExternalTickets },
  });

  const [modalState, setModalState] = useState<IntegrationModalState | undefined>(IntegrationModalState.NoModal);
  const [currentModalId, setCurrentModalId] = useState<number | undefined>(undefined);

  const [redirect, setRedirect] = useState<IIntegrationRedirect | undefined>(undefined);
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const urlObject = paramsToObject(urlSearchParams.entries());
    // pull out an authToken, orgId, and key from query
    const { authToken, key: scraperKey, orgId: orgIdQuery, ...others } = urlObject;
    // we have a valid redirect if all these be true
    let redir: IIntegrationRedirect | undefined = undefined;
    if (authToken && scraperKey && orgIdQuery && Number(orgIdQuery) === orgId) {
      redir = {
        authToken,
        scraperKey,
        teamId,
        additionalObjects: others,
      };
    }
    setRedirect(redir);
    //clear url params
    window.history.replaceState({}, '', window.location.pathname);
  }, []);

  return (
    <div className="flex flex-col divide-y divide-gray-200">
      <InformationModal
        modalOpen={modalState === IntegrationModalState.Success}
        callbackModal={() => {
          setModalState(undefined);
        }}
        text={'Connection created successfully!'}
        subtext={'The connection has been created on your organization.'}
      />

      {data?.integrations?.map((integration, index) => (
        <IntegrationCard
          //@ts-ignore
          integration={integration}
          key={index}
          redirect={redirect}
          setSuccessModalOpen={() => {
            setModalState(IntegrationModalState.Success);
            setCurrentModalId(undefined);
          }}
          modalOpen={currentModalId === integration.id && modalState === IntegrationModalState.Card}
          setModalOpen={(bool) => {
            handleOpenIntegrationCard(
              //@ts-ignore
              integration,
              bool,
              (state) => setModalState(state),
              (val) => setCurrentModalId(val)
            );
          }}
        />
      ))}
    </div>
  );
};

const handleOpenIntegrationCard = (
  integration: Integration_Type,
  openModal: boolean,
  setModalState: (state: IntegrationModalState | undefined) => void,
  setCurrentModalId: (val: number | undefined) => void
) => {
  // close the integration card regardless
  if (!openModal) {
    setModalState(undefined);
    setCurrentModalId(undefined);
  }
  if (openModal) {
    // open integration card
    setCurrentModalId(integration.id);
    setModalState(IntegrationModalState.Card);
  }
};

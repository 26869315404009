import { any } from "underscore";
import { ProgressMonitor, ProgressState, Status } from "../../exports/ProgressMonitor";

/**
 * We have to do this so we know when to truly hide the progress bar. 
 * It is not sufficient to hide it when all the AJAX calls are complete because the actual downloading of the file can take several seconds. This can appear to the user as "lag."
 * 
 * The CSV generator we use will download the CSV file by appending an <a> tag to the document.body and then triggering a "click" event.
 * Unfortunately, the library does not allow us to invoke a callback.
 * 
 * To get around this, we create an observer on the document.body that listens for the anchor tag with a CSV download link.
 * When the anchor tag with our download link is removed from the DOM, then the download process is truly complete and we can remove the progress bar.
 * 
 * @param updateExportProgress - react function to update state of export progress
 * @returns void
 */
export const listenForFileDownload = (fileName: string, updateExportProgress: React.Dispatch<React.SetStateAction<ProgressState>>) => {
    const callback = (mutationList: MutationRecord[], observer: any) => {
        
      for (const mutation of mutationList) {
        if (mutation.addedNodes.length > 0) {
          if (any(mutation.addedNodes, (node) => containsDownloadLink(fileName, node))) {
            updateExportProgress({ status: Status.processing, percent: 99 });
          }
        }
        if(mutation.removedNodes.length > 0) {
          if (any(mutation.removedNodes,(node) => containsDownloadLink(fileName, node))) {
            updateExportProgress({ status: Status.finished, percent: 100 });
            observer.disconnect();
          }
        }
      }
    };
  
    const observer = new MutationObserver(callback);
    observer.observe(document.body, {childList: true, attributes: true})
  
    return observer;
  }

/**
 * Updates the React State of the ExportProgress every 100ms, which in turn will update a ProgressBar somewhere in the DOM.
 * We use the native browser API setInterval to make periodic updates
 * @param groupReport - an instance GroupReport responsible for fetching data and generating a report
 * @param updateExportProgress  - react state function used to update state of ProgressBar component
 * @returns - the id of the interval created. Make sure to clear this in the calling function. 
 * We will create a memory leak if this interval does not get cleared.
 */
export const updateProgressOnInterval = (report: ProgressMonitor, updateExportProgress: React.Dispatch<React.SetStateAction<ProgressState>>) => {
  const counter = 0;
    return window.setInterval(() => {
        const newProgress = report.getProgress();
        const progressPercent = Math.max(newProgress.percent, counter * 0.01 * 10);

        if (progressPercent > 0) {
            updateExportProgress({ status: Status.processing, percent: progressPercent });
        }

        if (progressPercent >= 99) {
            updateExportProgress({ status: Status.processing, percent: 99 });
        }

        if(progressPercent <= 0) {
          updateExportProgress({ status: Status.initializing, percent: 0 });
        }
        
    }, 100);
}

/**
 * Used by the observer to tell whether a node that is added to the body contains our file downloadl link
 * @param fileName - name of the file we are downloading to the browser
 * @param node - HTML DOM node
 * @returns boolan indicating whether the DOM node is an anchor tag with a download link for the specified fileName
 */
export const containsDownloadLink = (fileName: string, node: any) => {
  return node?.download?.match(fileName) != null;
}
import React, { FormEvent } from 'react';

interface FormProps {
  children?: JSX.Element | JSX.Element[];
  bottomRow: JSX.Element | JSX.Element[];
  onSubmit?: (e: FormEvent) => void;
  formRef?: React.MutableRefObject<HTMLFormElement | null>;
}

const Form = ({ children, bottomRow, onSubmit, formRef }: FormProps): JSX.Element => {
  return (
    <form
      ref={formRef ? formRef : null}
      className="space-y-8 divide-y divide-gray-200 flex flex-col justify-between h-full"
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit?.(e);
      }}
    >
      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5 flex flex-col h-full">
        <div className="overflow sm:rounded-md flex flex-col h-full">
          <div className="px-4 py-2 sm:p-6 sm:py-2 flex flex-col justify-between h-full">
            {children}
            {bottomRow}
          </div>
        </div>
      </div>
    </form>
  );
};

export default Form;

// PARSING FORMATTERS
/**
 * @description formats a string to lowercase letters and numbers
 * @param s a string
 * @returns a string s with only lowercase letters and numbers
 */
export function formatAlphaNumericLowercase(s: string): string {
    return s.replace(/[\W_]/g, '').toLowerCase().trim();
}

/**
 * @description turns the string "hello_World my code" to "helloworldmycode"
 * @param s arbitrary string
 * @returns a string with no hypens or spaces with lowercase format
 */
export function formatNoHyphensOrSpaceLowercase(s: string): string {
    return s.replace(/[_ ]/g, '').toLowerCase();
}

/**
 * @param num a number which may have more require commas
 * @returns the number with commas
 */
export function formatNumberWithCommas(num: number): string {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

/**
 * @param s a string
 * @returns string s with upper cased first letter of each word and replaces `_` with ` `
 *  ex `NPS_buckets` to `NPS Buckets`
 */
export function formatCapitalizeAndSpace(s: string): string {
    // Split the string into an array of words based on the "_" character
    const words: string[] = s.trim().split(/[!_,.\s]/);

    // Convert the first character of each word to uppercase
    const upperCaseWords: string[] = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));

    // Join the words back together with a space between them
    const formattedString: string = upperCaseWords.join(" ").trim();

    return formattedString;
}

/**
 * @description concatentates indexes of an array until either a maxChar or maxIdx is reached
 * @param arr the array to concatenate values from 
 * @param maxChars maximum number of characters that can be used
 * @param delimiter the delimiter to concat the indexes
 * @param maxIndxs the maximum range of elements
 * @returns 
 */
export function concatArrayAsString(arr: any[], maxChars: number, delimiter: string = ', ', maxIdxs: number = arr.length): string {
    return arr.slice(0, maxIdxs).reduce((acc, curr, i) => {
        const nextStr = acc + curr.toString() + delimiter;
        return nextStr.length <= maxChars ? nextStr : acc;
    }, '').slice(0, -delimiter.length);
}

// DATE FORMATTERS
/**
 * @description Turns the date from ISO format to human readable format for display reasons, note, the actual CSV is not edited
 * @param dateString the ISO date string created by csvHook.validate()
 * @returns the date string in the normal human expected format
 */
export function formatDate(dateString: string): string {
    // case 1) empty date
    if (dateString.trim() === "") return dateString;

    const date = new Date(dateString);

    // case 2) date is invalid
    if (isNaN(date.getTime())) {
        return dateString;
    }

    // case 3) date is valid
    const shortFormat = `${padZero(date.getMonth() + 1)}/${padZero(date.getDate())}/${date.getFullYear().toString().slice(-2)}`;

    return shortFormat;
}

/**
 * @description turns 8 into 08
 * @param num arbitrary number
 * @param targetLen the end length you want, defaults to 2
 * @returns a string with number padded with zeros starting from the front
 */
export function padZero(num: number, targetLen: number = 2): string {
    return num.toString().padStart(targetLen, '0');
}

import { InsightLightFragment, useGetInsightPreviewChartToDeleteQuery } from '../../../../generated/graphql';
import InsightChart from './InsightChart';
import { getFiltersFromInsight } from '../insights/EditInsightModal';

interface InsightChartSectionProps {
  insight: InsightLightFragment;
}

/**
 * This wraps the <InsightChart /> object with the appropriate pre-fetches to populate the chart from a given insight Id
 */
const InsightChartSection = ({ insight }: InsightChartSectionProps) => {
  // this is a little confusing here the filterInput isn't really used for anything other than display here. The actual filtering on the chart comes from the insight
  const filterInput = getFiltersFromInsight(insight);

  const { loading, data } = useGetInsightPreviewChartToDeleteQuery({
    variables: {
      teamId: insight.team.id,
      collectionId: insight.collection.id,
      collectionType: insight.collection.type,
      filterInput: filterInput,
      chartBin: insight.chartBin,
    },
  });

  return <InsightChart seriesData={data?.getInsightPreviewChartToDelete} filterInput={filterInput} loading={loading} />;
};

export default InsightChartSection;

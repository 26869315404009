import { Dispatch } from 'react';
import { DigestDataFragment, GetDigestSeriesQuery } from '../../generated/graphql';
import { NOT_FOUND } from '../utilities/consts';

export type DigestSeriesDataType = GetDigestSeriesQuery['digestSeries'][0];
export interface DigestDataState {
  digestSeriesData: DigestSeriesDataType[];
  digests: DigestDataFragment[];
  /**
   * These selectedDigestObject are either a Digest or a DigestSeries
   */
  selectedDigestObjectType: DigestObjectType;
  selectedDigestObject: DigestDataFragment | DigestSeriesDataType | undefined;
}

export enum DigestDataActions {
  SetDigestSeriesData,
  SetDigestData,
  AddDigestSeriesItem,
  SetSelectedDigestObject,
  AddDigestItem,
  UpdateAutoPopulateSGList,
  SetDigestSubject,
}

export enum DigestObjectType {
  Series,
  Digest,
}

type SetSelectedDigestObjectPayload = {
  digestObjectType: DigestObjectType;
  selectedObject: DigestSeriesDataType | DigestDataFragment; // |DigestDataType
};
type SetDigestSeriesDataPayload = {
  digestSeriesData: DigestSeriesDataType[];
};
type SetDigestDataPayload = {
  digests: DigestDataFragment[];
};
type AddDigestItemPayload = {
  item: DigestDataFragment;
};
type AddDigestSeriesItemPayload = {
  item: DigestSeriesDataType;
};
type UpdateAutoPopulateSGListPayload = {
  digestSeriesId: number;
  autoPopulateSGList: boolean;
};
type SetDigestSubjectPayload = {
  subject: string;
};

export type DigestDataActionPayloads =
  | SetDigestSeriesDataPayload
  | AddDigestSeriesItemPayload
  | SetSelectedDigestObjectPayload
  | AddDigestItemPayload
  | SetDigestDataPayload
  | UpdateAutoPopulateSGListPayload
  | SetDigestSubjectPayload;

export type DigestDataDispatch = Dispatch<{
  type: DigestDataActions;
  payload: DigestDataActionPayloads;
}>;

export const getInitialState = (): DigestDataState => {
  // handle loading digest/digestSeries object from local storage
  const persistedDigestObject = fetchSelectedObjectFromStore();

  return {
    digestSeriesData: [],
    digests: [],
    selectedDigestObjectType: persistedDigestObject ? persistedDigestObject.digestObjectType : DigestObjectType.Series,
    selectedDigestObject: persistedDigestObject ? persistedDigestObject.selectedObject : undefined,
  };
};

export const DigestDataReducer = (state: DigestDataState, action: { type: DigestDataActions; payload: DigestDataActionPayloads }): DigestDataState => {
  switch (action.type) {
    case DigestDataActions.SetDigestSeriesData:
      return setDigestSeriesData(state, action.payload as SetDigestSeriesDataPayload);
    case DigestDataActions.AddDigestSeriesItem:
      return addDigestSeriesItem(state, action.payload as AddDigestSeriesItemPayload);
    case DigestDataActions.SetSelectedDigestObject:
      return setSelectedDigestObject(state, action.payload as SetSelectedDigestObjectPayload);
    case DigestDataActions.AddDigestItem:
      return addDigestItem(state, action.payload as AddDigestItemPayload);
    case DigestDataActions.SetDigestData:
      return setDigests(state, action.payload as SetDigestDataPayload);
    case DigestDataActions.UpdateAutoPopulateSGList:
      return UpdateAutoPopulateSGList(state, action.payload as UpdateAutoPopulateSGListPayload);
    case DigestDataActions.SetDigestSubject:
      return setDigestSubject(state, action.payload as SetDigestSubjectPayload);
    default:
      throw new Error(`I do no know how to do that action ${action.type}`);
  }
};

const setDigestSubject = (state: DigestDataState, payload: SetDigestSubjectPayload): DigestDataState => {
  // Only update if selected object is a digest
  if (state.selectedDigestObjectType === DigestObjectType.Digest && state.selectedDigestObject) {
    const updatedDigest = { ...state.selectedDigestObject, subject: payload.subject } as DigestDataFragment;

    const updatedDigests = state.digests.map((digest) => (digest.id === updatedDigest.id ? updatedDigest : digest));

    return {
      ...state,
      selectedDigestObject: updatedDigest,
      digests: updatedDigests,
    };
  }
  return state;
};

const UpdateAutoPopulateSGList = (state: DigestDataState, payload: UpdateAutoPopulateSGListPayload): DigestDataState => {
  const { digestSeriesId, autoPopulateSGList } = payload;

  const updatedDigestSeriesData = state.digestSeriesData.map(series =>
    series.id === digestSeriesId ? { ...series, autoPopulateSGList } : series
  );

  let updatedSelectedObject = state.selectedDigestObject;
  if (
    state.selectedDigestObjectType === DigestObjectType.Series &&
    state.selectedDigestObject?.id === digestSeriesId
  ) {
    updatedSelectedObject = { ...state.selectedDigestObject, autoPopulateSGList } as DigestSeriesDataType;
  }

  return {
    ...state,
    digestSeriesData: updatedDigestSeriesData,
    selectedDigestObject: updatedSelectedObject,
  };
};

const setDigests = (state: DigestDataState, payload: SetDigestDataPayload): DigestDataState => {
  const newState: DigestDataState = {
    ...state,
    digests: payload.digests,
  };

  // handle loading digest/digestSeries object from local storage
  const persistedDigestObject = fetchSelectedObjectFromStore();
  if (persistedDigestObject) {
    newState.selectedDigestObject = persistedDigestObject.selectedObject;
    newState.selectedDigestObjectType = persistedDigestObject.digestObjectType;
  }

  // When we mutate a Digest object apollo is smart enough to stick the new Digest object data in the client side cache and refire the
  // event that triggers setDigests. This code block checks to see if we have a digest object and if it's in the new data returned will
  // stick the updated data into the digest object
  if (state.selectedDigestObjectType === DigestObjectType.Digest) {
    const newDigestDataIndex = payload.digests.findIndex((dig) => dig.id === state.selectedDigestObject?.id);
    if (newDigestDataIndex !== NOT_FOUND) {
      newState.selectedDigestObject = payload.digests[newDigestDataIndex];
    }
  }
  return newState;
};

const addDigestItem = (state: DigestDataState, payload: AddDigestItemPayload): DigestDataState => {
  return {
    ...state,
    selectedDigestObject: payload.item,
    selectedDigestObjectType: DigestObjectType.Digest,
    digests: [payload.item, ...state.digests],
  };
};

const addDigestSeriesItem = (state: DigestDataState, payload: AddDigestSeriesItemPayload): DigestDataState => {
  return {
    ...state,
    selectedDigestObject: payload.item,
    selectedDigestObjectType: DigestObjectType.Series,
    digestSeriesData: [payload.item, ...state.digestSeriesData],
  };
};

const setDigestSeriesData = (state: DigestDataState, payload: SetDigestSeriesDataPayload) => {
  return {
    ...state,
    digestSeriesData: payload.digestSeriesData,
  };
};

const setSelectedDigestObject = (state: DigestDataState, payload: SetSelectedDigestObjectPayload): DigestDataState => {
  // assert that payload.selectedObject matches type
  if (payload.digestObjectType === DigestObjectType.Digest) {
    if (payload.selectedObject.__typename !== 'digest') {
      throw new Error(`Set object type to digest but the selecetedObject passed in does not equal the expected type`);
    }
    // assert that payload.selectedObject matches type
  } else {
    if (payload.selectedObject.__typename !== 'digest_series') {
      throw new Error(`Set object type to digest_series but the selecetedObject passed in does not equal the expected type`);
    }
  }
  persistSelectedObject(payload);
  return {
    ...state,
    selectedDigestObject: payload.selectedObject,
    selectedDigestObjectType: payload.digestObjectType,
  };
};

const persistSelectedObject = (payload: SetSelectedDigestObjectPayload): void => {
  localStorage.setItem('selectedDigestObject', JSON.stringify(payload.selectedObject));
  localStorage.setItem('selectedDigestObjectType', JSON.stringify(payload.digestObjectType));
};

const fetchSelectedObjectFromStore = (): SetSelectedDigestObjectPayload | undefined => {
  const selectedObject = localStorage.getItem('selectedDigestObject');
  const selectedObjectType = localStorage.getItem('selectedDigestObjectType');
  if (selectedObject && selectedObjectType) {
    return {
      selectedObject: JSON.parse(selectedObject),
      digestObjectType: JSON.parse(selectedObjectType),
    };
  }
  return undefined;
};

import moment from 'moment';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { classNames } from '../../v2/util';
import { Date_Window } from '../../generated/graphql';

export interface HorizontalDateSelectorOption {
  name: string;
  startDate: Date;
  endDate: Date;
  disabled?: boolean;
  date_window?: Date_Window;
}

interface HorizontalDateSelectorProps {
  options: HorizontalDateSelectorOption[];
  onChooseDate: (newStartDate?: Date, newEndDate?: Date, option?: HorizontalDateSelectorOption) => void;
  defaultSelect?: HorizontalDateSelectorOption;
  //dateChange gets called when we modify the date from the range picker, which is outside this component.
  dateChange?: (startDate: Date, endDate: Date) => void;
}

export const HorizontalDateSelector = forwardRef(({ options, onChooseDate, defaultSelect, dateChange }: HorizontalDateSelectorProps, ref) => {
  const [selectedOption, setSelectedOption] = useState<HorizontalDateSelectorOption | undefined>(defaultSelect);

  const handleOptionClick = (option: HorizontalDateSelectorOption) => {
    setSelectedOption(option);
    onChooseDate(option.startDate, option.endDate, option);
  };

  useImperativeHandle(ref, () => ({
    //This is used by the Date Picker to update the selected option (including none).
    updateSelectedOption: (startDate: Date, endDate: Date) => {
      const newOption = options.find((option) => {
        return moment(option.startDate).isSame(moment(startDate), 'day') && moment(option.endDate).isSame(moment(endDate), 'day');
      });
      setSelectedOption(newOption);
    },
    //Change to default
    reset: () => {
      setSelectedOption(options[2]);
    },
    //Set by date_window
    setOptionByDateWindow: (date_window: Date_Window) => {
      const newOption = options.find((option) => option.date_window === date_window);
      if (newOption) {
        setSelectedOption(newOption);
        onChooseDate(newOption.startDate, newOption.endDate);
      } else {
        console.error('Invalid date_window');
      }
    },
    clearSelectedWindow : () => {
      setSelectedOption(undefined);
    }
  }));

  return (
    <div className="horizontal-date-selector inline-flex w-fit divide-x rounded-3xl bg-silver">
      {/*  <DatePickerOption /> */}
      {options.map((item, index) => {
        return (
          <Option
            key={index}
            option={item}
            selected={selectedOption === item}
            roundLeft={index === 0}
            roundRight={index === options.length - 1}
            onClick={() => (!item.disabled ? handleOptionClick(item) : null)}
          />
        );
      })}
    </div>
  );
});

const Option = ({
  option,
  selected,
  roundLeft,
  roundRight,
  onClick,
}: {
  option: HorizontalDateSelectorOption;
  selected: boolean;
  roundLeft?: boolean;
  roundRight?: boolean;
  onClick?: () => void;
}) => {
  //The roundLeft/right is a trick to round the edges...not sure what the best CSS way to do this is.
  return (
    <button
      className={classNames(
        'inline-flex items-center px-4 py-2 duration-100',
        selected ? 'bg-blueberry text-milk' : 'bg-silver text-blueberry hover:bg-blueberry-lighter hover:text-milk',
        roundLeft && 'rounded-l-3xl',
        roundRight && 'rounded-r-3xl'
      )}
      onClick={onClick}
    >
      <p className="text-sm">{option.name}</p>
    </button>
  );
};

import { ChatBubbleLeftEllipsisIcon } from '@heroicons/react/24/outline';
import { GroupFull } from '../../../../../v2/hooks/GroupHook';
import { ToolbarBaseButton } from './ToolbarBaseButton';
import { useNavigate, useParams } from 'react-router-dom';
import { Action, FilterType, Resource } from '../../../../../generated/graphql';
import { AppRoutes } from '../../../../../Routes';
import { PermissionsContext } from '../../../../../v2/contexts/PermissionsContext';
import { useContext } from 'react';

export function ReplyToUsersButton(props: { group: GroupFull; buttonRounding?: 'left' | 'right' }) {
  const navigate = useNavigate();
  const { groupId: URLGroupId } = useParams();
  const params = new URLSearchParams(window.location.search);
  const navigateToReplyPage = () => {
    const filters = JSON.parse(decodeURIComponent(params.get('group') ?? '{}'));
    filters.groupFilter = [{ filterCondition: FilterType.And, group: [{ id: Number(URLGroupId) }] }];
    const stringifiedFilters = JSON.stringify(filters);
    params.set('group', stringifiedFilters);
    navigate(`${AppRoutes.v3FullPath.replying}?${params.toString()}`, { replace: false });
  };

  const {hasPermission} = useContext(PermissionsContext);
  const canReplyToUsers = hasPermission(Resource.Replies, Action.Exec);
  const disabledTooltip = 'You do not have permission to reply to users. Please contact your administrator and request the Replier role.';
  
  return (
    <ToolbarBaseButton
      id="toolbar-reply-to-users-button"
      icon={<ChatBubbleLeftEllipsisIcon className="h-5 w-5 stroke-2" />}
      label={'Reply to Users'}
      onClick={navigateToReplyPage}
      buttonRounding={props.buttonRounding}
      disabled={!canReplyToUsers}
      disabledTooltip={disabledTooltip}
    />
  );
}

import { AppRoutes } from '../../../Routes';
import {
  BellAlertIcon, ChatBubbleBottomCenterTextIcon,
  GiftIcon,
  HomeIcon,
  MagnifyingGlassIcon,
  NewspaperIcon,
  RectangleStackIcon,
  Square3Stack3DIcon,
  Squares2X2Icon,
  UsersIcon,
  WrenchIcon
} from '@heroicons/react/24/outline';
import {
  BellAlertIcon as BellAlertIconSolid, ChatBubbleBottomCenterTextIcon as ChatBubbleBottomCenterTextIconSolid,
  GiftIcon as GiftIconSolid,
  HomeIcon as HomeIconSolid,
  MagnifyingGlassIcon as MagnifyingGlassIconSolid,
  NewspaperIcon as NewspaperIconSolid,
  Square3Stack3DIcon as Square3Stack3DIconSolid,
  Squares2X2Icon as Squares2X2IconSolid,
  UsersIcon as UsersIconSolid,
  WrenchIcon as WrenchIconSolid,
  RectangleStackIcon as RectangleStackIconSolid
} from '@heroicons/react/24/solid';
import { Action, Resource } from '../../../generated/graphql';

export const navigationItems: INavigation[] = [
  {
    name: 'Home',
    path: AppRoutes.v3FullPath.home,
    href: '#',
    icon: HomeIcon,
    selectedIcon: HomeIconSolid,
    current: true,
    unwrapperRequired: false,
    teamRequired: false,
  },
  {
    name: 'Assistant',
    path: AppRoutes.v3FullPath.assistant,
    href: '#',
    icon: ChatBubbleBottomCenterTextIcon,
    selectedIcon: ChatBubbleBottomCenterTextIconSolid,
    current: false,
    showBetaBadge: true,
    unwrapperRequired: false,
    teamRequired: true,
  },
  {
    name: 'Feedback',
    path: AppRoutes.v3FullPath.feedback,
    href: '#',
    icon: Square3Stack3DIcon,
    selectedIcon: Square3Stack3DIconSolid,
    current: false,
    unwrapperRequired: false,
    teamRequired: true,
    subNavigations: [
      {
        name: 'View Feedback',
        path: AppRoutes.v3FullPath.feedback,
        href: '#',
        icon: NewspaperIcon,
        selectedIcon: NewspaperIconSolid,
        current: false,
        unwrapperRequired: false,
        teamRequired: true,
      },
      {
        name: 'Reply to Feedback',
        path: AppRoutes.v3FullPath.replying,
        href: '#',
        icon: ChatBubbleBottomCenterTextIcon,
        selectedIcon: ChatBubbleBottomCenterTextIconSolid,
        current: false,
        unwrapperRequired: false,
        hasReplyIntegration: true,
        teamRequired: true,
        requiredPermissions: [
          {
            resource: Resource.Replies,
            action: Action.Exec,
          },
        ],
      },
    ],
  },
  {
    name: 'Explore',
    path: AppRoutes.v3FullPath.explore,
    href: '#',
    icon: MagnifyingGlassIcon,
    selectedIcon: MagnifyingGlassIconSolid,
    current: true,
    unwrapperRequired: false,
    teamRequired: true,
  },
  {
    name: 'Boards',
    path: AppRoutes.v3FullPath.boards,
    href: '#',
    icon: RectangleStackIcon,
    selectedIcon: RectangleStackIconSolid,
    current: true,
    unwrapperRequired: false,
    showNewBadge: true,
    teamRequired: true,
  },
 /*  {
    name: 'Charts',
    path: AppRoutes.v3FullPath.charts,
    href: '#',
    icon: ChartBarSquareIcon,
    selectedIcon: ChartBarSquareIconSolid,
    current: true,
    unwrapperRequired: false,
    showNewBadge: false,
    teamRequired: true,
  }, */
  {
    name: 'Alerts',
    path: AppRoutes.v3FullPath.alerts,
    href: '#',
    icon: BellAlertIcon,
    selectedIcon: BellAlertIconSolid,
    current: false,
    unwrapperRequired: false,
    teamRequired: true,
  },
  {
    name: 'Integrations',
    path: AppRoutes.v3FullPath.integrations,
    href: '#',
    icon: Squares2X2Icon,
    selectedIcon: Squares2X2IconSolid,
    current: false,
    unwrapperRequired: false,

    teamRequired: true,
  },
  {
    name: 'Organizations',
    path: AppRoutes.v3FullPath.organizations,
    href: '#',
    icon: UsersIcon,
    selectedIcon: UsersIconSolid,
    current: false,
    unwrapperRequired: false,
    teamRequired: false,
  },
  {
    name: 'Digests',
    path: AppRoutes.v3FullPath.digest,
    href: '#',
    icon: GiftIcon,
    selectedIcon: GiftIconSolid,
    current: false,
    unwrapperRequired: true,
    teamRequired: false,
  },
  {
    name: 'Admin',
    path: AppRoutes.v3FullPath.admin,
    href: '#',
    icon: WrenchIcon,
    selectedIcon: WrenchIconSolid,
    current: false,
    unwrapperRequired: true,
    teamRequired: false,
  },
];

export interface INavigation {
  name: string;
  path: string;
  href: string;
  current: boolean;
  title?: string;
  icon?: any;
  selectedIcon?: any;
  /** Only show nav link if user is an unwrapper */
  unwrapperRequired: boolean;
  showNewBadge?: boolean;
  showBetaBadge?: boolean;
  teamRequired?: boolean;
  subNavigations?: INavigation[];
  hasReplyIntegration?: boolean;
  disabled?: boolean;
  requiredPermissions?: {
    resource: Resource;
    action: Action;
  }[];
}

import Tippy from '@tippyjs/react';
import { classNames } from '../../../v2/util';

export function GroupCount(props: {
  shouldDisplay: boolean,
  filteredGroupCount: number,
  amountOfGroups: number | undefined,
}) {

  if (!props.amountOfGroups) return <></>;
  if (!props.shouldDisplay) return <></>;

  return <Tippy
    content={
      <p>
        This indicates the currently <b>filtered</b> group count compared to the total group
        count.
      </p>
    }
  >
    <div className={classNames('flex w-fit')}>
      <p className="text-xs">
        (Showing {props.filteredGroupCount} of {props.amountOfGroups} total)
      </p>
    </div>
  </Tippy>;
}
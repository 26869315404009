import { useCallback, useEffect, useState } from 'react';
import Form from '../../../baseComponents/Form';
import Button, { ButtonShape, ButtonVariant } from '../../baseComponents/Button';
import DropDown from '../../baseComponents/DropDown';
import Modal from '../../baseComponents/Modal';
import {
  Board_Widget_Type,
  PotentialWidgetFragment,
  useCreateWidgetsMutation,
  useGetTeamPotentialWidgetsLazyQuery,
  WidgetFragment,
} from '../../../generated/graphql';
import { useValidTeamAppContext } from '../../../v2/contexts/AppContext';
import { BoardsListActionTypes } from '../../../reducers/boards/boardsListReducer';
import toast from 'react-hot-toast';
import { useBoardDispatch, useBoardState } from '../../../context/boardContext';
import { classNames } from '../../../v2/util';
import { BoardActionTypes } from '../../../reducers/boards/boardReducer';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../../Routes';
import SearchInput from '../../baseComponents/SearchInput';
import { debounce, set } from 'lodash';

export const AddBoardWidgetModal = ({ modalOpen, setModalOpen }: { modalOpen: boolean; setModalOpen: (open: boolean) => void }) => {
  const { curTeamId: teamId } = useValidTeamAppContext();
  const state = useBoardState();
  const dispatch = useBoardDispatch();
  const navigate = useNavigate();
  const [isSearching, setIsSearching] = useState(false);

  const [selectedPotentialWidgets, setSelectedPotentialWidgets] = useState<PotentialWidgetFragment[]>([]);

  const [getPotentialWidgets, { loading: loadingWidgets, data: widgetsRes }] = useGetTeamPotentialWidgetsLazyQuery({
    variables: {
      teamId,
      //boardIdToExclude: state.board.id,
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      setIsSearching(false);
    },
    onError(error) {
      toast.error('Failed to fetch widgets');
      setIsSearching(false);
    },
  });

  useEffect(() => {
    getPotentialWidgets();
  }, []);

  useEffect(() => {
    if (!modalOpen) setSelectedPotentialWidgets([]);
  }, [modalOpen]);

  const [createWidgets, createWidgetsMutationRes] = useCreateWidgetsMutation();
  async function handleAddWidgets() {
    await createWidgets({
      variables: {
        boardId: state.board.id,
        inputWidgets: selectedPotentialWidgets.map((potWidget) => ({
          widgetType: potWidget.widgetType,
          widgetTypeId: potWidget.widgetTypeId,
        })),
        teamId,
      },
      refetchQueries: ['GetTeamWidgets'],
      onCompleted: (data) => {
        dispatch({ type: BoardActionTypes.AddWidgets, payload: { widgets: data.createWidgets } });
        toast.success('Widgets added successfully');
        setModalOpen(false);
      },
      onError: (error) => {
        toast.error('Failed to add selected widgets');
      },
    });
  }

  const getFormButtons = () => {
    const getAddWidgetButtonText = () => {
      const count = selectedPotentialWidgets.length;
      if (count === 0) return 'Add widget';
      if (count === 1) return 'Add widget (1)';
      return `Add widgets (${count})`;
    };

    return (
      <div className="mt-8 grid grid-cols-3 justify-end gap-x-4 text-center">
        <div className="col-span-1">
          <Button variant={ButtonVariant.Tertiary} shape={ButtonShape.Pill} onClick={() => setModalOpen(false)} text="Cancel" expandWidth></Button>
        </div>
        <div className="col-span-2">
          <Button
            submit
            variant={ButtonVariant.Primary}
            shape={ButtonShape.Pill}
            disabled={selectedPotentialWidgets.length === 0}
            text={getAddWidgetButtonText()}
            onClick={() => {
              handleAddWidgets();
            }}
            loadingConfirm={createWidgetsMutationRes.loading}
            expandWidth
          ></Button>
        </div>
      </div>
    );
  };

  const debouncedOnChange = useCallback(
    debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      getPotentialWidgets({
        variables: {
          teamId,
          titleSubstring: value,
        },
      });
    }, 300),
    []
  );

  return (
    <Modal open={modalOpen} setOpen={setModalOpen} unmountOnClose>
      <div className="h-[37rem]" data-testid="filter-modal">
        <Form bottomRow={getFormButtons()}>
          <div className="flex flex-col gap-y-4  text-blueberry">
            <div className="flex items-center justify-between mx-3">
              <h1 className="font-semibold text-2xl ">Add widget</h1>
              <Button
                variant={ButtonVariant.Bordered}
                shape={ButtonShape.Pill}
                text="Create widget +"
                onClick={() => navigate(AppRoutes.v3FullPath.charts + '/creator' + '?' + 'boardId=' + state.board.id)}
              ></Button>
            </div>
            <SearchInput
              noPadding
              onSearch={() => {}}
              onChange={(e) => {
                setIsSearching(true);
                debouncedOnChange(e);
              }}
              onClear={() => {
                setIsSearching(true);
                getPotentialWidgets({
                  variables: {
                    teamId,
                  },
                });
              }}
            />

            <div className="flex flex-col gap-y-1 h-full max-h-96 overflow-y-auto">
              {loadingWidgets || isSearching ? (
                <>
                  {Array.from({ length: 5 }).map((_, index) => (
                    <WidgetOptionRowSkeleton key={index} />
                  ))}
                </>
              ) : (widgetsRes?.getTeamPotentialWidgets.length ?? 0) > 0 ? (
                widgetsRes?.getTeamPotentialWidgets.map((widget) => (
                  <div
                    key={widget.widgetTypeId}
                    className={classNames(
                      'flex flex-row py-2 px-3 justify-between items-center rounded-md',
                      selectedPotentialWidgets.some((w) => w.widgetTypeId === widget.widgetTypeId) ? 'bg-[#F2F3F6]' : 'hover:bg-silver-lighter'
                    )}
                    onClick={() => {
                      if (selectedPotentialWidgets.some((w) => w.widgetTypeId === widget.widgetTypeId)) {
                        setSelectedPotentialWidgets((prev) => prev.filter((w) => w.widgetTypeId !== widget.widgetTypeId));
                      } else {
                        setSelectedPotentialWidgets((prev) => [...prev, widget]);
                      }
                    }}
                  >
                    <div className="flex flex-row gap-x-4 items-center">
                      {/*TODO: Replace this with HeadlessUI Checkbox (requires package upgrade)*/}
                      <input
                        name="widget"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 focus:ring-0 ring-0"
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) => {
                          e.stopPropagation();
                          
                          if (e.target.checked) setSelectedPotentialWidgets((prev) => [...prev, widget]);
                          else setSelectedPotentialWidgets((prev) => prev.filter((w) => w.widgetTypeId !== widget.widgetTypeId));
                        }}
                        checked={selectedPotentialWidgets.some((w) => w.widgetTypeId === widget.widgetTypeId)}
                      />
                      <div className="flex flex-col">
                        <p className="font-semibold text-lg text-blueberry">{widget.title}</p>
                        <p className="font-light text-xs text-gray-400 italic">{WidgetTypeNameMap[widget.widgetType]}</p>
                      </div>
                    </div>
                    {/* <p className="text-gray-500 italic text-sm">John Doe</p> */}
                  </div>
                ))
              ) : (
                <div className="text-center text-gray-500">No widgets found</div>
              )}
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

const WidgetTypeNameMap: Record<Board_Widget_Type, string> = {
  [Board_Widget_Type.ChartWidget]: 'Chart',
  // [Board_Widget_Type.TableWidget]: 'Table',
};

const WidgetOptionRowSkeleton = () => {
  return (
    <div className="flex flex-row py-2 px-3 justify-between items-center rounded-md">
      <div
        className="space-y-5 rounded-lg bg-gray-100 relative 
        before:absolute before:inset-0
        before:-translate-x-full
        before:animate-[shimmer_2s_infinite]
        before:bg-gradient-to-r before:from-transparent before:via-blueberry  before:opacity-[0.2]
        isolate
        overflow-hidden
        before:border-t before:border-gray-100 opacity-70 h-7 w-full"
      ></div>
    </div>
  );
};

import { Route, Routes } from 'react-router-dom';
import { BoardsPage } from './BoardsPage';
import { BoardPage } from './BoardPage';
import { BoardsListProvider } from '../../../context/boardsListContext';

export const BoardsRouterPage = () => {
  return (
    <Routes>
      <Route path={`/:boardId`} element={<BoardPage />} />
      <Route
        path={`/`}
        element={
          <BoardsListProvider>
            <BoardsPage />
          </BoardsListProvider>
        }
      />
    </Routes>
  );
};

import { Dispatch, SetStateAction, useContext, useEffect, useReducer } from 'react';
import { Dialog } from '@headlessui/react';
import AppContext from '../../../v2/contexts/AppContext';
import { IDropDownItem } from '../../baseComponents/DropDown';
import Button, { ButtonVariant } from '../../baseComponents/Button';
import Badge from '../Badge';
import Modal from '../../baseComponents/Modal';
import { VirtualizedComboBox } from '../VirtualizedComboBox';
import Form from '../../../baseComponents/Form';
import {
  CreateDigestSeriesActions,
  CreateDigestSeriesReducer,
  CreateDigestSeriesState,
  canSaveDigestSeries,
  getInitialState,
} from '../../../reducers/digests/createDigestSeriesReducer';
import { DigestSeriesType, GetDigestSeriesDocument, useCreateDigestSeriesMutation } from '../../../generated/graphql';
import { EMPTY_DROPDOWN_ITEM } from '../../../reducers/utilities/consts';
import { getDropDownSelectionsFromEnum, getEnumValue } from '../../../reducers/utilities/enumHandling';
import { DigestDataDispatchContext } from '../../../context/digestDataContext';

export interface CreateDigestProps {
  modalOpen: boolean;
  setFalseToCloseModal: Dispatch<SetStateAction<boolean>>;
}

/**
 * This creates or edits a given digest series
 * @param param0
 * @returns
 */
const CreateDigestSeries = ({ modalOpen, setFalseToCloseModal }: CreateDigestProps) => {
  const { organizations } = useContext(AppContext);
  const [cdsState, dispatch] = useReducer(CreateDigestSeriesReducer, organizations, getInitialState);

  /**
   * This block of code below FEELS wrong. Why can't I take my useCreateDigestSeriesMutation out, put it in reducer and move the next
   * functions below out of my view codes...
   *
   * I've tried putting in a reducer dispatcher... but I'm a little
   */
  const [createDigestSeriesMutation, _] = useCreateDigestSeriesMutation();
  const saveDigestSeries = async (state: CreateDigestSeriesState) => {
    if (canSaveDigestSeries(state)) {
      createDigestSeriesMutation({
        variables: {
          orgId: state.selectedOrganization.id,
          teamIds: state.selectedTeams.map((team) => team.id),
          title: state.title,
          digestSeriesType: getEnumValue(DigestSeriesType, state.digestSeriesType),
        },
        onCompleted: () => {
          setFalseToCloseModal(false);
          dispatch({ type: CreateDigestSeriesActions.ClearState, payload: {} });
        },
        refetchQueries: [{ query: GetDigestSeriesDocument, variables: {} }],
        awaitRefetchQueries: true,
      });
    }
  };

  const getFormButtons = () => {
    return (
      <div className="mt-4 grid grid-cols-3 justify-end gap-x-4 text-center">
        <div className="col-span-1">
          <Button expandWidth={true} variant={ButtonVariant.Cancel} onClick={() => setFalseToCloseModal(false)} text="Cancel"></Button>
        </div>
        <div className="col-span-2">
          <Button
            variant={ButtonVariant.Primary}
            text="Save"
            expandWidth={true}
            disabled={cdsState.loading || !canSaveDigestSeries(cdsState)}
            onClick={() => {
              saveDigestSeries(cdsState);
            }}
            loadingConfirm={cdsState.loading}
          ></Button>
        </div>
      </div>
    );
  };

  return (
    <Modal open={modalOpen} setOpen={setFalseToCloseModal}>
      <div className="mt-3 text-center sm:mt-5 text-blueberry">
        {/* Title bar */}
        <Dialog.Title as="h1" className="text-3xl font-medium ">
          Create New Digest Series
        </Dialog.Title>
        <div className="grid grid-cols-3 gap-x-4 mt-8 gap-y-8">
          {/* Title Input */}
          <div className="col-span-1 flex items-center justify-end">
            <h1 className=" text-lg font-semibold">Title</h1>
          </div>
          <div className="col-span-2 pr-6">
            <input
              className="w-full px-3 py-2 appearance-none rounded-md border border-gray-300 placeholder-gray-400 focus:border-blueberry focus:outline-none focus:ring-blueberry sm:text-sm"
              value={cdsState.title}
              onChange={(e) => dispatch({ type: CreateDigestSeriesActions.SetTitle, payload: { title: e.target.value } })}
            />
          </div>
          {/* Digest Series Type */}
          <div className="col-span-1 flex items-center justify-end">
            <h1 className=" text-lg font-semibold">Digest Type</h1>
          </div>
          <div className="col-span-2 pr-6">
            <VirtualizedComboBox
              placeholder="Choose Digest Series Type..."
              comboBoxData={getDropDownSelectionsFromEnum(DigestSeriesType)}
              setSelectedItem={(item) => {
                dispatch({ type: CreateDigestSeriesActions.SetDigestSeriesType, payload: { digestSeriesType: item ?? EMPTY_DROPDOWN_ITEM } });
              }}
              selectedItem={cdsState.digestSeriesType}
            />
          </div>
          {/* Organization combo box */}
          <div className="col-span-1 flex items-center justify-end">
            <h1 className=" text-lg font-semibold">Organization</h1>
          </div>
          <div className="col-span-2 pr-6">
            <VirtualizedComboBox
              placeholder="Choose an Organization..."
              comboBoxData={cdsState.organizations.map((org): IDropDownItem => {
                return {
                  name: org.name,
                  id: org.id,
                };
              })}
              setSelectedItem={(item) => {
                dispatch({ type: CreateDigestSeriesActions.SelectOrganization, payload: { selectedOrganization: item } });
              }}
              selectedItem={cdsState.selectedOrganization}
            />
          </div>
          {/* Teams ComboBox */}
          <div className="col-span-1 flex items-center justify-end">
            <h1 className=" text-lg font-semibold">Teams</h1>
          </div>
          <div className="col-span-2 pr-6">
            <VirtualizedComboBox
              placeholder="Choose Teams..."
              comboBoxData={cdsState.teamsOptions}
              setSelectedItem={(item) => {
                if (item) {
                  dispatch({ type: CreateDigestSeriesActions.AddTeam, payload: { team: item } });
                }
              }}
              selectedItem={cdsState.selectedTeam}
            />
          </div>
          <div className="col-span-3 flex justify-start items-top px-6 gap-x-2">
            {cdsState.selectedTeams.map((team) => {
              return (
                <Badge
                  badge={{ text: team.name, id: team.id.toString() }}
                  key={team.id}
                  onRemove={(item) => {
                    dispatch({ type: CreateDigestSeriesActions.RemoveTeam, payload: { team: { name: item.text, id: Number(item.id) } } });
                  }}
                  smaller={true}
                />
              );
            })}
          </div>
        </div>
        <Form
          bottomRow={getFormButtons()}
          onSubmit={(e) => {
            e.preventDefault();
          }}
        ></Form>
      </div>
    </Modal>
  );
};

export default CreateDigestSeries;

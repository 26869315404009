import { ExportToCsv } from "export-to-csv";
import { DataExporter } from "./DataExporter";
import { DataFormat } from "./DataFormat";

/**
 * Handles exporting data to a FileStore
 * In this case we use ExportToCsv, which uses the native browser API for downloading a file onto the user's filesystem.
 */
export class FileExporter implements DataExporter {
    
    /**
     * 
     * @param data - data to export
     * @param format - data format (csv, json, etc.)
     * @param fileName - defaults to Unwrap Group Export for now.
     * @returns 
     */
    export(fileName: string, format: DataFormat, data: any) {
        switch(format) {
            case(DataFormat.CSV): {
                const exporter = new ExportToCsv({
                    fieldSeparator: ',',
                    quoteStrings: '"',
                    decimalSeparator: '.',
                    showLabels: true,
                    showTitle: false,
                    filename: fileName,
                    useTextFile: false,
                    useBom: true,
                    useKeysAsHeaders: true,
                  });

                  return exporter.generateCsv(data);
            }
            default: throw new Error(`Unrecognized data format: ${format}`);
        }
    }
    
}
import { ArrowTopRightOnSquareIcon, CalendarDaysIcon, NewspaperIcon, StarIcon as StarIconOutline, UserCircleIcon } from '@heroicons/react/24/outline';
import { ChevronDownIcon, ChevronUpIcon, LinkIcon, StarIcon } from '@heroicons/react/24/solid';
import Tippy from '@tippyjs/react';
import he from 'he';
import { capitalize } from 'lodash';
import { useContext, useRef, useState } from 'react';
import 'tippy.js/themes/light.css';
import { Action, Conversation_Part, EntryFragment, Filter_Condition, FilterInput, FilterType, Resource } from '../../generated/graphql';
import { getSentimentString, useClickOutside } from '../../utilities';
import { useValidTeamAppContext } from '../../v2/contexts/AppContext';
import sourcesMap from '../../v2/other/sourcesMap';
import { alphabeticalSort, classNames, getFiltersFromUrl, localDateString, truncateAndEllipsis } from '../../v2/util';
import Button, { ButtonVariant } from '../baseComponents/Button';
import SettingsMenu from '../baseComponents/SettingsMenu';
import { useEditSentimentHook } from '../hooks/EditSentimentHook';
import { isReplyPart, useReplyHook } from '../hooks/ReplyHook';
import { EntryAddIcon } from './entries/EntryAddIcon';
import { EntryDeleteTrashCan } from './entries/EntryDeleteTrashCan';
import { FullConversation } from './FullConversation';
import { GroupMembershipSection } from './GroupMembershipSection';
import { RepliableSection } from './replying/RepliableSection';
import { SegmentLabel } from './SegmentLabel';
import { PermissionsContext } from '../../v2/contexts/PermissionsContext';
import { AppRoutes } from '../../Routes';
import toast from 'react-hot-toast';

interface FeedbackEntryCardProps {
  entry: EntryFragment;
  allowReplyExpansion?: boolean;

  /**
   * The entry can appear in many places on a platform.
   *
   * In the GroupPage Context (ParentComponentContext) we allow a user to delete the entry from the Group
   *
   * if this function is defined (I'm sorry) the FeedbackEntryCard will show the delete icon and call this function
   * if / when that icon is clicked on
   * @param entryId
   * @returns
   */
  deleteEntryFromParentComponentContext?: (entryId: string) => Promise<void>;
  /**
   * The entry can appear in many places on a platform.
   *
   * In the GroupPage Context (ParentComponentContext) we allow a user to add an Entry to a Group
   *
   * if this function is defined (I'm sorry) the FeedbackEntryCard will show the Add Entry icon and call this function
   * if / when that icon is clicked on
   * @param entryId
   * @returns
   */
  addEntryToParentComponentContext?: (entryId: string) => Promise<void>;

  compact?: boolean;
  compactAvailable?: boolean;
  showConversation?: boolean;
}
export const FeedbackEntryCard = ({
  entry,
  compact,
  allowReplyExpansion,
  deleteEntryFromParentComponentContext,
  addEntryToParentComponentContext,
}: FeedbackEntryCardProps) => {
  const replyHook = useReplyHook({ initialReply: entry });
  const [showReplyBox, setShowReplyBox] = useState(false);
  return (
    <div className={`text-blueberry relative group min-w-0 break-words `}>
      <EntryDeleteTrashCan deleteEntryFromParentComponentContext={deleteEntryFromParentComponentContext} entryId={entry.id} />
      {<RenderFeedbackEntry entry={entry} compact={compact} />}

      <EntryAddIcon addEntryToParentComponentContext={addEntryToParentComponentContext} entryId={entry.id} />

      {/* todo, move to its own component */}
      {allowReplyExpansion ? (
        <div className="relative">
          <div className="absolute inset-0 flex items-center" aria-hidden="true">
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center cursor-pointer" onClick={() => allowReplyExpansion && setShowReplyBox((prev) => !prev)}>
            <span className="flex flex-row gap-x-2 bg-silver shadow items-center px-2 text-sm text-gray-500">
              <h1>{!showReplyBox ? 'Reply' : 'Hide Replies'}</h1>
              {!showReplyBox ? <ChevronDownIcon className="w-4 h-4" /> : <ChevronUpIcon className="w-4 h-4" />}
            </span>
          </div>
        </div>
      ) : null}
      {showReplyBox && (
        <RepliableSection
          entry={entry}
          replyHook={replyHook}
          existingReplyData={replyHook.existingReplies[entry.id]}
          currentReplyTextToSend={replyHook.replyTextsToSend[entry.id]}
        />
      )}
    </div>
  );
};

interface RenderFeedbackEntryProps {
  entry: EntryFragment;
  compact?: boolean;
  updateSentiment?: (sentiment: number) => void;
}

//how the hell do i name this
const isConversationOnlyAReplierReply = (convParts: Conversation_Part[]) => {
  return convParts.length === 1 && isReplyPart(convParts[0].providerUniqueId);
};

export const RenderFeedbackEntry = ({ entry, compact }: RenderFeedbackEntryProps) => {
  const [showFullCard, setShowFullCard] = useState<boolean>(false);

  const isConvoReplierOnly = isConversationOnlyAReplierReply(entry.feedbackEntryText.conversationParts);

  const showDistillatebyDefault = entry.distillateText && (entry.defaultDistillateDisplay || entry.hasConversation);

  /**
   * This is true if the entry is in a toggleable state. I.e. toggle from compact distillate mode to full card.
   */
  const canToggleEntryCard = (entry.hasConversation && !isConvoReplierOnly) || showDistillatebyDefault;

  const handleClick = () => {
    const selection = window.getSelection()?.toString();
    // Don't toggle if text is selected
    if (selection && selection.length > 0) return;
    setShowFullCard((prev) => !prev);
  };
  const handleConditionalClick = () => {
    if (canToggleEntryCard) handleClick();
  };

  /**
   * This choses how to display the card. I moved it here instead of ternary hell, mildly better, I had to use console logs to figure out what
   * was actually getting hit.
   * @returns
   */
  const getCardPresentation = () => {
    if (entry.hasConversation) {
      if (showFullCard) {
        return <FullConversation entry={entry} height="h-[22rem]" />;
      } else {
        return <ConversationPartsConcat entry={entry} />;
      }
    } else {
      // you want to show concatenated or not right?
      return (
        <p className="entry-text text-base" data-testid="no-conversation-feedback-entry">
          {he.decode(entry.text ?? '')}
        </p>
      );
    }
  };

  return (
    <>
      {!showFullCard && showDistillatebyDefault ? ( // on group page before opening (summary)
        <div
          onClick={() => {
            handleClick();
          }}
          className={`feedback-entry-card flex flex-col gap-y-3 w-full rounded-3xl bg-silver ${'hover:shadow-lg transition cursor-pointer'}  ${
            !showFullCard ? ' hover:bg-silver-darker' : ''
          } ${showFullCard ? 'px-0' : 'px-6'} py-5`}
        >
          <CompactFeedbackEntry entry={entry} />
        </div>
      ) : (
        <div
          onClick={handleConditionalClick}
          className={`feedback-entry-card flex flex-col  gap-y-3 w-full rounded-3xl bg-silver hover:shadow-lg transition ${
            canToggleEntryCard ? 'cursor-pointer' : 'cursor-default'
          }
           ${showFullCard && entry.hasConversation && !isConvoReplierOnly ? 'px-0' : 'px-6'} py-5`}
        >
          <FeedbackEntryHeader entry={entry} showFullCard={showFullCard} />

          {/* This handles chosing how we show the card */}
          <div>{getCardPresentation()}</div>
          <FeedbackEntryFooter entry={entry} showFullCard={showFullCard} />
        </div>
      )}
    </>
  );
};

const ConversationPartsConcat = ({ entry }: { entry: EntryFragment }) => {
  return (
    <div data-testid="conversation-parts-concat">
      <p>{he.decode(`${capitalize(entry.submitterType ?? '')} - ${entry.text} `)}</p>
      {entry.feedbackEntryText.conversationParts
        .slice(0, 3)
        .map((conversation) => `${he.decode(`${capitalize(conversation.submitterType ?? '')} - ${conversation.fullText} `)}`)
        .map((part, i) => {
          return (
            <p>
              {part}
              {i === 2 && entry.feedbackEntryText.conversationParts.length > 3 ? '...' : ''}
            </p>
          );
        })}
    </div>
  );
};
interface SentimentLabelProps {
  sentiment: number;
  onClick: (sentiment: number) => void;
}

const SentimentLabel = ({ sentiment, onClick }: SentimentLabelProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const { hasPermission } = useContext(PermissionsContext);
  useClickOutside([ref], () => menuOpen && setMenuOpen(false));
  const sentimentMenu = [
    { id: 0, group: 'sentiment', onClick: () => onClick(1), name: 'Positive' },
    { id: 1, group: 'sentiment', onClick: () => onClick(0.5), name: 'Neutral' },
    { id: 2, group: 'sentiment', onClick: () => onClick(-1), name: 'Negative' },
  ];
  if (hasPermission(Resource.Entries, Action.Update)) {
    return (
      <SettingsMenu settings={sentimentMenu}>
        <div
          ref={ref}
          onClick={() => {
            setMenuOpen(true);
          }}
          className={classNames('items-scenter group relative flex cursor-pointer flex-row items-baseline gap-x-1 duration-300')}
        >
          <span className={classNames('block h-2.5 w-2.5 rounded-full bg-failure p-1 opacity-70', getSentimentBgColor(sentiment ?? 0))} />
          <h1 className="text-xs">{getSentimentString(sentiment ?? 0)}</h1>
          <ChevronDownIcon className={classNames('w-.2.5 absolute -right-3 mt-1 h-2.5', menuOpen ? 'block' : 'hidden group-hover:block')} />
        </div>
      </SettingsMenu>
    );
  } else {
    return (
      <div className="flex flex-row items-center gap-x-1 relative items-baseline">
        <span className={classNames('block h-2.5 w-2.5 rounded-full bg-failure p-1 opacity-70', getSentimentBgColor(sentiment ?? 0))} />
        <h1 className="text-xs">{getSentimentString(sentiment ?? 0)}</h1>
      </div>
    );
  }
};

const getSentimentBgColor = (sentimentScore: number) => (sentimentScore >= 0.9 ? 'bg-success' : sentimentScore <= -0.9 ? 'bg-failure' : 'bg-gray-400');

export default FeedbackEntryCard;

const FeedbackEntryFooter = ({ entry, showFullCard }: { entry: EntryFragment; showFullCard: boolean }) => {
  const isConvoReplierOnly = isConversationOnlyAReplierReply(entry.feedbackEntryText.conversationParts);
  const isConvoExpanded = showFullCard && entry.hasConversation && !isConvoReplierOnly;
  return (
    <div className={`flex flex-col items-start gap-y-2 ${isConvoExpanded ? 'px-6 pt-2' : 'px-0'}`}>
      <div className={`items-center w-full flex flex-row gap-x-8 justify-between text-blueberry`}>
        <GroupMembershipSection entry={entry} />
        <SegmentFooter entry={entry} />
      </div>
    </div>
  );
};

const UserDateAndSentiment = ({ entry }: { entry: EntryFragment }) => {
  const { handleEditSentiment } = useEditSentimentHook();
  const { curTeamId: teamId, curOrgId: orgId } = useValidTeamAppContext();

  const updateEntrySentiment = (teamId: number, entryId: string, sentiment: number) => {
    handleEditSentiment(teamId, entryId, sentiment);
  };
  return (
    <div className="flex flex-row gap-x-5 gap-y-2 justify-start flex-wrap">
      <div className="flex flex-row items-center gap-x-1 whitespace-nowrap" onClick={(e) => e.stopPropagation()}>
        {entry.sentiment || entry.sentiment === 0 ? (
          <SentimentLabel
            sentiment={entry.sentiment}
            onClick={(sentiment) => {
              updateEntrySentiment(teamId, entry.id, sentiment);
            }}
          />
        ) : null}
      </div>
      <div className="flex flex-row items-center gap-x-1 whitespace-nowrap">
        <CalendarDaysIcon className="h-5 w-5" />
        <h1 className="text-xs">{entry.date ? localDateString(new Date(entry.date)) : ''}</h1>
      </div>
      <div className="flex flex-row items-center gap-x-1 whitespace-nowrap">
        <UserCircleIcon className="h-5 w-5" />
        <h1 className="text-xs">{entry.submitter ?? 'Unknown'}</h1>
      </div>
      <div
        className="flex flex-row items-center gap-x-1 whitespace-nowrap cursor-pointer hover:bg-gray-200 duration-100 rounded-full p-0.5"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          const link = generateLinkToEntry(entry, teamId, orgId);
          navigator.clipboard.writeText(link);
          toast.success('Link to feedback entry copied to clipboard');
        }}
      >
        <LinkIcon className="h-4 w-4" />
      </div>
    </div>
  );
};

const generateLinkToEntry = (entry: EntryFragment, teamId: number, orgId: number) => {
  const curFilter = getFiltersFromUrl('group', teamId);
  const startDate = curFilter?.startDate;
  const endDate = curFilter?.endDate;

  const filter: FilterInput = {
    ...(startDate ? { startDate: startDate } : {}),
    ...(endDate ? { endDate: endDate } : {}),
    entryFilter: [
      {
        filterCondition: FilterType.And,
        entry: [{ id: entry.id }],
      },
    ],
  };
  const urlParams = new URLSearchParams();
  urlParams.set('teamId', teamId.toString());
  urlParams.set('orgId', orgId.toString());
  urlParams.set('group', encodeURIComponent(JSON.stringify(filter)));

  const newUrl = window.location.protocol + '//' + window.location.host + AppRoutes.v3FullPath.feedback + '?' + urlParams.toString();
  return newUrl;
};

/** We want to show a consolidated feedbackEntry here. */
const CompactFeedbackEntry = ({ entry }: { entry: EntryFragment }) => {
  const displayDistillate = entry.distillateText && (entry.hasConversation || entry.defaultDistillateDisplay);

  return (
    <div className="flex flex-col gap-y-3" data-testid="compact-entry-card">
      <div className="flex flex-row justify-between">
        <div className="flex flex-row items-center gap-x-2">
          {entry.source ? <SourceImage entrySource={entry.source} /> : null}
          {entry.stars ? <StarsDisplay starCount={entry.stars} /> : null}
          {entry.source_permalink ? <PermalinkDisplay permalink={entry.source_permalink} /> : null}
          {displayDistillate ? (
            <Tippy content="This is a summarized version of the text. You can click to expand the full entry" theme="dark">
              <div className="bg-slate-200 opacity-80 flex flex-row items-center gap-x-1 rounded-md px-2 py-1">
                <NewspaperIcon className="h-3 w-3 stroke-1" />
                <p className="text-xxs">Summarized</p>
              </div>
            </Tippy>
          ) : null}
        </div>
        <UserDateAndSentiment entry={entry} />
      </div>
      <div className="flex flex-row justify-between gap-x-2 items-start">
        {displayDistillate ? <p>{entry.distillateText}</p> : <p>{truncateAndEllipsis(entry.text, 80)}</p>} <SegmentFooter entry={entry} compact={true} />
      </div>
      <GroupMembershipSection entry={entry} />
    </div>
  );
};

const SegmentFooter = ({ entry, compact }: { entry: EntryFragment; compact?: boolean }) => {
  const sliceAmount = compact ? 0 : 2;
  const sortedSegments = [...entry.segments].sort((a, b) => alphabeticalSort(a.groupName, b.groupName));
  return (
    <div className="flex flex-row gap-x-3  gap-y-2 justify-end flex-wrap">
      {sortedSegments && sortedSegments.length !== 0
        ? sortedSegments.map((segment, index) => {
            if (index >= sliceAmount) return null;
            return <SegmentLabel key={index} segment={segment} />;
          })
        : null}
      <div>{sortedSegments && sortedSegments.length > sliceAmount ? <SegmentLabel remainingSegments={sortedSegments.slice(sliceAmount)} /> : null}</div>
    </div>
  );
};

const FeedbackEntryHeader = ({ entry, showFullCard }: { entry: EntryFragment; showFullCard: boolean }) => {
  const isConvoReplierOnly = isConversationOnlyAReplierReply(entry.feedbackEntryText.conversationParts);
  const isConvoExpanded = showFullCard && entry.hasConversation && !isConvoReplierOnly;
  return (
    <div className={`flex flex-row justify-between flex-wrap ${isConvoExpanded ? 'px-6 ' : 'px-0'}`}>
      <div className={`flex select-none flex-row items-center gap-x-2`}>
        {entry.source ? <SourceImage entrySource={entry.source} /> : null}
        <EntryTitleDisplay entry={entry} />
        {entry.stars ? <StarsDisplay starCount={entry.stars} /> : null}
        {entry.source_permalink ? <PermalinkDisplay permalink={entry.source_permalink} /> : null}
        {entry.distillateText ? <SummaryDisplay distillateText={entry.distillateText} /> : null}
      </div>
      <UserDateAndSentiment entry={entry} />
    </div>
  );
};

const SourceImage = ({ entrySource }: { entrySource: string }) => {
  const source = sourcesMap[entrySource.toLowerCase()];
  if (!source) return null;
  return <img className="h-4 w-4" src={sourcesMap[entrySource.toLowerCase()]?.logo} alt={sourcesMap[entrySource]?.name} />;
};

const StarsDisplay = ({ starCount }: { starCount: number }) => {
  return (
    <div className="flex flex-row gap-x-1">
      {[1, 2, 3, 4, 5].map((el) => {
        return (
          <div key={el}>
            {el <= starCount ? <StarIcon key={el} className="h-4 w-4 text-yellow-500" /> : <StarIconOutline key={el} className="h-4 w-4 text-yellow-500" />}
          </div>
        );
      })}
    </div>
  );
};

const DateDisplay = ({ timestamp }: { timestamp: number }) => {
  return (
    <div className="flex flex-row items-center gap-x-0.5 whitespace-nowrap">
      <CalendarDaysIcon className="h-4 w-4" />
      <h1 className="text-xs">{timestamp ? localDateString(new Date(timestamp)) : ''}</h1>
    </div>
  );
};
const PermalinkDisplay = ({ permalink }: { permalink: string }) => {
  return (
    <div>
      <a
        onMouseUp={(e) => e.stopPropagation()}
        href={permalink ?? 'https://google.com'}
        id="entry-permalink-clicked"
        target="_blank"
        rel="noreferrer"
        className="p-0.5 rounded-full hover:bg-gray-300 duration-100 block"
      >
        <Button variant={ButtonVariant.IconRaw} icon={<ArrowTopRightOnSquareIcon className="h-3.5 w-3.5 cursor-pointer duration-100 hover:stroke-[2]" />} />
      </a>
    </div>
  );
};

const SummaryDisplay = ({ distillateText }: { distillateText: string }) => {
  return (
    <Tippy
      theme="dark"
      content={
        <div className="p-1">
          <p className="text-lg mb-2 font-semibold">Summary:</p>
          <p>{distillateText}</p>
        </div>
      }
    >
      <div className="justify-center items-center flex flex-row">
        <NewspaperIcon className="h-4 w-4 stroke-1" />
      </div>
    </Tippy>
  );
};

const EntryTitleDisplay = ({ entry }: { entry: EntryFragment }) => {
  return entry.title && entry.title !== '' ? (
    <p className="entry-title">"{entry.title}"</p>
  ) : (
    <p>
      {entry.source
        ? (sourcesMap[entry.source]?.name || entry.source.charAt(0).toUpperCase() + entry.source.slice(1)) +
          ' ' +
          (sourcesMap[entry.source]?.itemName || 'Entry')
        : null}
    </p>
  );
};

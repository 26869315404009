import { Dispatch, Fragment, SetStateAction, useContext, useEffect } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { ArrowsRightLeftIcon } from '@heroicons/react/24/outline';
import { WhiteSpinner } from '../../baseComponents/WhiteSpinner';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../../Routes';
import UserContext from '../../../v2/contexts/UserContext';
import { classNames, getTeamChangeRedirectURL, ifUnwrapperShowId } from '../../../v2/util';
import AppContext, { OrgTeamWithInfo } from '../../../v2/contexts/AppContext';
import { useGetOrganizationUserTeamsLazyQuery } from '../../../generated/graphql';
import toast from 'react-hot-toast';

interface ViewDropdownProps {
  setNewOrgModalOpen: Dispatch<SetStateAction<boolean>>;
  setNewTeamModalOpen: Dispatch<SetStateAction<boolean>>;
  setSwitchOrgModalOpen: Dispatch<SetStateAction<boolean>>;
  queryString: string;
  setQueryString: Dispatch<SetStateAction<string>>;
}

const menuOpenerClassName =
  'hover:bg-raspberry hover:bg-opacity-10 ring-0 focus:ring-0 border-l-4 border-transparent hover:border-raspberry duration-150 rounded-md flex flex-row justify-between items-center px-2 h-full gap-x-2';

export function ViewDropdownRevamp({ setNewOrgModalOpen, setNewTeamModalOpen, setSwitchOrgModalOpen, queryString, setQueryString }: ViewDropdownProps) {
  const { user } = useContext(UserContext);
  const { currentTeam, currentOrg, setCurTeamId, orgsHaveLoaded, organizations } = useContext(AppContext);
  const location = useLocation();
  let navigate = useNavigate();
  const [getOrgUserTeams, { data }] = useGetOrganizationUserTeamsLazyQuery();

  useEffect(() => {
    if (currentOrg && user) {
      getOrgUserTeams({
        variables: {
          orgId: currentOrg.id,
        },
      });
    }
  }, [currentOrg, user]);

  return (
    <Popover className="relative h-full w-full font-sofiapro">
      {!orgsHaveLoaded ? (
        <div className={menuOpenerClassName}>
          <div className="mx-3 flex flex-row items-baseline justify-center gap-x-2 text-left text-milk">
            <WhiteSpinner />
            <h1 className="min-h-5 select-none text-sm font-semibold line-clamp-2">Loading views...</h1>
          </div>
        </div>
      ) : organizations.length > 0 ? (
        <Popover.Button data-testid="view-dropdown" className={'h-full w-full outline-none'}>
          <div className={menuOpenerClassName}>
            {/* {selectedTeam?.logoUrl && <img className="w-5 h-5" src={selectedTeam?.logoUrl} />} */}
            <div className="flex flex-col items-start justify-center px-3 text-left">
              <h1 className="min-h-5 text-sm font-semibold text-milk line-clamp-2">{currentTeam?.name}</h1>
              <h1 className="min-h-5 text-xs font-normal text-silver line-clamp-1">{currentOrg?.name}</h1>
            </div>
            <div>
              <ChevronUpDownIcon className="h-5 w-5 text-milk" aria-hidden="true" />
            </div>
          </div>
        </Popover.Button>
      ) : (
        <NavLink to={AppRoutes.v3FullPath.organizations} className={menuOpenerClassName}>
          <div className="mx-3 flex flex-row items-baseline justify-center gap-x-2 text-left text-milk">
            <h1 className="min-h-5 select-none text-sm font-semibold line-clamp-2">No organizations were found.</h1>
          </div>
        </NavLink>
      )}

      <Transition
        as={Fragment}
        enter="transition ease-out duration-150"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute left-0 bottom-20 z-50 ml-2 mt-5 flex w-screen max-w-max">
          <div className="w-screen max-w-[14rem] flex-auto divide-y divide-gray-300 rounded-lg rounded-t-lg bg-white text-sm shadow-xl ring-1 ring-gray-900/5">
            <div className="flex flex-col rounded-lg bg-silver">
              <div className="flex select-none items-center justify-center gap-x-2 rounded-lg p-2 py-1 text-sm font-bold text-blueberry">
                <h1>Views</h1>
              </div>
            </div>
            <div data-testid="view-dropdown-list" className="flex flex-col divide-y divide-gray-900/5 rounded-lg bg-silver">
              <input
                placeholder="Search..."
                className="mx-2 my-1 cursor-text gap-x-2 rounded-md border-2 border-gray-300 py-1 px-1.5 text-sm font-semibold italic text-blueberry outline-gray-400"
                value={queryString}
                onChange={(e) => {
                  setQueryString(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === ' ') {
                    e.stopPropagation();
                  }
                }}
              />
            </div>
            <div className="containerScrollbarDark flex max-h-[150px] flex-col divide-y divide-gray-300 overflow-y-scroll xl:max-h-[250px] ">
              {currentOrg?.teams
                ?.filter((t) => t.name.toLowerCase().includes(queryString.toLowerCase()))
                .map((team: OrgTeamWithInfo, idx: number) => {
                  const canAccessTeam = data?.currentOrgUser?.teams?.find((t) => t.id === team.id);
                  return (
                    <Popover.Button key={team.id} id="team-select-drop-down">
                    <div
                      key={idx}
                      id="team-select-drop-down-button"
                      className={classNames(
                        'group relative flex flex-row gap-x-6 py-3 px-3 text-left text-blueberry line-clamp-2 ',
                        team.id === currentTeam?.id ? 'font-black bg-gray-100 text-black' : 'font-semibold text-blueberry hover:bg-gray-50',
                        canAccessTeam ? '' : 'opacity-50 cursor-not-allowed'
                      )}
                      onClick={() => {
                        if (!canAccessTeam) {
                          toast.error('You do not have access to this team.');
                        } else {
                          setCurTeamId(team.id);
                          const redirectURL = getTeamChangeRedirectURL(location.pathname);
                          if (redirectURL) navigate(redirectURL);
                          setTimeout(() => {
                            setQueryString('');
                          }, 300);
                        }
                      }}
                    >
                      {team.logoUrl ? <img className="block h-5 w-5" src={team.logoUrl!} alt={team.name} /> : null}
                      <h1>{team.name + ifUnwrapperShowId(user, team.id) + (canAccessTeam ? '' : ' (Access Prohibited)')}</h1>
                    </div>
                  </Popover.Button>
                );
              })}
            </div>
            <div className="flex flex-col divide-y divide-gray-900/5 rounded-b-lg bg-silver">
              <NavLink
                to={`#`}
                onClick={() => setSwitchOrgModalOpen(true)}
                className="flex items-center justify-center gap-x-2 rounded-b-lg p-2 text-xs font-semibold text-blueberry hover:bg-gray-100"
              >
                <h1>Switch Organizations</h1>
                <ArrowsRightLeftIcon className="h-4 w-4 flex-none stroke-2" aria-hidden="true" />
              </NavLink>
            </div>
          </div>
          <div className="bottom-arrow absolute bottom-0 left-1/2" />
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}

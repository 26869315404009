/**
 * This file intefaces with Sentry.io and sends telemetry data to sentry.
 *
 * Use this file to store functions like `logError` `setUser` and any other hook ups you'd want to add to Sentry.
 *
 * I'm interfacing this because I don't want to tie ourselves
 */
import * as Sentry from '@sentry/react';
import { IUser } from './v2/contexts/UserContext';
import { client } from './client';
import { GetUserAttrOrgsDocument, GetUserAttrOrgsQuery } from './generated/graphql';
import { AxiosError } from 'axios';
import userflow from 'userflow.js';

const knownErrors = [
  // These errors are caused by the virtualized combo box we have
  // It's a known issue and not worth fixing: https://github.com/TanStack/virtual/issues/531
  'ResizeObserver loop completed with undelivered notifications.',
  'ResizeObserver loop limit exceeded',
];

userflow.init('ct_sb6hezi4qvdjfhzpd4eb3rkfui');

export const initTelemetry = () => {
  const isStaging = window.location.hostname.includes('staging');
  // !(process.env.NODE_ENV === 'development') &&
  Sentry.init({
    beforeSend(event, hint) {
      // Check if the event contains an exception with a known error message
      const hasKnownErrorMessage = event?.exception?.values?.some((exception) => knownErrors.some((knownError) => exception?.value?.includes(knownError)));

      if (hasKnownErrorMessage) {
        return null; // Discard the event if it matches a known error
      }

      // Extract the axios error from the hint if it exists
      const axiosError = hint.originalException as AxiosError | undefined;

      // Check if the error comes from an Axios request
      if (axiosError && axiosError.config && axiosError.config.url) {
        // Check if the request was to the OS domain
        const isFromOSDomain = axiosError.config.url.startsWith(process.env.REACT_APP_OS_DOMAIN!);
        // Check if the response status code is 500
        const hasStatusCode500 = axiosError.response && axiosError.response.status === 500;

        // If both conditions are met, discard the event
        if (isFromOSDomain && hasStatusCode500) {
          return null;
        }
      }

      // Otherwise, send the event
      return event;
    },
    beforeBreadcrumb(breadcrumb, hint) {
      try {
        if (breadcrumb.category?.startsWith('ui')) {
          breadcrumb.message = `CSS: ${hint?.event.target.className}\nElement:${hint?.event.target.tagName.toLowerCase()}\nText: ${
            hint?.event.target.innerText
          }`;
        }
      } catch (e) {}
      return breadcrumb;
    },
    dsn: 'https://65c2c659c8d84d9587a3416563ecbcce@o4503982348632064.ingest.sentry.io/4503982353088512',
    integrations: [new Sentry.BrowserTracing({ tracingOrigins: ['localhost', 'service.api.production.unwrap.ai'] }), new Sentry.Replay({})],

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    sampleRate: 1,
    environment: isStaging ? 'staging' : 'production',
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1,
  });
};

/**
 * Responsible for telling our system and plugins who our user is.
 * @param user
 */
export const setUser = (user: IUser) => {
  Sentry.setUser({ email: user.email });

  let org:
    | {
        __typename?: 'organization_users';
        orgId: number;
        organization?: {
          __typename?: 'organization';
          name: string;
        } | null;
      }
    | undefined;
  client.query<GetUserAttrOrgsQuery>({ query: GetUserAttrOrgsDocument, variables: {} }).then((attributesResult) => {
    const { data } = attributesResult;
    const { getUserAttributes } = data;
    if (getUserAttributes.organizations && getUserAttributes.organizations.length > 0) {
      // choosing the first org is pretty standard for all our customers.
      org = getUserAttributes.organizations[0];

      //@ts-ignore
      FS('setIdentity', {
        uid: getUserAttributes.id,
        properties: {
          displayName: `${user.firstName} ${user.lastName}`,
          email: user.email,
          orgName: org.organization?.name,
        },
      });
      userflow.identify(getUserAttributes.email, {
        name: `${getUserAttributes.firstName} ${getUserAttributes.lastName}`,
        email: getUserAttributes.email,
        signed_up_at: new Date(getUserAttributes.dateCreated).toISOString(),
        belongs_to_org: {
          set: getUserAttributes.organizations && getUserAttributes.organizations.length > 0 ? true : false,
          data_type: 'boolean',
        },
      });
      //@ts-ignore
      window.intercomSettings = {
        //@ts-ignore
        ...window.intercomSettings,
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
        user_id: getUserAttributes.id,
        companies: getUserAttributes.organizations.map((org) => {
          return {
            company_id: org.orgId,
            // created_at: 1394531169,
            name: org.organization?.name,
            // monthly_spend: 49,
            // plan: 'Pro',
            // size: 85,
            // website: 'http://example.com',
            // industry: 'Manufacturing',
          };
        }),
      };
      // @ts-ignore
      Intercom('update');
    } // @ts-ignore
  });
};

export const logMetric = (metricName: string, metricValue: number, metricUnit: 'millisecond' | 'second') => {
  const transaction = Sentry.getCurrentHub().getScope()?.getTransaction();
  if (transaction) {
    transaction.setMeasurement(metricName, metricValue, metricUnit);
  }
};

export const logError = (error: Error | any) => {
  Sentry.captureException(error);
};

export const clustersPageLogClustersLoaded = (duration: number) => {
  logMetric('clusters.loadClusters', duration, 'millisecond');
};

export const clustersPageLogSentencesLoaded = (duration: number) => {
  logMetric('clusters.loadSentences', duration, 'millisecond');
};

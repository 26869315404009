import { createContext } from 'react';
import { SearchPreviewReducerResult } from '../reducers/searchPreview';
import { defaultGroup } from '../lib/searchPreview';

const defaultState = {
  group: defaultGroup,
  belongingIds: [],
  excludedIds: [],
  searchTerm: '',
  excludeFromNewFilter: false,
  searchInput: '' // the value in the search bar
};
export const SearchPreviewContext = createContext<SearchPreviewReducerResult>(defaultState);

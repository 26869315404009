import ReactMarkdown from 'react-markdown';
import { useEffect } from 'react';
import ReactDOM from 'react-dom';

export const Markdown = ({ text, id }: { text: string, id: string }) => {
  useEffect(() => {
    if(document.getElementById(id) === null) return;
    ReactDOM.render(<ReactMarkdown components={{
      h1: ({node, ...props}) => <h1 className={'text-xl mb-4 text-blueberry-darker'} {...props} />,
      h2: ({node, ...props}) => <h2 className={'text-xl mb-3'} {...props} />,
      h3: ({node, ...props}) => <h3 className={'text-lg mb-2'} {...props} />,
      a: ({node, ...props}) => <a className={'text-raspberry underline hover:text-raspberry-darker'} target="_blank" rel="noopener noreferrer" {...props} />,
      h4: ({node, ...props}) => <h4 className={'text-medium accent-raspberry-darker'} {...props} />,
      ul: ({node, ...props}) => <ul className={'list-disc ml-6'} {...props} />,
      li: ({node, ...props}) => <li className={'mb-2'} {...props} />,
    }}
    children={text}/>, document.getElementById(id));
  }, [text, id]);

  return <div id={id} className={"flex flex-col w-1/2 text-blueberry-light"}/>
}
import { PaginatedQuery } from "./PaginatedQuery";
import { ProgressMonitor, ProgressState, Status } from "../ProgressMonitor";

/**
 * When we need to fetch many records and know the amount of records ahead of time,
 * we should execute paginated queries in parallel . This class abstracts away the business logic
 * of batching the paginated queries and awaiting for their completion.
 * 
 */
export class ParallelPagination<T> implements ProgressMonitor {
    private pageSize: number;
    private numThreads: number;
    private totalRecords: number;
    private recordsProccessed: number;
    private query: PaginatedQuery<T>;

    private take: number;
    private skip: number;

    private progressPolls: number;

    /**
     * Runs PaginatedQuery<T> in batches, which are processed in parallel.
     * Each batch will invoke the onResolve method of the given PaginatedQuery object.
     * 
     * @param query - an implementation of PaginatedQuery<T>
     * @param params - specifies the following:
     *                      1. pageSize of the requests
     *                      2. the number of requests to run in parallel
     *                      3. the total number of expecgted records
     */
    constructor(query: PaginatedQuery<T>, params: { pageSize: number, numThreads: number, totalRecords: number }) {
        this.pageSize = params.pageSize;
        this.numThreads = params.numThreads;
        this.totalRecords = params.totalRecords
        this.query = query;

        this.take = params.pageSize;
        this.skip = 0;
        this.recordsProccessed = 0;

        this.progressPolls = 0;
    }

    /**
     * Executes the given PaginatedQuery<t> until all records have been processed (or an error is thrown).
     * This method does not need to return anything. It just needs to be marked as async (hence returning a promise)
     */
    async execute(): Promise<void> {
        while (this.recordsProccessed < this.totalRecords) {
            const responses = await Promise.all(this.executeInParallel());
            responses.forEach(this.query.onResolve.bind(this.query));
        }
    }

    /**
     * This function gets called to get the progress of the underlying parallel fetching. 
     * We "fake" (or rather guess) the progress to avoid displaying a progress bar that is stuck at a certain percent for a long time.
     * @returns - ProgressState showing the percentage of records that have been fetched and the status of the fetching.
     */
    getProgress(): ProgressState {

        this.progressPolls = Math.min(100, this.progressPolls + 1);
        
        const percentRecordsInFlight = Math.min((this.skip / this.totalRecords), 1);

        // each time getProgress is called, fakeProgress will increase by a certain % of the records in flight until we reach 100%
        // ideally the records are returned and the next batch is requested before we reach 100%.
        const fakeProgress = this.getVelocity(percentRecordsInFlight) * this.progressPolls * percentRecordsInFlight; 
        const realProgress = (this.recordsProccessed / this.totalRecords);

        const progress = Math.min(fakeProgress, realProgress);

        return {
            status: Status.processing,
            percent: Math.round((progress) * 100)
        }
    }

    /**
     * This function returns a number that will dictate how fast the progress bar will move after every tick.
     * We do this for small reports where increasing 0.01% per tick may not be enough to get to 100% by the time the promises have resolved.
     * @param percentRecordsInFlight - the number of records that are being pulled in by pending promises
     * @returns 
     */
    private getVelocity(percentRecordsInFlight: number) : number {
        if(percentRecordsInFlight >= 0.8) return 0.02
        if(percentRecordsInFlight >= 0.5) return 0.015
        return 0.01
    }

    /**
     * Fetches N pages of data in parallel (where N = numThreads)
     * @returns Array of promises, where each promise corresponds to a page of data.
     */
    private executeInParallel(): Promise<T>[] {
        const promises: Promise<T>[] = [];

        for (let j = 0; j < this.numThreads; j++) {
            const paginationQuery = this.query.fetch({ take: this.take, skip: this.skip });
            paginationQuery.then(() => this.recordsProccessed += this.pageSize)
            promises.push(paginationQuery);
            this.skip += this.pageSize;
        }

        return promises;
    }
}
import { TaxonomyGroup } from '../../../v2/hooks/GroupHook';
import { useContext } from 'react';
import { TaxonomyContext } from '../../context/TaxonomyContext';
import { getGroupPageUrl } from '../../lib/groups';
import { useValidTeamAppContext } from '../../../v2/contexts/AppContext';
import { AppRoutes } from '../../../Routes';
import { ChevronRightIcon } from '@heroicons/react/24/solid';

export function GroupPageBreadcrumbs(props: { group: TaxonomyGroup }) {
  const { curTeamId: teamId, curOrgId: orgId, currentOrg } = useValidTeamAppContext();

  const taxonomy = useContext(TaxonomyContext);
  let groups = taxonomy.get(props.group.id)?.ancestors ?? [];
  const { group } = props;
  groups = groups.concat({ id: group.id, title: group.title! });

  return (
    <p className="text-sm flex flex-row flex-wrap">
      {groups.map((group, index) => {
        const groupPageUrl = getGroupPageUrl(teamId, orgId, group.id, AppRoutes.v3FullPath.explore);
        return (
          <span key={group.id} className="flex flex-row flex-wrap items-center">
            {index === groups.length - 1 ? (
              <div className="text-gray-500 font-light italic">{group.title}</div>
            ) : (
              <a target="_blank" href={groupPageUrl} className={'hover:cursor-pointer hover:text-gray-500'}>
                {group.title}
              </a>
            )}

            {index < groups.length - 1 ? <ChevronRightIcon className="w-4 h-4 mx-1" /> : null}
          </span>
        );
      })}
    </p>
  );
}

import { getAuthToken } from '../../v2/contexts/UserContext';
import axios from 'axios';

export class ChatClient {

  private baseUrl: string;
  private controller: AbortController;
  private signal: any;
  private active: boolean;
  public id: number;

  private static instance: ChatClient;

  public static getInstance() {
    if(!process.env.REACT_APP_NLP_API_BASE_URL) throw new Error("REACT_APP_NLP_API_BASE_URL is not set!");
    if(!this.instance) this.instance = new ChatClient(process.env.REACT_APP_NLP_API_BASE_URL);
    return this.instance;
  }

  public constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
    this.controller = new AbortController();
    this.signal = this.controller.signal;
    this.active = false;
    this.id = Math.random();
  }

  public abort() {
    if(this.active) {
      this.controller.abort('NetworkCallAborted');
      this.controller = new AbortController();
      this.signal = this.controller.signal;
    }
  }

  public async generateQuestions({teamId, page} : {teamId: number, page: number}) {
    let token = await getAuthToken();
    if(!token) throw new Error("Could not query the chat server. User is not authenticated.");

    return await axios.post(this.baseUrl + '/generate_suggested_questions', {
      "team_id": teamId,
      "page": page,
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': token,
        'X-Timezone-Offset': new Date().getTimezoneOffset(),
      }
    });
  }

  public async streamAnswer({question, teamId, onData, onDone} : {question: string, teamId: number, onData: (data: any) => void, onDone: () => void}) {
    let token = await getAuthToken();
    if(!token) throw new Error("Could not query the chat server. User is not authenticated.");

    this.active = true;
    const response = await fetch(this.baseUrl + '/get_answer', {
      signal: this.signal,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': token,
        'X-Timezone-Offset': `${new Date().getTimezoneOffset()}`,
      },
      body: JSON.stringify({
        "question": question,
        "team_id": teamId,
      }),
    });

    const reader = response.body!.pipeThrough(new TextDecoderStream()).getReader()

    while (true) {
      const { value, done } = await reader.read();
      if (done) {
        this.active = false;
        onDone();
        break;
      }

      try {
        value!.split('\n').filter((line) => line.length > 2).forEach((line) => {
          const data = JSON.parse(line);
          onData(data);
        });
      } catch (e) {
        console.log(e);
        console.error("caught error while parsing JSON", value!);
      }
    }
  }
}
import { useContext, useState, useEffect } from 'react';
import { useValidTeamAppContext } from '../../../../../../v2/contexts/AppContext';
import Form from '../../../../../../baseComponents/Form';
import Button, { ButtonVariant } from '../../../../../baseComponents/Button';
import FormInput from '../../../../../../baseComponents/FormInput';
import { External_Tickets_Integration, Feedback_Integration, Integration_Type, Integration_Type_Requirement } from '../../../../../../generated/graphql';
import { ApolloError } from '@apollo/client';
import UserContext from '../../../../../../v2/contexts/UserContext';
import TooltipIcon from '../../../TooltipIcon';
import Toggle from '../../../../Toggle';
import toast from 'react-hot-toast';
import { allRequirementsHaveValue, getSuccessOrError, getSuccessOrErrorIcon, integrationsOAuthRedirect } from './helpers';

interface QueryRes {
  success: boolean;
  error: string | null;
}

interface IntegrationFormComponentProps {
  modalOpen: boolean;
  setModalOpen: (open: boolean) => void;
  integration: Integration_Type;
  existingIntegrations: Feedback_Integration[] | External_Tickets_Integration[];
  requirements: Integration_Type_Requirement[];
  setRequirements: (reqs: Integration_Type_Requirement[]) => void;
  updateIntegration: () => Promise<QueryRes>;
  mutationLoading: boolean;
  testConnection: () => Promise<QueryRes>;
  testConnectionLoading: boolean;
  testConnectionError?: ApolloError | null;
  setSuccessModalOpen?: (open: boolean) => void;
}

/*
 * This is the base form for integration forms, such as Data Import and External Tickets. It handles the form inputs, validation, and submission.
 * The forms that implement this base form must provide the necessary props.
 * */

export const BaseIntegrationForm = ({
  modalOpen,
  setModalOpen,
  integration,
  existingIntegrations,
  requirements,
  setRequirements,
  updateIntegration,
  testConnection,
  testConnectionLoading,
  mutationLoading,
  testConnectionError,
  setSuccessModalOpen,
}: IntegrationFormComponentProps) => {
  const { curTeamId: teamId, curOrgId: orgId } = useValidTeamAppContext();
  const { user } = useContext(UserContext);

  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [canSubmit, setCanSubmit] = useState(false);
  const [lockSubmit, setLockSubmit] = useState(false); // lock submit button to prevent double clicks
  const [canValidate, setCanValidate] = useState(false);
  const [validationTestSuccess, setValidationTestSuccess] = useState<boolean>(false);

  //This is some ugly hack from a long time ago...needs to be revisited and removed.
  const [zdskAdminSettingsShown, setZdskAdminSettingsShown] = useState(false);
  const isZendeskIntegration = integration.title === 'Zendesk';

  useEffect(() => {
    setErrorMessage(testConnectionError?.message);
  }, [testConnectionError]);

  const updateRequirementValue = (reqToUpdate: Integration_Type_Requirement, newValue: string) => {
    setRequirements(
      requirements.map((req) => {
        if (req.id === reqToUpdate.id) {
          return {
            ...req,
            value:
              req.value.length === 1
                ? req.value.map((value) => {
                    return { ...value, value: newValue, requirement: req };
                  })
                : [{ value: newValue, id: 0, requirement: req }],
          };
        }
        return req;
      })
    );
    setCanValidate(allRequirementsHaveValue(requirements));
  };

  const handleTestConnection = async () => {
    setValidationTestSuccess(false);
    setErrorMessage(undefined);

    const res = await testConnection();

    if (res.success) {
      setValidationTestSuccess(true);
      setCanSubmit(allRequirementsHaveValue(requirements));
    } else {
      setErrorMessage(res.error ?? 'Unknown error - contact support');
    }
  };

  const handleUpdateFeedbackIntegration = async () => {
    const handleCompletion = () => {
      setModalOpen(false);
      setLockSubmit(false);
      //If you open a modal before the previous modal transition finishes (that's embedded in the Modal CSS code), the scrollbar starts misbehaving.
      setTimeout(() => {
        setSuccessModalOpen?.(true);
      }, 301);
    };

    const handleError = (err: ApolloError) => {
      setErrorMessage(err.message);
      setLockSubmit(false);
    };

    const res = await updateIntegration();
    if (res.success) handleCompletion();
    else handleError(new ApolloError({ errorMessage: res.error ?? 'Unknown error - contact support' }));
    setModalOpen(false);
  };

  const getFormButtons = (loading: boolean, enableSubmit: boolean) => {
    return (
      <div className="mt-8 flex flex-row justify-between gap-x-4 text-center">
        <Button variant={ButtonVariant.Tertiary} onClick={() => setModalOpen(false)} text="Cancel"></Button>
        <Button
          variant={ButtonVariant.Primary}
          text="Submit"
          onClick={() => {
            setLockSubmit(true);
            handleUpdateFeedbackIntegration();
          }}
          loadingConfirm={loading}
          disabled={!enableSubmit || loading || lockSubmit}
        ></Button>
      </div>
    );
  };

  return (
    <Form bottomRow={getFormButtons(mutationLoading, canSubmit || existingIntegrations.length > 0)}>
      <div className="space-y-2">
        {requirements.map((req) => {
          if (!zdskAdminSettingsShown) {
            if (req.key === 'authToken' && !req.value[0]?.value) return;
          }
          if (req.category === 'reply') return;
          if (req.key === 'summarize') return;
          if (req.key === 'ticket_amount') {
            if (!user?.isUnwrapper) return;
            if (!zdskAdminSettingsShown) return;
          }
          const isCheckbox = req.key.includes('toggle_');
          return (
            <FormInput
              disabled={!zdskAdminSettingsShown && isZendeskIntegration && req.key === 'authToken'}
              type={req.obfuscate ? 'password' : isCheckbox ? 'checkbox' : 'text'}
              description={req.description}
              label={req.title}
              key={req.id}
              placeholder={req.example}
              value={req.value[0]?.value}
              onChange={(e) => {
                updateRequirementValue(req, isCheckbox ? (e.target.checked ? '1' : '0') : e.target.value);
              }}
            />
          );
        })}
        <div className="pt-5">
          <div className="flex flex-row justify-end">
            {user?.isUnwrapper && integration.title.includes('Zendesk') ? (
              <div className="flex flex-row gap-x-1 items-center">
                <div className="flex flex-row gap-x-1 items-center">
                  <p>Admin Settings</p>
                  <TooltipIcon
                    tooltipContent={
                      'For Unwrap users. This shows admin fields, and lets you enter the API key manually and click VALIDATE without having to Auth. Useful after user authed Zendesk once, then we grab the API key from the DB.'
                    }
                  />
                </div>
                <Toggle initialState={false} value={zdskAdminSettingsShown} onSwitch={() => setZdskAdminSettingsShown((prev) => !prev)} />
              </div>
            ) : null}
            <div className="items-right flex justify-end space-x-5 sm:col-span-6">
              {getSuccessOrErrorIcon(validationTestSuccess, errorMessage)}

              {(integration.title.includes('Zendesk') || (integration.requirements.find((req) => req.requiredForAuthRedirect) && integration.authRedirect)) && (
                <Button
                  variant={ButtonVariant.Secondary}
                  text={'AUTHENTICATE'}
                  onClick={() => integrationsOAuthRedirect({ requirements, teamId, integration })}
                  disabled={zdskAdminSettingsShown || (!!requirements.find((req) => req.key === 'authToken')?.value[0]?.value ?? false)}
                />
              )}
              <Button
                variant={ButtonVariant.Secondary}
                id={`validate-integration-${integration.title.replace(' ', '-').toLowerCase()}`}
                text={'VALIDATE'}
                onClick={handleTestConnection}
                loadingConfirm={testConnectionLoading}
                disabled={
                  !canValidate &&
                  !zdskAdminSettingsShown &&
                  !(integration.title !== 'Zendesk' ? true : !!requirements.find((req) => req.key === 'authToken')?.value[0]?.value)
                }
              />
            </div>
          </div>
          <div className="flex mt-0 space-x-5 sm:col-span-6">{getSuccessOrError(integration, validationTestSuccess, errorMessage)}</div>
        </div>
      </div>
    </Form>
  );
};

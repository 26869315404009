import { Auth, Hub } from 'aws-amplify';
import { useContext, useEffect, useState } from 'react';
import { AuthConfigs, configureAmplifyAuth, IAuthConfig } from '../../../../AuthConfigs';
import { toast, Toaster } from 'react-hot-toast';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { useNavigate, useParams } from 'react-router-dom';
import UserContext from '../../../../v2/contexts/UserContext';
import LoadingSpinner from '../../../baseComponents/LoadingSpinner';

type TokenPayload = {
  access_token: string;
  id_token: string;
  refresh_token: string;
}

/**
 * This essentially creates a cognito session within amplify.
 * It does this by implementing authorization grant flow for SSO then registering the user with Amplify.
 *
 * We currently use this for IDP-initiated SSO, wherein the user goes from the IDP to the SP (us) with a SAML assertion.
 * The SP (us) will then generate an auth code and forward it to this endpoint.
 */
const SSOSession = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const pathParams = useParams();
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const code = urlParams.get('code');

  const [isLoading, setIsLoading] = useState(true);
  const errorMessageParams = {duration: 10000};

  const createSessionFromGrant = async (url: string, config: IAuthConfig) => {

    if (!code) {
      toast.error('Invalid authorization code.');
    }

    // Note: _oAuthHandler.handleAuthResponse accepts a callback function and ideally this logic could be put there
    // unfortunately, our version of Amplify has a bug that calls the token endpoint twice with the same grant code
    // one out of 2 of those calls will always fail (a grant code can only be used once, then it is invalid)
    // sometimes...the failure gets processed before the success and Amplify throws an exception and never executes the callback :(
    // so we have to use the Hub to listen for the tokens event and manually process the tokens :(
    // We publish to the 'auth' channel in latencyTracker.ts :( ..... which is now more of a general purpose event bus
    Hub.listen('auth', async ({ payload: { event, data } }) => {
      switch (event) {
        case 'tokens':
          if (userContext.user) {
            navigate('/dashboard/home');
            return;
          }

          const {access_token, id_token, refresh_token} = data as TokenPayload;

          if(!access_token || access_token.length <= 0) return;

          const AccessToken = new
          AmazonCognitoIdentity.CognitoAccessToken({
            AccessToken: access_token,
          });

          const IdToken = new AmazonCognitoIdentity.CognitoIdToken({
            IdToken: id_token,
          });

          const RefreshToken = new
          AmazonCognitoIdentity.CognitoRefreshToken({
            RefreshToken: refresh_token,
          });


          const sessionData = {
            IdToken: IdToken,
            AccessToken: AccessToken,
            RefreshToken: RefreshToken,
          };

          const session = new AmazonCognitoIdentity.CognitoUserSession(
            sessionData,
          );

          // next we'll look at how to turn the CognitoUserSession into
          // an AWS Amplify authenticated user
          const poolData = {
            UserPoolId: config.userPoolId,
            ClientId: config.userPoolWebClientId,
          };


          const userPool = new AmazonCognitoIdentity.CognitoUserPool(
            poolData,
          );

          // create an object containing the username and user pool.
          // You can get the username from the CognitoAccessToken
          const userData = {
            Username: AccessToken.payload.username,
            Pool: userPool,
          };

          // create a cognito user using the userData object
          const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

          // set the cognito user session w/ the CognitoUserSession
          cognitoUser.setSignInUserSession(session);

          const currentSession = await Auth.currentSession();
          setIsLoading(false);

          console.log('current session: ' + currentSession);

          if (currentSession) {
            await userContext.setCurrentUser();
            navigate('/dashboard/home');
            return;
          } else {
            toast.error('Invalid sign in credentials. Please contact your system administrator.');
            return;
          }
        default:
          break;
      }

      // We need to use a private data member (_oAuthHandler)  in Amplify so that we can set the session and user appropriately.
      // This is documented here: https://medium.com/codex/how-to-process-an-aws-cognito-authorization-code-grant-using-aws-amplify-b49d9ee052ca
      // future versions of Amplify have an official way to implement this using public methods
      // we are doing this work-around because the cost of upgrading is too great as of now.

    });
    try {
      await Auth['_oAuthHandler'].handleAuthResponse(url);
    } catch(err: any) {
      if(err.message && err.message == 'invalid_grant') {
        return;
      }
    }

  };

  useEffect(() => {
    if(userContext.user) {
      setIsLoading(false);
      return navigate('/dashboard/home');
    }
    if(!code) {
      toast.error('Invalid authorization code.', errorMessageParams);
      setIsLoading(false);
      return;
    }

    if(!pathParams.tenantId) {
      toast.error('Invalid tenant ID. Please contact your system administrator.', errorMessageParams);
      setIsLoading(false);
      return;
    }

    const authConfig = AuthConfigs.find((authConfig) => authConfig.tenantId === pathParams!.tenantId);
    if(!authConfig)  {
      toast.error('Invalid tenant ID. Please contact your system administrator.', errorMessageParams);
      return;
    }
    const config = configureAmplifyAuth(authConfig?.issuer!, authConfig?.tenantId!);

    createSessionFromGrant(window.location.toString(), config);
  }, []);

  return (
    <>
    {isLoading && (
      <div className={`flex-1 p-40`}>
        <div className={`flex text-blueberry flex-col items-center justify-center`}>
          <h1 className="text-2xl font-semibold m-y-[20%] animate-pulse">
            Initializing session...
          </h1>
          <br/>
          <LoadingSpinner />
        </div>
      </div>
    )
    }
      <Toaster
        position="top-right"
        toastOptions={{
          duration: 20000,
          className: '',
          style: {
            border: '1px solid white',
            fontFamily: 'sofiapro',
            backgroundColor: '#292E5B',

            color: 'white',
            borderRadius: '1rem',
            minWidth: '8%',
          },
        }}
      />
    </>
  );
};

export default SSOSession;

import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { classNames } from '../../../v2/util';
import Tippy from '@tippyjs/react';

export const TextArea = ({
  placeholder = '',
  rows,
  darkMode = false,
  buttonRow,
  handleTextChange,
  text,
  error,
  maxLength,
  disabled,
  disabledTooltipText,
}: {
  text: string;
  rows?: number;
  handleTextChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  error?: string;
  value?: string;
  buttonRow?: React.ReactNode;
  darkMode?: boolean;
  maxLength?: number;
  disabled?: boolean;
  disabledTooltipText?: string;
}) => {
  return (
    <div className={classNames('flex items-start space-x-4 w-full', rows && rows > 0 ? '' : 'h-full')}>
      <div className="min-w-0 flex-1 h-full relative">
        {error ? (
          <div className="flex flex-row justify-center items-center gap-x-1">
            <ExclamationTriangleIcon className="h-4 w-4 text-red-500 stroke-2" />
            <p className="text-red-500 mt-[2px]">{error}</p>
          </div>
        ) : null}
        <Tippy content={disabledTooltipText} disabled={!disabled} placement="top" theme="light-border">
          <div
            className={classNames(
              `h-full relative rounded-lg shadow-sm border ${darkMode ? 'bg-blueberry border-blueberry text-white' : 'border-gray-300 text-blueberry'}`,
              rows && rows > 0 ? '' : 'h-full'
            )}
          >
            <textarea
              disabled={disabled}
              rows={rows}
              maxLength={maxLength}
              name="reply"
              id="reply"
              className="block w-full pt-2 resize-none border-0 bg-transparent py-1.5 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 h-full"
              placeholder={placeholder}
              value={text}
              onChange={handleTextChange}
              data-testid="reply-textarea"
            />
            <div className="py-2.5" aria-hidden="true">
              <div className="py-px">
                <div className="h-9" />
              </div>
            </div>
            <div className={`absolute bottom-[1px] px-[1px] w-full rounded-b-lg duration-100 ${darkMode ? 'border-blueberry' : 'border-gray-300'}`}>
              <div
                className={`flex items-center justify-between rounded-b-lg space-x-3 border-t px-2 py-2 sm:px-3 ${
                  darkMode ? 'bg-blueberry-darker border-blueberry' : 'bg-gray-50 border-gray-200'
                }`}
              >
                {buttonRow}
              </div>
            </div>
          </div>
        </Tippy>
      </div>
    </div>
  );
};

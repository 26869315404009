import { useState } from 'react';
import { OrgTeamWithInfo } from '../../../v2/contexts/AppContext';
import SearchInput from '../../baseComponents/SearchInput';
import { PermissionsEditorForAUser } from './PermissionsEditor';
import { useOrgInfoState } from '../../../context/orgInfoContext';
import { AssignedRoles, Domain, OrganizationUserFragment, Role_Attachments } from '../../../generated/graphql';

//Displays all views with the roles the user has assigned on them.
//If no user is passed (aka a new member being invited), it will use the defaults.
export const ViewPermissionsEditorSection = ({ user }: { user?: OrganizationUserFragment }) => {
  const [searchInput, setSearchInput] = useState('');
  const { organization } = useOrgInfoState();

  const filteredViews = organization.teams.filter((view) => {
    if (searchInput === '') return true;
    return view.name.toLowerCase().includes(searchInput.toLowerCase());
  });

  const userRoleAttachments: Role_Attachments[] = user?.user?.roles.filter((role) => role.domain === Domain.Team) ?? [];

  return (
    <div className="flex flex-col items-center font-semibold gap-y-3 text-blueberry">
      <h1>Permissions per view</h1>
      <div className="flex w-full">
        <SearchInput
          noPadding
          rounded="md"
          placeholder={`Browse ${organization.teams.length} views...`}
          onSearch={() => {}}
          onChange={(e) => setSearchInput(e.target.value)}
          onClear={() => setSearchInput('')}
        />
      </div>
      <PermissionsEditorForAUser views={filteredViews} currentRoles={user ? userRoleAttachments : []} isFiltered={searchInput !== ''} />
    </div>
  );
};

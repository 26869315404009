import { IDropDownItem } from '../../baseComponents/DropDown';
import { ICategoryItem } from './FiltersTypes';
import { getPlaceholderText } from './FiltersUtil';
import { VirtualizedComboBox } from '../../components/VirtualizedComboBox';
export const SelectFilterDropdown = ({
  selectedCategory,
  setSelectedFilter,
  selectedFilter,
  comboBoxData,
}: {
  selectedCategory: IDropDownItem | undefined;
  selectedFilter: IDropDownItem | undefined;
  setSelectedFilter: (filter: IDropDownItem | undefined) => void;
  comboBoxData: IDropDownItem[];
}) => {
  return (
    <>
      {comboBoxData.length === 0 ? (
        <input
          data-testid="select-filter-input"
          type="text"
          className="w-full rounded-md border border-gray-300 bg-white pl-3 pr-14 text-blueberry shadow-sm focus:border-blueberry focus:outline-none focus:ring-1 focus:ring-blueberry sm:text-sm"
          placeholder={getPlaceholderText(selectedCategory as ICategoryItem)}
          onChange={(e) => {
            setSelectedFilter({ id: -1, name: e.currentTarget.value, displayName: e.currentTarget.value });
          }}
        />
      ) : (
        <VirtualizedComboBox
          data-testid="select-filter-combobox"
          comboBoxData={comboBoxData.sort((a, b) => a.name.localeCompare(b.name))}
          useDisplayName={true}
          selectedItem={selectedFilter}
          setSelectedItem={(item) => {
            setSelectedFilter(item);
          }}
        />
      )}
    </>
  );
};

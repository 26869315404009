import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { SparklesIcon } from '@heroicons/react/24/solid';
import Tippy from '@tippyjs/react';
import { debounce } from 'lodash';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Action, EntryFragment, Resource } from '../../../generated/graphql';
import { PermissionsContext } from '../../../v2/contexts/PermissionsContext';
import Button, { ButtonSize, ButtonVariant } from '../../baseComponents/Button';
import { IDropDownItem } from '../../baseComponents/DropDown';
import { FeedbackReplyData, replyDataContainsSuccesfulReply, ReplyHook } from '../../hooks/ReplyHook';
import { selector } from '../../pages/ReplyingPage';
import { TextArea } from './ReplyTextArea';
import TemplateDropDown from './TemplateDropdown';

interface RepliableSectionProps {
  entry: EntryFragment;
  replyHook: ReplyHook;
  existingReplyData?: FeedbackReplyData;
  currentReplyTextToSend?: string;
  selectedTemplateItem?: IDropDownItem;
}

export const RepliableSection = ({ entry, replyHook, existingReplyData, currentReplyTextToSend, selectedTemplateItem }: RepliableSectionProps) => {
  if (existingReplyData) {
    if (replyDataContainsSuccesfulReply(existingReplyData)) {
      //Entry contains a successful reply
      //Error situation: when you reply to an entry that already contains a successful reply. We need to show the error AND the existing reply.
      return (
        <div className="flex flex-col h-full">
          <div className="flex flex-col gap-y-3 rounded-3xl bg-silver h-full w-full px-6 py-5">
            <div className="flex flex-row justify-between">
              <p className="font-semibold">{existingReplyData.replyConversationPart?.userId ? 'Sent from Unwrap' : 'Agent'}</p>
              <ReplyDataErrorBadge replyData={existingReplyData} />
            </div>
            <p>{existingReplyData.replyConversationPart?.fullText}</p>
          </div>
        </div>
      );
    } else {
      return <ReplyingSectionBody entry={entry} replyHook={replyHook} existingReplyData={existingReplyData} currentReplyTextToSend={currentReplyTextToSend} />;
    }
  } else {
    return (
      <ReplyingSectionBody
        entry={entry}
        replyHook={replyHook}
        existingReplyData={existingReplyData}
        currentReplyTextToSend={currentReplyTextToSend}
        selectedTemplateItem={selectedTemplateItem}
      />
    );
  }
};

const ReplyingSectionBody = ({ entry, replyHook, existingReplyData, currentReplyTextToSend, selectedTemplateItem }: RepliableSectionProps) => {
  const [generateLoading, setGenerateLoading] = useState(false);
  const [curText, setCurText] = useState(currentReplyTextToSend ?? '');
  const { hasPermission } = useContext(PermissionsContext);
  const canReplyToUsers = hasPermission(Resource.Replies, Action.Exec);

  useEffect(() => {
    if (currentReplyTextToSend !== curText) setCurText(currentReplyTextToSend ?? '');
  }, [currentReplyTextToSend]);

  useEffect(() => {
    if (selectedTemplateItem?.value) {
      setCurText(selectedTemplateItem.value as string);
      replyHook.updateReplyTextToSend(entry.id, selectedTemplateItem.value as string);
    }
  }, [selectedTemplateItem]);

  const debouncedUpdateReplyTextToSend = useCallback(
    debounce((id, value) => {
      replyHook.updateReplyTextToSend(id, value);
    }, 200),
    [replyHook.replyTextsToSend]
  );

  const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCurText(event.target.value);
    debouncedUpdateReplyTextToSend(entry.id, event.target.value);
  };

  return (
    <div className="flex flex-col h-full">
      {existingReplyData ? <ReplyDataErrorBadge replyData={existingReplyData} /> : null}
      <TextArea
        disabled={replyHook.generatingGeneralReplies}
        disabledTooltipText="Please wait for the smart replies to finish generating."
        value={curText}
        handleTextChange={handleTextChange}
        text={curText}
        maxLength={350}
        placeholder="Type your reply...or use our AI-powered reply crafting feature above."
        buttonRow={
          <ReplyButtonRow
            loadingGenerateReplies={generateLoading}
            generateReply={async () => {
              setGenerateLoading(true);
              await replyHook.handleGenerateReply(currentReplyTextToSend ?? '', entry);
              setGenerateLoading(false);
            }}
            handleSetSelected={(item) => item && replyHook.updateReplyTextToSend(entry.id, item?.value as string)}
            enableSendReplyButton={(currentReplyTextToSend?.length ?? 0) > 0 && !replyHook.generatingGeneralReplies && canReplyToUsers}
            submitReply={(cb: () => void) => {
              replyHook.sendIndividualReply(entry.id, cb);
            }}
          />
        }
      />
    </div>
  );
};

const ReplyButtonRow = ({
  loadingGenerateReplies,
  submitReply,
  generateReply,
  handleSetSelected,
  enableSendReplyButton,
}: {
  loadingGenerateReplies: boolean;
  submitReply: (cb: () => void) => void;
  generateReply: () => void;
  handleSetSelected: (item: IDropDownItem | undefined) => void;
  enableSendReplyButton: boolean;
}) => {
  const [selectedItem, setSelectedItem] = useState<IDropDownItem | undefined>(undefined);
  const [replyLoading, setReplyLoading] = useState(false);
  const { hasPermission } = useContext(PermissionsContext);
  const canReplyToUsers = hasPermission(Resource.Replies, Action.Exec);
  return (
    <div className="flex flex-row justify-between w-full ">
      <TemplateDropDown
        placeholderText="Choose a template..."
        dropDownData={selector}
        setSelectedItem={(item) => {
          setSelectedItem(item);
          handleSetSelected(item);
        }}
        selectedItem={selectedItem}
      />
      <div className="flex flex-row items-center justify-end gap-x-3 w-full">
        <Tippy content="Generate a reply based on your current message" theme="light">
          <div>
            <Button
              disabled={!canReplyToUsers}
              disabledTooltip="You do not have permission to reply to users. Please contact your administrator and request the Replier role."
              loadingConfirm={loadingGenerateReplies}
              loadingText="Generating smart replies..."
              variant={ButtonVariant.Secondary}
              size={ButtonSize.Small}
              onClick={() => generateReply()}
              id={'button-generate-smart-reply'}
              text="Create Smart Reply"
              icon={<SparklesIcon className="w-4 h-4" />}
            />
          </div>
        </Tippy>

        <Button
          disabled={!enableSendReplyButton}
          loadingText="Send Reply"
          variant={ButtonVariant.Secondary}
          size={ButtonSize.Small}
          loadingConfirm={replyLoading}
          onClick={() => {
            setReplyLoading(true);
            submitReply(() => setReplyLoading(false));
          }}
          id="button-send-reply"
          text="Send Reply"
        />
      </div>
    </div>
  );
};

export const ReplyDataErrorBadge = ({ replyData }: { replyData: FeedbackReplyData }) => {
  return replyData.errorMessage ? (
    <div className="flex flex-row gap-x-1 justify-center items-end bg-red-100 px-3 py-0.5 rounded-md">
      <ExclamationTriangleIcon className="h-5 w-5 text-red-600 stroke-2" />
      <p className="text-red-500">{replyData.errorMessage}</p>
    </div>
  ) : null;
};

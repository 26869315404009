import { ArrowTopRightOnSquareIcon, NewspaperIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import Button, { ButtonVariant } from '../baseComponents/Button';
import sourcesMap, { ISource } from '../../v2/other/sourcesMap';
import 'tippy.js/themes/light.css';
import Tippy from '@tippyjs/react';
import { alphabeticalSort, localDateString } from '../../v2/util';
import { SegmentLabel } from './SegmentLabel';
import { FullConversation } from './FullConversation';
import he from 'he';
import { EntryFragment } from '../../generated/graphql';
interface FeedbackSentenceClusterProps {
  id: number;
  entry: EntryFragment;
  loading?: boolean;
  sentenceHasBeenAdded?: boolean;
}

const GroupEntryPreviewPage = ({ id, entry }: FeedbackSentenceClusterProps): JSX.Element => {
  const [expanded, setExpanded] = useState(false);
  const renderFullText = (entry: EntryFragment) => {
    if (!expanded) {
      return <DistillateDisplay entry={entry} />;
    } else if (entry.hasConversation) {
      return (
        <div className="w-full">
          <FullConversation includeTitle entry={entry} title={entry.title ?? ''} />
          <GroupSentenceEntryFooter entry={entry} />
        </div>
      );
    } else {
      return <FullEntry entry={entry} />;
    }
  };
  return (
    <div className="group-sentence flex flex-row items-center justify-between gap-x-2 border-b-2 border-gray-200">
      <div
        className="col-span-9 flex min-h-[48px] w-full flex-row items-center justify-start rounded py-0.5 px-0.5 duration-100 transition hover:cursor-pointer hover:bg-gray-100"
        onClick={(e) => {
          const selection = document.getSelection();
          if (selection?.toString()?.length && selection.toString.length >= 1) return;
          setExpanded(!expanded);
        }}
      >
        <div className="flex w-full flex-row items-center justify-between gap-x-2 mx-2 overflow-hidden">
          <div id="entry-sentence-text" className={`${!expanded || (expanded && !entry.hasConversation) ? 'w-11/12' : 'w-full flex-1'} `}>
            {renderFullText(entry)}
            {!expanded && entry?.date ? <i className="text-xs font-semibold"> - {localDateString(new Date(entry.date), 'MMM Do')}</i> : null}
          </div>
          {entry?.source_permalink && (!expanded || (expanded && !entry.hasConversation)) ? (
            <div className="opacity-0 duration-100 transition group-sentence-hover:opacity-100">
              <Tippy
                theme="dark"
                delay={200}
                content={
                  <span className="text-center text-white">
                    <p className="text-center">View feedback source</p>
                  </span>
                }
              >
                <a href={entry.source_permalink} target="_blank" rel="noreferrer" onClick={(e) => e.stopPropagation()}>
                  <ArrowTopRightOnSquareIcon className="h-5 w-5 cursor-pointer duration-100 hover:stroke-2" />
                </a>
              </Tippy>
            </div>
          ) : null}
          {entry.distillateText && (
            <Tippy
              theme="dark"
              content={
                <div className="text-white">
                  <p className="text-lg mb-2 font-semibold">Summary:</p>
                  <p>{entry.distillateText}</p>
                </div>
              }
            >
              <div className="justify-center items-center flex flex-row opacity-0 duration-100 transition group-sentence-hover:opacity-100">
                <NewspaperIcon className="h-5 w-5 stroke-1.5" />
              </div>
            </Tippy>
          )}
        </div>
      </div>
    </div>
  );
};

export default GroupEntryPreviewPage;

interface SentenceWithSurroundingTextProps {
  entry: EntryFragment;
}

const DistillateDisplay = ({ entry }: SentenceWithSurroundingTextProps): JSX.Element => {
  return <p className="lg:text-md inline text-sm font-bold break-words">{he.decode(entry.distillateText ?? '')}</p>;
};

interface FullEntryProps {
  entry: EntryFragment;
  condensed?: boolean;
}

export const FullEntry = ({ entry, condensed }: FullEntryProps): JSX.Element => {
  const titleSentence = entry.title;
  return (
    <div className="flex flex-col px-0.5 w-full text-left py-1">
      {entry.title && entry.title.length > 0 && (
        <div className="flex flex-row">
          <h1 className={`inline text-lg ? 'font-bold' : ''}`} key={titleSentence}>
            {he.decode(entry.title)}
          </h1>
        </div>
      )}
      <div>
        <p className="text-md inline break-words"> {he.decode(entry.text ?? '')}</p>
      </div>
      <GroupSentenceEntryFooter entry={entry} condensed={condensed} />
    </div>
  );
};

export const GroupSentenceEntryFooter = ({ entry, condensed }: { entry: EntryFragment; condensed?: boolean }) => {
  const source: ISource | undefined = sourcesMap[entry.source!];
  return (
    <div className={`flex flex-row items-center justify-between  gap-x-2 pt-2  ${condensed ? 'truncate	' : ''}`}>
      <div className="flex flex-row items-center justify-between  gap-x-2 ">
        {source ? <img className="inline-block m-0 h-5 w-5" src={source.logo} /> : <i className="text-sm font-semibold">{entry.source}</i>}
        {entry.submitter ? <span className={`text-sm ${condensed ? 'truncate' : ''}`}>{' - ' + entry.submitter + ' - '}</span> : null}
        {entry?.date ? <i className={`text-sm ${condensed ? 'truncate' : ''}`}> {localDateString(new Date(entry.date), 'MMM Do Y')}</i> : null}
        {!condensed && entry.source_permalink && (
          <>
            {' - '}
            <a onClick={(e) => e.stopPropagation()} href={entry.source_permalink} target="_blank" rel="noreferrer">
              <Button
                variant={ButtonVariant.IconRaw}
                expandWidth
                icon={<ArrowTopRightOnSquareIcon className="inline-block h-3.5 w-3.5 cursor-pointer duration-100 hover:scale-125" />}
              />
            </a>
          </>
        )}
      </div>
      <div className="flex flex-row gap-x-3 ">
        {!condensed && entry.segments && entry.segments.length !== 0
          ? [...entry.segments]
              .sort((a, b) => alphabeticalSort(a.groupName, b.groupName))
              .map((segment, index) => {
                if (index >= 2) return null;
                return <SegmentLabel key={index} segment={segment} />;
              })
          : null}
        <div>{entry.segments && entry.segments.length > 2 ? <SegmentLabel remainingSegments={entry.segments.slice(2)} /> : null}</div>
      </div>
    </div>
  );
};

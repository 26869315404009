import { useContext, useState } from 'react';
import Button, { ButtonSize, ButtonVariant } from '../baseComponents/Button';
import TooltipIcon from '../components/Modals/TooltipIcon';
import { Action, FilterInput, New_Alerts, Resource, useDataForFiltersQuery } from '../../generated/graphql';
import { toast } from 'react-hot-toast';
import LoadingSpinner from '../../v2/components/LoadingSpinner';
import { PageWrapper } from './PageWrapper';
import { useValidTeamAppContext } from '../../v2/contexts/AppContext';
import CreateStandardAlertModal from '../components/Modals/CreateStandardAlertModal';
import AlertsTable from '../components/AlertsTable';
import moment from 'moment';
import { Cog6ToothIcon, TrashIcon } from '@heroicons/react/24/solid';
import Tippy from '@tippyjs/react';
import YesCancelModal from '../components/Modals/YesCancelModal';
import { CreateStandardAlertParams, useAlertHook } from '../hooks/AlertHook';
import { NavLink } from 'react-router-dom';
import { AppRoutes } from '../../Routes';
import { truncateAndEllipsis } from '../../v2/util';
import { GroupSubscriptionPopover, cadenceOptions } from '../components/Popovers/GroupSubscriptionPopover';
import { buildFilterInputFromSavedFilterInput } from './ChartsPage';
import { useOrganizationHook } from '../../v2/hooks/OrganizationHook';
import { CreateAlertsButton } from './alerts/CreateAlertsButton';
import { PermissionsContext } from '../../v2/contexts/PermissionsContext';

export function AlertsPage() {
  const { curTeamId: teamId, curOrgId: orgId } = useValidTeamAppContext();
  const [createAlertModalOpen, setCreateAlertModalOpen] = useState(false);
  const [loadingDataForFilters, setLoadingDataForFilters] = useState(true);
  const { filteredOrgUsers } = useOrganizationHook();
  const [alertToDeleteId, setAlertToDeleteId] = useState<number | null>(null);
  const [standardAlertToEditId, setStandardAlertToEditId] = useState<number | null>(null);
  const {hasPermission} = useContext(PermissionsContext);

  const canDeleteAlerts = hasPermission(Resource.Alerts, Action.Delete);
  const canEditAlerts = hasPermission(Resource.Alerts, Action.Update);

  const [popoverKey, setPopoverKey] = useState<number>(0);

  const alertHook = useAlertHook({
    teamId,
  });
  const { standardAlerts, groupSubscriptions, createStandardAlert, deleteAlert, editStandardAlert, loadingStatuses } = alertHook;

  const { data: dataForFilters } = useDataForFiltersQuery({
    variables: { teamId, orgId },
    onCompleted(data) {
      setLoadingDataForFilters(false);
    },
  });

  const onDeleteAlert = async () => {
    if (alertToDeleteId)
      await deleteAlert({
        alertId: alertToDeleteId,
        successCb: () => toast.success('Alert deleted successfully'),
        failureCb: () => toast.error('Error deleting alert'),
      });
    setAlertToDeleteId(null);
  };

  const onCreateStandardAlert = async (standardAlertParams: CreateStandardAlertParams) => {
    await createStandardAlert({
      ...standardAlertParams,
    });
    setCreateAlertModalOpen(false);
  };

  const onEditStandardAlert = async (standardAlertParams: CreateStandardAlertParams, alertId: number | null) => {
    if (!standardAlertToEditId) return;
    await editStandardAlert(standardAlertToEditId, standardAlertParams);
    setStandardAlertToEditId(null);
  };

  return (
    <PageWrapper title={'Alerts'}>
      <div className="flex flex-col gap-y-3 font-sofiapro">
        {createAlertModalOpen && (
          <CreateStandardAlertModal
            modalOpen={createAlertModalOpen}
            confirmButton={onCreateStandardAlert}
            callbackModal={() => {
              setCreateAlertModalOpen(false);
            }}
            loadingConfirm={loadingStatuses.creatingAlert}
            userEmails={filteredOrgUsers.map((orgUser) => orgUser.email)}
          />
        )}
        {standardAlertToEditId && (
          <CreateStandardAlertModal
            modalOpen={!!standardAlertToEditId}
            confirmButton={onEditStandardAlert}
            alertToEdit={standardAlerts.find((alert) => alert.id === standardAlertToEditId)}
            callbackModal={() => {
              setStandardAlertToEditId(null);
            }}
            loadingConfirm={loadingStatuses.editingAlert}
            userEmails={filteredOrgUsers.map((orgUser) => orgUser.email)}
          />
        )}
        {alertToDeleteId && (
          <YesCancelModal
            text="Are you sure you want to delete this alert?"
            subtext="This will delete the alert for all users subscribed to it."
            modalOpen={!!alertToDeleteId}
            confirmText="Yes, delete"
            confirmButton={onDeleteAlert}
            callbackModal={() => {
              setAlertToDeleteId(null);
            }}
            loadingConfirm={loadingStatuses.deletingAlert}
          />
        )}
        <main>
          <div className="flex flex-col gap-y-10">
            <div className="flex flex-col gap-y-12 xl:grid xl:grid-cols-2 gap-x-8">
              {/* Group Subscriptions Section */}
              <div className="flex flex-col gap-y-3">
                <div className="flex flex-row items-center justify-between border-b pb-2">
                  <div className="flex flex-row items-baseline gap-x-2">
                    <h1 className="text-3xl font-bold text-blueberry">Group Subscriptions</h1>
                    <TooltipIcon
                      tooltipContent={
                        'Subscribe to a group to receive daily/weekly updates about new feedback in that group. To subscribe, go to any Group and click the Bell icon.'
                      }
                    />
                  </div>
                  <NavLink to={AppRoutes.v3FullPath.explore} className="font-semibold text-blue-800">
                    <Button text={'Go to Groups'} variant={ButtonVariant.Primary} size={ButtonSize.Small} />
                  </NavLink>
                </div>
                <div>
                  {loadingStatuses.loadingAlerts || loadingDataForFilters ? (
                    <LoadingSpinner height="auto" />
                  ) : (
                    <AlertsTable
                      data={groupSubscriptions ?? []}
                      columns={[
                        {
                          title: 'Group',
                          render: (alert: New_Alerts) => {
                            const urlParams = new URLSearchParams();
                            const filter: FilterInput = buildFilterInputFromSavedFilterInput(alert.filterInput, moment().subtract(90, 'days').toDate());
                            urlParams.set('group', encodeURIComponent(JSON.stringify(filter)));
                            urlParams.set('teamId', alert.team_id.toString());
                            urlParams.set('orgId', orgId.toString());
                            urlParams.set('expanded', 'drawer');

                            const buttonUrl = AppRoutes.v3FullPath.explore + '?' + urlParams.toString();
                            return (
                              <NavLink to={buttonUrl} target="_blank" rel="noreferrer">
                                <div className="flex">
                                  <Tippy content="Visit this group" placement="top">
                                    <p className="text-blueberry font-semibold">
                                      "
                                      {truncateAndEllipsis(
                                        dataForFilters?.getAllGroups.find((group) => group.id === alert.filterInput.groupFilter?.[0]?.groups?.[0].id ?? -1)
                                          ?.title ?? '',
                                        35
                                      )}
                                      "
                                    </p>
                                  </Tippy>
                                </div>
                              </NavLink>
                            );
                          },
                        },
                        {
                          title: 'Cadence',
                          render: (alert: New_Alerts) => cadenceOptions.find((cad) => cad.value === alert.cadence)?.name ?? alert.cadence ?? '',
                        },
                        {
                          title: '',
                          extraTdClassName: 'flex justify-end',
                          render: (alert: New_Alerts) => (
                            <div className="flex flex-row gap-x-2 items-center">
                              <GroupSubscriptionPopover
                                subscriptionToEdit={alert}
                                parentAlertHook={alertHook}
                                groupId={'-1'}
                                key={popoverKey}
                                updateKey={setPopoverKey}
                              >
                                <Tippy theme="dark" delay={200} placement="top" disabled={canDeleteAlerts} content={<p className="text-center">You don't have permission to edit this subscription</p>}>
                                  <div className={`flex w-min flex-row items-center rounded-3xl px-2 text-blueberry transition duration-150 hover:bg-gray-300 hover:text-indigo-900 ${canEditAlerts ? 'cursor-pointer' : 'cursor-not-allowed'}`}>
                                    <Cog6ToothIcon className="h-4 w-4" />
                                  </div>
                                </Tippy>
                              </GroupSubscriptionPopover>

                              <Tippy theme="dark" delay={200} placement="top" disabled={canDeleteAlerts} content={<p className="text-center">You don't have permission to delete this subscription</p>}>
                                <div
                                  className={`flex w-min flex-row items-center rounded-3xl px-2 text-blueberry transition duration-150 hover:bg-gray-300 hover:text-indigo-900 ${canDeleteAlerts ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setAlertToDeleteId(alert.id);
                                  }}
                                >
                                  <TrashIcon className="h-4 w-4" />
                                </div>
                              </Tippy>
                            </div>
                          ),
                        },
                      ]}
                    />
                  )}
                </div>
              </div>
              <div className="flex flex-col gap-y-6">
                {/* Volume Alarms section */}
                <div className="flex flex-col gap-y-3">
                  <div className="flex flex-row items-center justify-between border-b pb-2">
                    <div className="flex flex-row items-baseline gap-x-2">
                      <h1 className="text-3xl font-bold text-blueberry">Volume Alarms</h1>
                      <TooltipIcon
                        tooltipContent={
                          'Get notified if new feedback volume in any combination of filters meets a threshold you define. For example, get notified if you get more than 10 new negative mentions from the App Store.'
                        }
                      />
                    </div>
                    <CreateAlertsButton
                      loadingDataForFilters={loadingDataForFilters}
                      onClick={() => setCreateAlertModalOpen(true)}
                    />
                  </div>
                  <div className="">
                    {loadingStatuses.loadingAlerts || loadingDataForFilters ? (
                      <LoadingSpinner height="auto" />
                    ) : (
                      <AlertsTable
                        data={standardAlerts ?? []}
                        columns={[
                          {
                            title: 'Alert Name',
                            render: (alert: New_Alerts) => (
                              <p className="text-blueberry font-semibold">{truncateAndEllipsis(alert.name, 15) ?? 'Unnamed Alert'}</p>
                            ),
                          },
                          {
                            title: 'Last Triggered',
                            render: (alert: New_Alerts) => {
                              const lastTriggered = alert.notifications?.[alert.notifications.length - 1]?.sent_date;
                              return lastTriggered ? moment(lastTriggered).fromNow() : 'Never';
                            },
                          },
                          {
                            title: 'Threshold',
                            render: (alert: New_Alerts) => {
                              const threshold = alert.threshold;
                              return threshold ? (threshold === 1 ? '1 mention' : `${threshold} mentions`) : '1 mention';
                            },
                          },
                          {
                            title: 'Period',
                            render: (alert: New_Alerts) => {
                              const period = alert.period;
                              return period ? (period === 1 ? '1 day' : `${period} days`) : '1 day';
                            },
                          },
                          {
                            title: '',
                            extraTdClassName: 'flex justify-end',
                            render: (alert: New_Alerts) => (
                              <div className="flex flex-row gap-x-2 items-center">
                                <Tippy theme="dark" delay={200} placement="top" disabled={canDeleteAlerts} content={<p className="text-center">You don't have permission to edit this alert</p>}>
                                  <div
                                    className={`flex w-min ${canEditAlerts ? 'cursor-pointer' : 'cursor-not-allowed'} flex-row items-center rounded-3xl px-2 text-blueberry transition duration-150 hover:bg-gray-300 hover:text-indigo-900`}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      if (canEditAlerts) {
                                        setStandardAlertToEditId(alert.id);
                                      }
                                    }}
                                  >
                                    <Cog6ToothIcon className="h-4 w-4" />
                                  </div>
                                </Tippy>
                                <Tippy theme="dark" delay={200} placement="top" disabled={canDeleteAlerts} content={<p className="text-center">You don't have permission to delete this alert</p>}>
                                  <div
                                    className={`flex w-min ${canDeleteAlerts ? 'cursor-pointer' : 'cursor-not-allowed'} flex-row items-center rounded-3xl px-2 text-blueberry transition duration-150 hover:bg-gray-300 hover:text-indigo-900`}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      if (canDeleteAlerts) {
                                        setAlertToDeleteId(alert.id);
                                      }
                                    }}
                                  >
                                    <TrashIcon className="h-4 w-4" />
                                  </div>
                                </Tippy>
                              </div>
                            ),
                          },
                        ]}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </PageWrapper>
  );
}

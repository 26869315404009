import { SetStateAction, useEffect, useState } from 'react';
import { APIV2 } from '../../api/apiv2';
import { Feedback_Integration_Type, Integration_Type, Integration_Type_Requirement } from '../../generated/graphql';
import CSVIntegrationModal from '../components/Modals/CSVIntegrationModal';
import { IIntegrationRedirect } from '../pages/IntegrationsPage';
import { useValidTeamAppContext } from '../../v2/contexts/AppContext';
import EditIntegrationModal from './Modals/integrations/EditIntegrationModal';
import { FeedbackIntegrationCardBody } from './FeedbackIntegrationCardBody';
import { ExternalTicketsIntegrationCardBody } from './ExternalTicketsIntegrationCardBody';

interface IntegrationCardProps {
  isConnected?: boolean;
  integration: Integration_Type;
  redirect?: IIntegrationRedirect;
  setSuccessModalOpen?: React.Dispatch<SetStateAction<boolean>>;
  setModalOpen: (bool: boolean) => void;
  modalOpen: boolean;
}

export const IntegrationCard = ({ integration, redirect, setSuccessModalOpen, modalOpen, setModalOpen }: IntegrationCardProps): JSX.Element => {
  const { curTeamId: teamId } = useValidTeamAppContext();

  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false);
  const [modalErrorMsg, setModalErrorMsg] = useState(null);
  const [modalSuccessMsg, setModalSuccessMsg] = useState<string | null>(null);
  const [modalRawErrorMsg, setModalRawErrorMsg] = useState(null);
  const [requirements, setRequirements] = useState<Integration_Type_Requirement[]>(integration.requirements);

  const confirmIntegration = async ({ csvToUpload, source, sourceName, isCsv, isRedshift }: any) => {
    setIsLoadingConfirm(true);
    try {
      if (isCsv) await APIV2.teams.integrations.createCSVIntegration(teamId, sourceName, csvToUpload);
      else if (isRedshift) await APIV2.teams.integrations.createRedshiftIntegration(teamId, sourceName, csvToUpload);
      else await APIV2.teams.integrations.createIntegration(teamId, source, csvToUpload);
      setModalErrorMsg(null);
      setModalSuccessMsg(`Successfully integrated ${isCsv ? 'CSV' : source}. Items have been added to your dashboard.`);
      setModalOpen(false);
    } catch (err: any) {
      console.error('error uploading data', err.response?.data);
      setModalSuccessMsg(null);
      setModalErrorMsg(err.response?.data?.msg || 'Unknown error');
      setModalRawErrorMsg(err.response?.data?.raw_error);
    }
    setIsLoadingConfirm(false);
  };
  useEffect(() => {
    setModalErrorMsg(null);
    setModalSuccessMsg(null);
    if (redirect && redirect.scraperKey === integration.scraperKey) {
      const requirements: Integration_Type_Requirement[] = integration.requirements.map((req) => {
        if (req.key === 'authToken') {
          return { ...req, value: [{ value: redirect.authToken, id: 0, requirement: req }] };
        }
        if (req.key === 'url_zendesk' && redirect.url_zendesk) {
          return { ...req, value: [{ value: redirect.url_zendesk, id: 0, requirement: req }] };
        }
        if (redirect.additionalObjects[req.key]) {
          return { ...req, value: [{ value: redirect.additionalObjects[req.key], id: 0, requirement: req }] };
        }
        return req;
      });
      setRequirements([...requirements]);
      setModalOpen(true);
    }
  }, []);

  const callbackResetData = () => {
    /*Hack to reset error/success/disabled when another file is selected. Can be improved */
    setModalErrorMsg(null);
    setModalSuccessMsg(null);
  };

  const openModalOrAuthRedirect = (integration: Integration_Type, redirect?: IIntegrationRedirect) => {
    if (
      integration.authRedirect &&
      !integration.title.includes('Zendesk') /*This check should be better than a frontend string check*/ &&
      redirect?.scraperKey !== integration.scraperKey &&
      !integration.requirements.find((req) => req.requiredForAuthRedirect) &&
      (integration.teamIntegration?.length || integration.orgExternalTicketsIntegration?.length) === 0
    ) {
      const redirect = `${integration.authRedirect}&state=${encodeURIComponent(
        JSON.stringify({ redirect: window.location.href, teamId: teamId, scraperKey: integration.scraperKey })
      )}`;
      window.location.replace(redirect);
    } else {
      setModalOpen(true);
    }
  };

  return (
    <div>
      {integration.requirements.length > 0 ? (
        <EditIntegrationModal
          integration={integration}
          setRequirements={setRequirements}
          requirements={requirements}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          setSuccessModalOpen={setSuccessModalOpen}
        />
      ) : (
        <CSVIntegrationModal
          modalOpen={modalOpen}
          source={integration.title}
          callbackModal={() => setModalOpen(false)}
          confirmButton={confirmIntegration}
          loadingConfirm={isLoadingConfirm}
          errorMsg={modalErrorMsg}
          successMsg={modalSuccessMsg}
          callbackResetData={callbackResetData}
          rawErrorMsg={modalRawErrorMsg}
        />
      )}
      {integration.feedbackIntegrationType === Feedback_Integration_Type.ExternalTickets ? (
        <ExternalTicketsIntegrationCardBody integration={integration} redirect={redirect} setModalOpen={setModalOpen} />
      ) : (
        <FeedbackIntegrationCardBody integration={integration} redirect={redirect} openModalOrAuthRedirect={openModalOrAuthRedirect} />
      )}
    </div>
  );
};

export default IntegrationCard;

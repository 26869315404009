import { Dispatch, ReactNode, createContext, useContext, useReducer } from 'react';
import { OrgInfoAction, OrgInfoReducer, OrgInfoState } from '../reducers/orgInfo/orgInfoReducer';

const OrgInfoStateContext = createContext<OrgInfoState | undefined>(undefined);
const OrgInfoDispatchContext = createContext<Dispatch<OrgInfoAction> | undefined>(undefined);

export const OrgInfoProvider = ({ children, initialState }: { children: ReactNode; initialState: OrgInfoState }) => {
  if (!initialState.organization) throw new Error('OrgInfoProvider must be initialized with an organization');

  const [state, dispatch] = useReducer(OrgInfoReducer, initialState);
  return (
    <OrgInfoStateContext.Provider value={state}>
      <OrgInfoDispatchContext.Provider value={dispatch}>{children}</OrgInfoDispatchContext.Provider>
    </OrgInfoStateContext.Provider>
  );
};

export const useOrgInfoState = () => {
  const context = useContext(OrgInfoStateContext);
  if (context === undefined) throw new Error('useOrgInfoState must be used within an OrgInfoProvider');

  return context;
};

export const useOrgInfoDispatch = () => {
  const context = useContext(OrgInfoDispatchContext);
  if (context === undefined) throw new Error('useOrgInfoDispatch must be used within an OrgInfoProvider');

  return context;
};

import { PlusCircleIcon, MinusCircleIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import AdjustableLoadingIcon from '../../baseComponents/AdjustableLoadingIcon';
import 'tippy.js/themes/light.css';

import Tippy from '@tippyjs/react';

interface FeedbackSentenceClusterProps {
  id: number;
  title: string;
  uniqueEntries?: number;
  addChild?: (groupdId: number) => Promise<void> | undefined | void;
  deleteChild?: (groupdId: number) => Promise<void> | undefined | void;
  loading?: boolean;
  sentenceHasBeenAdded?: boolean;
  type?: string;
}

const GroupTaxonomyEntry = ({ id, title, addChild, deleteChild, type, uniqueEntries }: FeedbackSentenceClusterProps): JSX.Element => {
  const [expanded, setExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  return (
    <div className="group-sentence flex flex-row items-center justify-between gap-x-2 border-b-2 border-gray-200 ">
      <div
        className="col-span-9 flex min-h-[48px] w-full flex-row items-center justify-start rounded py-0.5 px-0.5 duration-100 transition hover:cursor-pointer hover:bg-gray-100"
        onClick={(e) => {
          const selection = document.getSelection();
          if (selection?.toString()?.length && selection.toString.length >= 1) return;
          setExpanded(!expanded);
        }}
      >
        <div className="flex w-full flex-row items-center justify-between gap-x-2 overflow-hidden">
          <div id="entry-sentence-text" className={`${addChild ? 'w-11/12' : 'w-12/12 flex-1'} `}>
            <p className="lg:text-md inline text-sm font-bold break-words">{title}</p>
            {!expanded ? null : (
              <p className="text-xs">
                <span className=" font-bold">Number of entries:</span> {uniqueEntries}
              </p>
            )}
          </div>
          {addChild ? (
            <div className={`flex items-center justify-center overflow-hidden group-sentence-hover:opacity-100 opacity-0 transition-all duration-150`}>
              {isLoading ? (
                <div data-testid="sentence-add-loading">
                  <AdjustableLoadingIcon width={6} height={6} />
                </div>
              ) : (
                <Tippy theme="dark" delay={200} content={<p className="text-center">Add this {type ?? 'sentence'} to the group.</p>}>
                  <PlusCircleIcon
                    className="h-6 w-6"
                    id="add-group-child"
                    onClick={async (e) => {
                      e.stopPropagation();
                      setIsLoading(true);
                      await addChild(id);
                    }}
                  />
                </Tippy>
              )}
            </div>
          ) : null}
          {deleteChild ? (
            <div className={`flex items-center justify-center overflow-hidden group-sentence-hover:opacity-100 opacity-0 duration-150`}>
              {isLoading ? (
                <div data-testid="sentence-add-loading">
                  <AdjustableLoadingIcon width={6} height={6} />
                </div>
              ) : (
                <Tippy theme="dark" delay={200} content={<p className="text-center">Remove this {type ?? 'sentence'} from the group.</p>}>
                  <MinusCircleIcon
                    className="h-6 w-6"
                    id="delete-group-child"
                    onClick={async (e) => {
                      e.stopPropagation();
                      setIsLoading(true);
                      await deleteChild(id);
                    }}
                  />
                </Tippy>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default GroupTaxonomyEntry;

import Tippy from '@tippyjs/react';

export function NewBadge({ isNew }: { isNew?: boolean | null }) {
  if (!isNew) return <></>;

  return (
    <div data-testid="new-badge" className="rounded-md py-0.5 px-1.5 bg-blueberry border text-white text-xs font-semibold flex justify-center items-center">
      <Tippy theme="dark" delay={200} content={<p className="text-center">This group has been created recently. </p>}>
        <div>New</div>
      </Tippy>
    </div>
  );
}

import _, { Dictionary } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import {
  Conversation_Part,
  EntryFragment,
  Feedback_Integration_Type,
  Integration_Category,
  useGenerateGptRepliesLazyQuery,
  useIntegrationsQuery,
  useReplyToReviewsMutation
} from '../../generated/graphql';
import AppContext from '../../v2/contexts/AppContext';
import { writeToastError } from '../../v2/hooks/GroupHook';
import { FeedbackHook } from './FeedbackHook';
import { FilterHook } from './FilterHook';

export const replyMap: Record<string, string> = {
  'google play': 'gplay',
  'apple app store': 'appstore',
};

export class FeedbackReplyData {
  feedbackEntryId: string;
  replyConversationPart: Conversation_Part | null;
  success: boolean;
  errorMessage: string | null;
  constructor(feedbackEntryId: string, replyConversationPart: Conversation_Part | null, success: boolean, errorMessage: string | null) {
    this.feedbackEntryId = feedbackEntryId;
    this.replyConversationPart = replyConversationPart;
    this.success = success;
    this.errorMessage = errorMessage;
  }
}

export interface ReplyHook {
  existingReplies: Dictionary<FeedbackReplyData>;
  replyTextsToSend: Dictionary<string>;
  validIntegrations: string[];
  handleCopyReplies: (text: string) => void;
  handleGenerateReplies: (text: string) => void;
  handleGenerateReply: (text: string, entry: EntryFragment) => Promise<void>;
  generatingGeneralReplies: boolean;
  sendIndividualReply: (entryId: string, cb?: () => void) => Promise<void>;
  sendAllReplies: (cb?: () => void) => Promise<void>;
  updateReplyTextToSend: (entryId: string, text: string) => void;
}

export const useReplyHook = ({
  filterHook,
  feedbackHook,
  initialReply,
}: {
  filterHook?: FilterHook;
  feedbackHook?: FeedbackHook;
  initialReply?: EntryFragment;
}): ReplyHook => {
  const [generateGPT] = useGenerateGptRepliesLazyQuery({ fetchPolicy: 'no-cache' });
  //Why are we not using useValidTeamAppContext here? Need to check and change if necessary...
  const { curTeamId: teamId, curOrgId: orgId } = useContext(AppContext);
  const { data } = useIntegrationsQuery({
    fetchPolicy: 'no-cache',
    variables: { teamId: teamId ?? -1, orgId: orgId ?? -1, feedbackIntegrationType: Feedback_Integration_Type.DataImport },
  });

  const validRepliers =
    data?.integrations
      ?.filter((integ) => {
        // Find integrations with at least one "Reply" requirement
        const hasReplyRequirement = integ.requirements.some((r) => r.category === Integration_Category.Reply);
        if (!hasReplyRequirement) return false; // Skip integrations without any "Reply" requirement

        // For those with "Reply" requirement, check if all requirements are met
        return integ.requirements.filter((r) => r.category === Integration_Category.Reply).every((r) => !r.required || (r.required && r.value.length > 0));
      })
      .map((integ) => integ.title.toLowerCase())
      .map((title) => (replyMap[title] as string) ?? title) ?? [];

  //existingReplies {[entryId]: FeedbackReply} is a dictionary that contains either successful or failed replies to entries.
  //To check if a user should still be able to reply to an entry, we need to check if the reply contains a conversation part.
  const [existingReplies, setExistingReplies] = useState<Dictionary<FeedbackReplyData>>(getInitialRepliesDict({ feedbackHook, initialReply }));

  //replyTextsToSend {[entryId]: string} is a dictionary that contains the text that will be sent for each entry.
  const [replyTextsToSend, setReplyTextsToSend] = useState<Dictionary<string>>({});

  const [generatingGeneralReplies, setGeneratingGeneralReplies] = useState(false);
  const [replyToReviews] = useReplyToReviewsMutation();

  useEffect(() => {
    if (feedbackHook?.entries) {
      const newReplies = getInitialRepliesDict({ feedbackHook });
      if (_.isEqual(existingReplies, newReplies) === false) setExistingReplies({ ...newReplies });
    }
  }, [filterHook?.filters, feedbackHook?.entries]);

  const sendIndividualReply = async (entryId: string, cb?: () => void) => {
    if (!teamId) return;
    const replyTextToSend = replyTextsToSend[entryId];
    if (!replyTextToSend) return;
    await replyToReviews({
      variables: { replyInput: [{ entryId, text: replyTextToSend }], teamId },
      onCompleted(data) {
        const reply = data?.replyToReviews[0];
        if (reply)
          setExistingReplies({
            ...existingReplies,
            [entryId]: new FeedbackReplyData(entryId, reply.conversationPart ?? null, reply.success ?? false, reply.errorMessage ?? ''),
          });
      },
      onError: () =>
        setExistingReplies({
          ...existingReplies,
          [entryId]: new FeedbackReplyData(entryId, null, false, 'Unknown error. Please try again or contact support.'),
        }),
    });
    cb?.();
  };

  const sendAllReplies = async (cb?: () => void) => {
    if (!teamId) return;
    await replyToReviews({
      variables: { replyInput: Object.entries(replyTextsToSend).map(([feedbackEntryId, text]) => ({ entryId: feedbackEntryId, text })), teamId },
      onCompleted(data) {
        if (data?.replyToReviews) {
          const newReplies = data.replyToReviews.map((reply) => {
            return new FeedbackReplyData(reply.feedbackEntryId, reply.conversationPart ?? null, reply.success ?? false, reply.errorMessage ?? '');
          });
          setExistingReplies({ ...existingReplies, ...newReplies.reduce((acc, reply) => ({ ...acc, [reply.feedbackEntryId]: reply }), {}) });
        }
      },
      onError(error) {
        writeToastError('Unknown error. Please try again or contact support.');
      },
    });
    cb?.();
  };

  const generateReplyText = async (text: string, entry: EntryFragment) => {
    const { data, error } = await generateGPT({
      variables: { teamId: teamId!, gptRepliesInput: [{ entryId: entry.id, feedbackText: entry.text ?? '', details: text ?? '' }] },
    });
    const reply = data?.generateGptReplies?.[0];
    if (!reply || error || !reply.generatedText) return 'There was an error generating this reply.';
    return reply.generatedText;
  };

  const handleGenerateReply = async (text: string, entry: EntryFragment) => {
    if (!teamId) return;
    const generatedText = await generateReplyText(text, entry);
    setReplyTextsToSend({ ...replyTextsToSend, [entry.id]: generatedText });
  };

  const handleCopyReplies = (text: string) => {
    if (!teamId || !feedbackHook) return;
    const newReplyTextsToSend = { ...replyTextsToSend };
    feedbackHook.entries
      .filter((entry) => !replyDataContainsSuccesfulReply(existingReplies[entry.id] ?? {}))
      .forEach((entry) => {
        newReplyTextsToSend[entry.id] = text;
      });
    setReplyTextsToSend(newReplyTextsToSend);
  };

  const handleGenerateReplies = async (text: string) => {
    setGeneratingGeneralReplies(true);
    if (!teamId || !feedbackHook) return;

    const newReplyTextsToSend = { ...replyTextsToSend };
    //Using only the entries that don't have a reply yet.
    const promises = feedbackHook.entries
      .filter((entry) => !replyDataContainsSuccesfulReply(existingReplies[entry.id] ?? {}))
      .map(async (entry) => {
        const generatedText = await generateReplyText(text, entry);
        newReplyTextsToSend[entry.id] = generatedText;
        setReplyTextsToSend(newReplyTextsToSend);
      });
    await Promise.allSettled(promises);
    setGeneratingGeneralReplies(false);
  };

  const updateReplyTextToSend = (entryId: string, text: string) => {
    setReplyTextsToSend({ ...replyTextsToSend, [entryId]: text });
  };

  return {
    existingReplies,
    replyTextsToSend,
    validIntegrations: validRepliers,
    handleCopyReplies,
    handleGenerateReplies,
    handleGenerateReply,
    generatingGeneralReplies,
    sendIndividualReply,
    sendAllReplies,
    updateReplyTextToSend,
  };
};

const getInitialRepliesDict = ({ feedbackHook, initialReply }: { feedbackHook?: FeedbackHook; initialReply?: EntryFragment }) => {
  const initialReplies: Dictionary<FeedbackReplyData> = {};

  if (feedbackHook) {
    feedbackHook.entries.forEach((entry) => {
      entry.feedbackEntryText.conversationParts.forEach((convPart) => {
        if (isReplyPart(convPart.providerUniqueId)) {
          initialReplies[entry.id] = new FeedbackReplyData(entry.id, convPart, true, '');
        }
      });
    });
  } else if (initialReply) {
    initialReply.feedbackEntryText.conversationParts.forEach((part) => {
      if (isReplyPart(part.providerUniqueId)) {
        initialReplies[initialReply.id] = new FeedbackReplyData(initialReply.id, part, true, '');
      }
    });
  }

  return initialReplies;
};

export const isReplyPart = (providerUniqueId?: string | null): boolean => {
  if (!providerUniqueId) return false;
  return providerUniqueId.endsWith('-unwrapreply') || providerUniqueId.endsWith('-existingreply');
};

export const replyDataContainsSuccesfulReply = (reply: FeedbackReplyData): boolean => {
  //FeedbackReply can return errors, so the presence of an entry is not enough to determine if it's a reply.
  //Here we check if that reply contains a conversation part.
  return !!reply.replyConversationPart;
};

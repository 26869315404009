import { useState } from 'react';
import Form from '../../../baseComponents/Form';
import Button, { ButtonShape, ButtonVariant } from '../../baseComponents/Button';
import Modal from '../../baseComponents/Modal';
import { useCreateBoardMutation } from '../../../generated/graphql';
import { useValidTeamAppContext } from '../../../v2/contexts/AppContext';
import { useBoardsListDispatch } from '../../../context/boardsListContext';
import { BoardsListActionTypes } from '../../../reducers/boards/boardsListReducer';
import toast from 'react-hot-toast';

export const CreateBoardModal = ({ modalOpen, setModalOpen }: { modalOpen: boolean; setModalOpen: (open: boolean) => void }) => {
  const { curTeamId: teamId } = useValidTeamAppContext();
  const dispatch = useBoardsListDispatch();
  const [boardTitle, setBoardTitle] = useState('');
  const [boardDescription, setBoardDescription] = useState('');

  const [createBoard, createBoardRes] = useCreateBoardMutation();
  async function handleCreateBoard() {
    await createBoard({
      variables: {
        teamId,
        title: boardTitle,
        description: boardDescription,
      },
      onCompleted: (data) => {
        dispatch({ type: BoardsListActionTypes.AddBoard, payload: { board: data.createBoard } });
        toast.success('Board created successfully');
        setModalOpen(false);
      },
      onError: (error) => {
        toast.error('Failed to create board');
      },
    });
  }

  const getFormButtons = () => {
    return (
      <div className="mt-8 grid grid-cols-3 justify-end gap-x-4 text-center">
        <div className="col-span-1">
          <Button variant={ButtonVariant.Tertiary} shape={ButtonShape.Pill} onClick={() => setModalOpen(false)} text="Cancel" expandWidth></Button>
        </div>
        <div className="col-span-2">
          <Button
            submit
            disabled={boardTitle === ''}
            variant={ButtonVariant.Primary}
            shape={ButtonShape.Pill}
            text="Create board"
            onClick={() => {
              handleCreateBoard();
            }}
            loadingConfirm={createBoardRes.loading}
            expandWidth
          ></Button>
        </div>
      </div>
    );
  };

  return (
    <Modal open={modalOpen} setOpen={setModalOpen}>
      <div className="px-6" data-testid="filter-modal">
        <Form bottomRow={getFormButtons()}>
          <div className="flex flex-col gap-y-4 text-blueberry">
            <div className="flex items-center justify-center">
              <h1 className="font-semibold text-2xl ">Create new board</h1>
            </div>
            <div className="flex flex-col gap-y-4 h-full">
              <div className="flex flex-col gap-y-1">
                <p>Title</p>
                <input
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 focus:border-blueberry focus:outline-none focus:ring-blueberry sm:text-sm"
                  placeholder="Board title..."
                  onChange={(e) => setBoardTitle(e.target.value)}
                />
              </div>
              <div className="flex flex-col gap-y-1">
                <p>Description</p>
                <input
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 focus:border-blueberry focus:outline-none focus:ring-blueberry sm:text-sm"
                  placeholder="Board description (optional)..."
                  onChange={(e) => setBoardDescription(e.target.value)}
                />
              </div>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

import { useContext } from 'react';
import UserContext from '../../v2/contexts/UserContext';

/**
 * EmployeeComponent conditionally renders its children based on whether the current user
 * is currently an employee
 *
 * @param {JSX.Element} children The components to render conditionally.
 * Defaults to `true`.
 * @returns {JSX.Element | null} Returns the children if conditions are met, otherwise null.
 */
const EmployeeOnlyComponent = ({ children }: { children: JSX.Element }) => {
  const { user } = useContext(UserContext);
  if (user?.isUnwrapper) {
    return children;
  }
  return null;
};
export default EmployeeOnlyComponent;

import { PageWrapper } from './PageWrapper';
import { AssistantDataContext, AssistantDispatchContext, defaultAssistantState } from '../../context/assistantContext';
import React, { useContext, useReducer } from 'react';
import { AssistantReducer } from '../../reducers/assistant/AssistantDispatch';
import UserContext from '../../v2/contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import { FeedbackAssistant } from '../components/assistant/FeedbackAssistant';
import { SuggestedQuestionsReducer } from '../../reducers/assistant/SuggestedQuestionsDispatch';
import {
  defaultSuggestedQuestionsState, SuggestedQuestionsDataContext,
  SuggestedQuestionsDispatchContext,
} from '../../context/suggestedQuestionsContext';

export const FeedbackAssistantPage = () => {
  const [state, dispatch] = useReducer(AssistantReducer, defaultAssistantState);
  const [suggestedQuestionsState, suggestedQuestionsDispatch] = useReducer(SuggestedQuestionsReducer, defaultSuggestedQuestionsState);

  return (
    <AssistantDataContext.Provider value={state}>
      <AssistantDispatchContext.Provider value={dispatch}>
        <SuggestedQuestionsDataContext.Provider value={suggestedQuestionsState}>
          <SuggestedQuestionsDispatchContext.Provider value={suggestedQuestionsDispatch}>
            <PageWrapper title={'Assistant'} beta={true}>
              <FeedbackAssistant/>
            </PageWrapper>
          </SuggestedQuestionsDispatchContext.Provider>
        </SuggestedQuestionsDataContext.Provider>
      </AssistantDispatchContext.Provider>
    </AssistantDataContext.Provider>
  );
};

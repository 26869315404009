import { EntryFragment } from '../../../generated/graphql';
import { IDropDownItem } from '../../baseComponents/DropDown';
import FeedbackEntryCard from '../FeedbackEntryCard';
import { ReplyHook } from '../../hooks/ReplyHook';
import 'tippy.js/themes/light.css';
import { RepliableSection } from './RepliableSection';

interface IReplyBox {
  entry: EntryFragment;
  replyHook: ReplyHook;
  selectedTemplateItem?: IDropDownItem;
}

export const FeedbackAndReplyRow = ({ entry, replyHook, selectedTemplateItem }: IReplyBox) => {
  return (
    <div className="grid grid-cols-10 gap-x-6 items-stretch">
      <div className="col-span-5 w-full ">
        <FeedbackEntryCard entry={entry} showConversation={false} />
      </div>
      <div className="col-span-5 w-full">
        <RepliableSection
          entry={entry}
          replyHook={replyHook}
          existingReplyData={replyHook.existingReplies[entry.id]}
          currentReplyTextToSend={replyHook.replyTextsToSend[entry.id]}
          selectedTemplateItem={selectedTemplateItem}
        />
      </div>
    </div>
  );
};

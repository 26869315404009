import toast from 'react-hot-toast';
import { Integration_Type, Integration_Type_Requirement } from '../../../../../../generated/graphql';
import { CheckIcon, ExclamationCircleIcon } from '@heroicons/react/24/solid';

export const allRequirementsHaveValue = (requirements: Integration_Type_Requirement[]): boolean => {
  requirements.forEach((requirement) => {
    if (requirement.required && requirement.value.length === 0) return false;
  });
  return true;
};

export const getSuccessOrErrorIcon = (success: boolean, error: string | undefined | null): JSX.Element => {
  if (success) {
    return (
      <div className="flex items-center">
        <CheckIcon className="h-8 w-8" color="green" />
      </div>
    );
  }
  if (error) return <ExclamationCircleIcon className="h-8 w-8" color="red" />;
  return <div></div>;
};

export const getSuccessOrError = (integration: Integration_Type, success: boolean, error: string | undefined | null): JSX.Element => {
  if (success) {
    return (
      <p className="mt-2 text-sm text-green-600">
        Connection to {integration.title} validated. Click <b>Submit</b> to complete the integration
        <br />
      </p>
    );
  }
  if (error) return <p className="mt-2 text-sm text-red-600">{error}</p>;
  return <p className="mt-2 text-sm"> </p>;
};

export const integrationsZendeskRedirect = ({
  requirements,
  teamId,
  integration,
}: {
  requirements: Integration_Type_Requirement[];
  teamId: number;
  integration: Integration_Type;
}) => {
  const zendeskURL = requirements
    .find((req) => req.key === 'url_zendesk')
    ?.value.find((v) => v.requirement.key === 'url_zendesk')
    ?.value.replace(/^https?:\/\//, '');

  if (!zendeskURL || !new RegExp('.+.zendesk.com$').test(zendeskURL)) {
    return toast.error("Invalid Zendesk URL - make sure you've entered a valid URL with the format mycompany.zendesk.com");
  }
  const redirect = `${integration.authRedirect?.replace('replace-with-subdomain', zendeskURL.replace('.zendesk.com', ''))}&state=${encodeURIComponent(
    JSON.stringify({
      teamId: teamId,
      zendeskURL: zendeskURL,
      redirect: window.location.href,
    })
  )}`;
  window.location.replace(redirect);
};

export const integrationsOAuthRedirect = ({
  requirements,
  teamId,
  integration,
}: {
  requirements: Integration_Type_Requirement[];
  teamId: number;
  integration: Integration_Type;
}) => {
  if (integration.title.includes('Zendesk')) {
    // special case for zendesk
    integrationsZendeskRedirect({ integration, requirements, teamId });
  } else {
    // general oAuth redirect
    // get redirect url from integration
    // swap in requirement values necessary for the url to be complete.
    let url = integration.authRedirect!;
    const requirementsForAuthRedirect = requirements.filter((req) => req.requiredForAuthRedirect);
    const stateProps: Record<string, string | number> = { redirect: window.location.href, teamId: teamId, scraperKey: integration.scraperKey };
    requirementsForAuthRedirect.map((req) => {
      if (!req.value || req.value.length === 0) {
        throw new Error(`Cannot complete redirect missing value for ${req.title}.`);
      }
      if (!req.frontendReplacementKey) {
        throw new Error(`Requirement not specified with a replacement key ${req.title}`);
      }
      url = url.replace(req.frontendReplacementKey, req.value[0].value);
      stateProps[req.key] = req.value[0].value;
    });
    const redirect = `${url}&state=${JSON.stringify(stateProps)}`;
    window.location.replace(redirect);
  }
};

/**
 * Get the requirements in shape to send to the backend, throwing an error on missing required values.
 * @param requirements
 * */
export const getIntegrationRequirementsToSend = (requirements: Integration_Type_Requirement[]): { id: number; key: string; value: string }[] => {
  return requirements
    .filter((req) => req.required || !!req.value?.[0]?.value)
    .map((requirement) => {
      if (requirement.value.length < 1) {
        throw new Error('Need an input for ' + requirement.title);
      }
      return {
        id: requirement.id,
        key: requirement.key,
        value: requirement.value[0].value,
      };
    });
};

import { useState, useEffect, useContext } from 'react';
import 'tippy.js/themes/light.css';
import {
  FilterInput,
  GroupDependencies,
  useTeamGroupsTaxonomyTreeLazyQuery,
  useTeamGroupsTaxonomyFlatLazyQuery,
} from '../../generated/graphql';
import LoadingSpinner from '../../v2/components/LoadingSpinner';
import { TaxonomyGroup, GroupBase, GroupFull } from '../../v2/hooks/GroupHook';
import { useValidTeamAppContext } from '../../v2/contexts/AppContext';
import AnnouncementModal from './Modals/AnnouncementModal';
import { GroupDeleter } from './GroupDeleter';
import { useInView } from 'react-intersection-observer';
import { TaxonomyFolder } from './TaxonomyFolder';
import { useNavigate } from 'react-router-dom';
import { TaxonomyContext } from '../context/TaxonomyContext';
import { TaxonomyDispatchContext } from '../context/TaxonomyDispatchContext';
import { pageSize } from '../pages/explore/ExplorePageRouter';
import { TaxonomyAction } from '../actions/taxonomy';
import { hasGroupLevelFilter } from '../sections/Filters/FiltersUtil';

interface TaxonomyViewProps {
  flatView?: boolean;
  currentGroup: GroupBase | GroupFull | undefined;
  filterInput: FilterInput;
  loadingStatuses?: any;
  setCurrentGroup: (group: GroupBase | GroupFull | undefined) => void;
  discardSearchGroup: (groupId: string) => void;
  togglePinGroup: (groupId: string, cb?: () => void) => void;
  copyGroupLink: (groupId: string) => void;
  openAnnouncementModal: (d: number) => void;
  assignChildren: (teamId: number, filterInput: FilterInput, parentGroupId: string, childGroupIds: string[], cb?: () => void) => Promise<void>;
  assignChild: (
    teamId: number,
    filterInput: FilterInput,
    parentGroupId: string,
    childGroupId: string,
    type?: 'Parent' | 'Child',
    cb?: () => void
  ) => Promise<void>;
  pageName: string;
  discardGroup: (groupId: string, cb?: () => void) => Promise<GroupDependencies | void>;
  updateProgress: (searchGroupId: string, newProgress: number) => void;
  replaceOrAddToSearchGroups: (searchGroup: any) => void;
}

export const TaxonomyView = ({
  flatView,
  filterInput,
  discardGroup,
  loadingStatuses,
  setCurrentGroup,
  togglePinGroup,
  currentGroup,
  copyGroupLink,
  updateProgress,
  replaceOrAddToSearchGroups,
}: TaxonomyViewProps) => {
  const navigate = useNavigate();
  const { ref, inView } = useInView({ threshold: 0 });
  const { curTeamId: teamId, curOrgId: orgId, currentOrg } = useValidTeamAppContext();
  const [curGroupIdToDelete, setCurGroupIdToDelete] = useState<string | null>(null);
  const [announcementId, setAnnouncementId] = useState<string | undefined>();
  const [activeId, setActiveId] = useState<string | undefined>(undefined);

  const taxonomy = useContext(TaxonomyContext);
  const dispatch = useContext(TaxonomyDispatchContext);

  const [skip, setSkip] = useState(pageSize); // There will already be {pageSize} taxonomy groups loaded on page load

  useEffect(() => {
    if (!currentGroup?.id) {
      setCurrentGroup(undefined);
    }
  }, [currentGroup?.id]);

  const [getTaxonomyTree, { loading: loadingTree }] = useTeamGroupsTaxonomyTreeLazyQuery({
    variables: { teamId, filterInput: filterInput ?? {}, take: 10, skip: 0 },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      const action: TaxonomyAction = {
        type: 'updateTaxonomy',
        payload: data,
      };
      dispatch(action);
    },
    onError(error) {
      dispatch({ type: 'error', payload: { error } });
    },
  });

  const [getTaxonomyFlat, { loading: loadingFlat }] = useTeamGroupsTaxonomyFlatLazyQuery({
    variables: { teamId, filterInput: filterInput ?? {}, take: 10, skip: 0 },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      const action: TaxonomyAction = {
        type: 'updateFlatTaxonomy',
        payload: data,
      };
      dispatch(action);
    },
    onError(error) {
      dispatch({ type: 'error', payload: { error } });
    },
  });

  useEffect(() => {
    if (inView && !loadingTree && !loadingFlat) {
      if (flatView) {
        getTaxonomyFlat({
          variables: { teamId, filterInput: filterInput ?? {}, take: pageSize, skip },
        });
      } else {
        getTaxonomyTree({
          variables: { teamId, filterInput: filterInput ?? {}, take: pageSize, skip },
        });
      }
      setSkip(skip + pageSize);
    }
  }, [inView, teamId, flatView]);

  const sortTaxonomyGroups = (a: TaxonomyGroup, b: TaxonomyGroup) => {
    // Processed items first
    if (a.processing && !b.processing) return -1;
    if (!a.processing && b.processing) return 1;

    // Then sort by totalEntries
    return b.totalEntries - a.totalEntries;
  };
  const topLevelGroups = Array.from(taxonomy.values())
    .filter((t: TaxonomyGroup) => t.parentId == undefined)
    .sort((a: TaxonomyGroup, b: TaxonomyGroup) => sortTaxonomyGroups(a, b));

  const renderGroup = (group: TaxonomyGroup, show: boolean, depth: number) => {
    const childItems =
      group.children
        ?.map((child) => child.id)
        .map((id) => taxonomy.get(id) ?? null)
        .filter((c) => c)
        .sort((a, b) => sortTaxonomyGroups(a as TaxonomyGroup, b as TaxonomyGroup))
        .map((c) => renderGroup(c as TaxonomyGroup, false, depth + 1)) ?? [];
    return (
      <TaxonomyFolder
        flatGroup={false}
        trending={group.trending}
        replaceOrAddToSearchGroups={replaceOrAddToSearchGroups}
        updateProgress={updateProgress}
        taxonomyGroup={taxonomy.get(group.id)!}
        itemRef={show ? ref : null}
        key={group.id}
        children={childItems}
        copyLink={copyGroupLink}
        teamId={teamId}
        setActiveId={(id) => setActiveId(id)}
        togglePinGroup={togglePinGroup}
        setCurrentGroupId={(id) => setCurGroupIdToDelete(id)}
        zIndex={activeId === group.id ? 'z-20' : 'z-0'}
        filterInput={filterInput ?? {}}
        depth={depth}
      />
    );
  };

  const renderFlatGroup = () => {
    const show: number = Array.from(taxonomy.values()).length - 2;

    const groups = Array.from(taxonomy.values()).map((group: TaxonomyGroup, index: number) => {
      return (
          <TaxonomyFolder
            flatGroup={true}
            trending={group.trending}
            replaceOrAddToSearchGroups={replaceOrAddToSearchGroups}
            updateProgress={updateProgress}
            taxonomyGroup={group}
            itemRef={show === index ? ref : null}
            key={group.id}
            children={[]}
            copyLink={copyGroupLink}
            teamId={teamId}
            setActiveId={(id) => {
              setActiveId(id);
            }}
            togglePinGroup={togglePinGroup}
            setCurrentGroupId={(id) => {
              setCurGroupIdToDelete(id);
            }}
            zIndex={activeId === group.id ? 'z-20' : 'z-0'}
            filterInput={filterInput}
            depth={0}
          />
      );
    });
    return <>{groups}</>;
  };

  return (
    <>
      {announcementId != null && (
        <AnnouncementModal
          groupId={announcementId}
          modalOpen={true}
          setModalOpen={() => setAnnouncementId(undefined)}
          teamId={teamId}
          orgId={orgId}
          orgName={currentOrg.name}
          filterInput={filterInput ?? {}}
        />
      )}

      {curGroupIdToDelete !== null ? (
        <GroupDeleter
          groupToDelete={curGroupIdToDelete}
          closeCallback={() => {
            setCurGroupIdToDelete(null);
          }}
          deleteGroup={discardGroup}
          deleteCallback={() => {
            setCurrentGroup(undefined);
            navigate('/dashboard/explore', { replace: true });
          }}
          loadingDelete={loadingStatuses.discardingGroup}
          hasChildren={taxonomy.get(curGroupIdToDelete)?.totalDescendents !== 0 ?? null}
        />
      ) : (
        <></>
      )}

      <div>
        {taxonomy.size === 0 ? (
          <p className="mt-12 flex flex-col items-center gap-y-4 pt-2">No search groups match the selected filters.</p>
        ) : flatView ? (
          renderFlatGroup()
        ) : (
          topLevelGroups.map((group, index, arr) => {
            const show: boolean = index === arr.length - 2;
            return renderGroup(group, show, 0);
          })
        )}
        {loadingTree || loadingFlat ? (
          <div className="flex justify-center items-center w-full my-4">
            <div className="h-8 w-8 animate-spin rounded-full border-t-2 border-b-2 border-blueberry"></div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default TaxonomyView;

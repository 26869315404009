import { useRef } from "react";
import { Date_Window, TeamDefaultsDocument, useTeamDefaultsQuery, useUpdateTeamDefaultsMutation } from "../../../generated/graphql";
import { useValidTeamAppContext } from "../../../v2/contexts/AppContext";
import toast from "react-hot-toast";
import { HorizontalDateSelector, HorizontalDateSelectorOption } from "../HorizontalDateSelector";
import { TrashIcon } from "@heroicons/react/24/solid";
import moment from "moment";
import AdjustableLoadingIcon from "../../../baseComponents/AdjustableLoadingIcon";
import Card from "../Card";
import { DateOptions } from "../../sections/Filters/FiltersUtil";
import { DatePicker } from "../../baseComponents/DatePicker";

export const TeamDefaultsCard = () => {
  const { curTeamId: teamId } = useValidTeamAppContext();
  const { data } = useTeamDefaultsQuery({
    variables: { teamId },
  });
  const [updateTeamDefaults] = useUpdateTeamDefaultsMutation();
  const defaults = data?.teamDefaults;
  const { exploreDefaultWindow, startDate, endDate } = defaults || {};
  const dateSelectorRef = useRef<any>(null);

  const updateDefaults = ({
    exploreDefaultWindow,
    startDate,
    endDate,
  }: {
    exploreDefaultWindow?: Date_Window | null;
    startDate?: Date | null;
    endDate?: Date | null;
  }) => {
    const loadingToast = toast.loading('Updating team defaults...', { duration: 0 });
    updateTeamDefaults({
      variables: {
        teamId,
        exploreDefaultWindow,
        startDate,
        endDate,
      },
      refetchQueries: [TeamDefaultsDocument],
      onCompleted: (data) => {
        if (!data.updateTeamDefaults.exploreDefaultWindow) dateSelectorRef.current?.clearSelectedWindow();
        toast.success('Updated team defaults');
        toast.dismiss(loadingToast);
      },
      onError: (err) => {
        toast.error("Couldn't update team defaults");
        toast.dismiss(loadingToast);
      },
    });
  };

  return (
    <Card
      title="Team Defaults"
      subTitle="if you specify a Start AND End date, the window will be ignored. Dates are specified if their badge is blue."
      width="max"
    >
      {defaults ? (
        <div className="flex flex-col px-6 gap-y-3 pb-2">
          <p className="underline">
            <b>Currently using:</b>{' '}
            {exploreDefaultWindow !== null && (!startDate || !endDate) ? 'Window' : !!startDate && !!endDate ? 'Start & End Dates' : 'None'}
          </p>
          <div className="flex flex-row gap-x-2 items-center">
            <p>Window:</p>
            <HorizontalDateSelector
              onChooseDate={(newStartDate: Date | undefined, newEndDate: Date | undefined, option: HorizontalDateSelectorOption | undefined) => {
                updateDefaults({ exploreDefaultWindow: option?.date_window, startDate: startDate, endDate: endDate });
              }}
              options={DateOptions}
              ref={dateSelectorRef}
              defaultSelect={exploreDefaultWindow ? DateOptions.find((option) => option.date_window === exploreDefaultWindow) : undefined}
            />
            <TrashIcon
              className="h-5 w-5 text-red-400 cursor-pointer"
              onClick={() => {
                updateDefaults({ exploreDefaultWindow: null, startDate: startDate, endDate: endDate });
              }}
            />
          </div>
          <div className="flex flex-row gap-x-2 items-center">
            <p>Start Date:</p>
            <DatePicker
              date={startDate ? moment(startDate).toDate() : new Date()}
              onChange={(dt: Date) => {
                updateDefaults({ exploreDefaultWindow, startDate: dt, endDate: moment(endDate).endOf('day').toDate() });
              }}
            />
            <TrashIcon
              className="h-5 w-5 text-red-400 cursor-pointer"
              onClick={() => {
                updateDefaults({ exploreDefaultWindow, startDate: null, endDate: null });
              }}
            />
          </div>
          <div className="flex flex-row gap-x-2 items-center">
            <p>End Date:</p>
            <DatePicker
              date={endDate ? moment(endDate).toDate() : new Date()}
              onChange={(dt: Date) => {
                updateDefaults({
                  exploreDefaultWindow,
                  startDate: startDate,
                  endDate: moment(dt).endOf('day').toDate(),
                });
              }}
            />
            <TrashIcon
              className="h-5 w-5 text-red-400 cursor-pointer"
              onClick={() => {
                updateDefaults({ exploreDefaultWindow, startDate: null, endDate: null });
              }}
            />
          </div>
        </div>
      ) : (
        <AdjustableLoadingIcon width={5} height={5} />
      )}
    </Card>
  );
};

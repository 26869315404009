import Tippy from '@tippyjs/react';
import { classNames } from '../../../../../v2/util';
import AdjustableLoadingIcon from '../../../../../baseComponents/AdjustableLoadingIcon';

type ToolbarButtonProps = {
  id: string;
  icon: JSX.Element;
  label?: string;
  loading?: boolean;
  onClick?: (props: any) => void;
  buttonRounding?: 'left' | 'right' | 'full';
  disabled?: boolean;
  disabledTooltip?: string;
  dataCy?: string;
};

export const ToolbarBaseButton = ({ id, icon, label, loading, onClick, buttonRounding, disabled, disabledTooltip, dataCy }: ToolbarButtonProps) => {
  disabledTooltip = disabledTooltip ?? 'Insufficient permissions';
  return (
    <Tippy content={disabled ? disabledTooltip : label}  delay={0} duration={[400, 100]}>
      <div
        id={id}
        data-cy={dataCy}
        role="button"
        className={classNames(
          buttonRounding === 'left' ? 'rounded-l-xl' : '',
          buttonRounding === 'right' ? 'rounded-r-xl' : '',
          buttonRounding === 'full' ? 'rounded-full' : '',
          `bg-silver text-blueberry flex justify-center items-center h-8 w-10 hover:bg-silver-darker duration-150 hover:shadow-sm focus:outline-none ${disabled ? 'hover:cursor-not-allowed' : 'cursor-pointer'}` 
        )}
        onClick={disabled ? undefined : onClick}
      >
        {loading ? <AdjustableLoadingIcon width={4} height={4} color="white" /> : icon}
      </div>
    </Tippy>
  );
};

import { useContext } from "react"
import { PermissionsContext } from "../../../v2/contexts/PermissionsContext"
import Button, { ButtonSize, ButtonVariant } from "../../baseComponents/Button";
import { Action, Resource } from "../../../generated/graphql";
export const CreateAlertsButton = (props: {onClick: () => void, loadingDataForFilters: boolean}) => {

    const {hasPermission} = useContext(PermissionsContext);
    const canCreateAlerts = hasPermission(Resource.Alerts, Action.Create);
    let disabledMessage = '';

    if (props.loadingDataForFilters) {
        disabledMessage = 'Loading team data...'
    }    
    if (!canCreateAlerts) {
        disabledMessage = 'You do not have permissions to create an alert.'
    }
    const enabled = !props.loadingDataForFilters && canCreateAlerts;
    return <Button
        disabled={!enabled}
        disabledTooltip={disabledMessage}
        onClick={props.onClick}
        text={'Create New Alarm +'}
        variant={ButtonVariant.Secondary}
        size={ButtonSize.Small}
    />
};
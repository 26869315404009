import { useMemo, useRef, useState } from 'react';
import { Combobox } from '@headlessui/react';
import classNames from 'classnames';
import { useVirtualizer } from '@tanstack/react-virtual';
import { ChevronDownIcon, ChevronUpDownIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { IDropDownItem } from '../baseComponents/DropDown';
import AdjustableLoadingIcon from '../../baseComponents/AdjustableLoadingIcon';

interface VirtualizedComboBoxProps {
  id?: string;
  comboBoxData: IDropDownItem[];
  selectedItem?: IDropDownItem;
  setSelectedItem: (item: IDropDownItem | undefined) => void;
  useDisplayName?: boolean;
  placeholder?: string;
  defaultOption?: boolean;
  disableClear?: boolean;
  disableAlphabeticalSort?: boolean;
  loading?: boolean;
  disabled?: boolean;
  grayStyle?: boolean; //janky
  onClear?: () => void;
}

export function VirtualizedComboBox({
  id,
  comboBoxData,
  selectedItem,
  setSelectedItem,
  placeholder,
  useDisplayName,
  defaultOption,
  disableClear,
  disableAlphabeticalSort,
  loading,
  disabled,
  grayStyle,
  onClear,
}: VirtualizedComboBoxProps) {
  const [query, setQuery] = useState('');

  function checkAndClearInput() {
    if (query !== '' && filteredItems.length == 0 && !defaultOption) {
      setQuery('');
    }
  }
  const defaultValue = { id: -1, displayName: query, name: query };

  const filteredItems = useMemo(() => {
    const items =
      query === ''
        ? comboBoxData
        : comboBoxData.filter((data) => {
            return data.name ? data.name.toLowerCase().includes(query.toLowerCase()) : data.title?.toLowerCase().includes(query.toLowerCase());
          });

    if (disableAlphabeticalSort) return items;

    return items.sort((a, b) => {
      const nameA = a.name || a.title || '';
      const nameB = b.name || b.title || '';
      return nameA.localeCompare(nameB);
    });
  }, [comboBoxData, query, disableAlphabeticalSort]);

  return (
    <Combobox
      id={id}
      data-testid={id}
      className={'h-full group focus:border-0 outline-none focus:outline-none focus:ring-0'}
      as="div"
      value={selectedItem}
      onChange={(item: IDropDownItem | undefined) => {
        if (query === '' && defaultOption) return;
        setSelectedItem(item);
        setQuery('');
      }}
      onFocus={() => checkAndClearInput()}
    >
      <div className="relative h-full">
        <div className={'relative h-full flex'}>
          <Combobox.Input
            tabIndex={disabled ? -1 : undefined}
            autoComplete="off"
            readOnly={grayStyle}
            placeholder={placeholder}
            className={classNames(
              'w-full pl-3 text-blueberry sm:text-sm',
              grayStyle
                ? 'border-0 focus:border-0 focus:outline-none focus:ring-0 font-semibold bg-silver group-hover:bg-silver-darker duration-150 rounded-3xl'
                : 'rounded-md border border-gray-300 bg-white pl-3 shadow-sm pr-1',
              !grayStyle ? (disableClear ? 'pr-1' : 'pr-14') : ''
            )}
            displayValue={(item: IDropDownItem) => {
              if (!selectedItem) return '';

              if (useDisplayName) {
                return item?.displayName ?? item?.name ?? '';
              }
              return item?.name ? item.name : item?.title ? item.title : '';
            }}
            onChange={(event) => setQuery(event.target.value)}
          />
          <div className="absolute top-0 flex h-full w-full flex-row justify-end">
            <Combobox.Button className="flex w-full"></Combobox.Button>

            <div className="flex items-center">
              {loading ? (
                <AdjustableLoadingIcon width={5} height={5} />
              ) : !disableClear && selectedItem ? (
                <XMarkIcon
                  onClick={() => {
                    if(onClear) return onClear();
                    setSelectedItem(undefined);
                    setQuery('');
                  }}
                  data-testid="clear-icon"
                  className="h-4 w-4 cursor-pointer text-blueberry"
                  aria-hidden="true"
                />
              ) : null}
              <Combobox.Button className="w-8 h-full">
                {!grayStyle ? (
                  <ChevronUpDownIcon onClick={() => setQuery('')} className="h-6 w-6 text-blueberry" aria-hidden="true" />
                ) : (
                  <ChevronDownIcon onClick={() => setQuery('')} className="h-4 w-4 text-blueberry" aria-hidden="true" />
                )}
              </Combobox.Button>
            </div>
          </div>
        </div>

        <Combobox.Options className={'text-blueberry'}>
          {filteredItems.length !== 0 ? (
            <VirtualizedList items={filteredItems} />
          ) : defaultOption && query !== '' ? (
            <div className="w-full absolute max-h-60 overflow-y-auto z-40 mt-1 rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              <Combobox.Option
                value={defaultValue}
                className={({ active }) =>
                  classNames(`duration-100 transition relative cursor-pointer select-none py-2 pl-2 pr-4  ${active ? 'bg-indigo-50' : 'text-gray-900'}`)
                }
              >
                {({ active, selected }) => (
                  <>
                    <span className={`flex flex-row line-clamp-3 items-center gap-x-2 ${selected ? 'font-medium' : 'font-normal'}`}> {query}</span>
                  </>
                )}
              </Combobox.Option>
            </div>
          ) : null}
        </Combobox.Options>
      </div>
    </Combobox>
  );
}

function VirtualizedList({ items }: { items: IDropDownItem[] }) {
  const parentRef = useRef<HTMLDivElement>(null);

  const count = items.length;
  const virtualizer = useVirtualizer({
    count,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 45,
  });

  const itemsToRender = virtualizer.getVirtualItems();

  return (
    <div className="">
      <div
        ref={parentRef}
        className={
          'w-full absolute max-h-60 overflow-y-auto z-40 mt-1 rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'
        }
      >
        <div
          style={{
            height: virtualizer.getTotalSize(),
            width: '100%',
            position: 'relative',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              transform: `translateY(${itemsToRender[0]?.start ?? 0}px)`,
            }}
            className="divide-y-2 divide-gray-100"
          >
            {itemsToRender.map((virtualRow) => (
              <div key={virtualRow.key} data-index={virtualRow.index} ref={virtualizer.measureElement}>
                <Combobox.Option
                  className={({ active }) =>
                    classNames(`duration-100 transition relative cursor-pointer select-none py-2 pl-2 pr-4 ${active ? 'bg-indigo-50' : 'text-gray-900'}`)
                  }
                  value={items?.[virtualRow.index]}
                >
                  {({ selected, active }) => (
                    <span className={`flex flex-row line-clamp-3 items-center gap-x-2 ${selected ? 'font-medium' : 'font-normal'}`}>
                      {items?.[virtualRow.index].displayName ?? items?.[virtualRow.index].name}
                    </span>
                  )}
                </Combobox.Option>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

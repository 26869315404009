import { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import logo from '../../../assets/unwrap_logo_red.svg';
import fullLogoMain2 from '../../../assets/unwrap_full_logo_2_MAIN_2.svg';
import { classNames } from '../../util';

export default function PreviewExitSurvey({
  modalOpen,
  callbackModal,
  onConfirm,
  afterSubmit,
}: {
  modalOpen: boolean;
  onConfirm: (choice: string) => void;
  callbackModal: () => void;
  afterSubmit: boolean;
}) {
  const [currentChoice, setCurrentChoice] = useState<{ id: string; name: string; writeIn: boolean }>();
  const [writeInText, setWriteInText] = useState<string>();

  const choices = [
    { id: 'not-ready', name: 'Not ready to purchase', writeIn: false },
    { id: 'research', name: 'Just doing some research', writeIn: false },
    { id: 'missing-features', name: "Not seeing the features I'm looking for", writeIn: false },
    { id: 'other', name: 'Other', writeIn: true },
  ];

  return (
    <Transition.Root show={modalOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={callbackModal}>
        <div className="relative flex min-h-screen items-center justify-center px-4 pt-4 pb-20 text-center font-sofiapro sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block max-w-4xl transform overflow-hidden rounded-3xl text-left align-bottom shadow-xl transition-all sm:align-middle">
              <div className="fade-in-up-2 absolute right-0 top-0 flex flex-row gap-x-2">
                <div className="group cursor-pointer select-none p-2 text-sm font-medium opacity-80" onClick={callbackModal}>
                  <XMarkIcon className={classNames('h-5 w-5 text-milk')} />
                </div>
              </div>
              {!afterSubmit ? (
                <div className="grid grid-cols-2">
                  <div className="col-span-2 flex flex-col gap-y-2 bg-blueberry px-8 py-6 ">
                    <div className="flex flex-col items-center gap-x-2 text-center">
                      <img className="block w-12" src={logo} alt="Unwrap" />
                    </div>
                    <div className="flex flex-col gap-y-2 text-justify text-sm">
                      <p className="mt-4 text-center text-2xl text-milk">Leaving already?</p>
                    </div>
                  </div>
                  <div className="col-span-2 bg-milk px-8 py-6 text-blueberry ">
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        if (currentChoice) {
                          if (currentChoice.id === 'other' && writeInText) onConfirm(writeInText);
                          else onConfirm(currentChoice.name);
                        }
                      }}
                    >
                      <div className="flex flex-col">
                        <p className="text-center text-lg font-semibold">Can you help us understand why you didn't sign up for a demo today before you go?</p>
                        {choices.map((choice) => {
                          return (
                            <div
                              key={choice.id}
                              className="flex items-center justify-start py-4 px-2 hover:bg-gray-100"
                              onClick={(e) => {
                                e.preventDefault();
                                setCurrentChoice(choice);
                              }}
                            >
                              <input
                                id={choice.id}
                                type="radio"
                                required
                                value={choice.id}
                                checked={currentChoice?.id === choice.id}
                                name="options"
                                className="h-3 w-3 cursor-pointer border-gray-700 text-blueberry focus:ring-2 focus:ring-blue-300"
                              />
                              <label htmlFor={choice.id} className="ml-2">
                                {choice.name}
                              </label>
                              {choice.writeIn ? (
                                <input
                                  onChange={(e) => {
                                    setWriteInText(e.target.value);
                                  }}
                                  placeholder="Enter here..."
                                  required={currentChoice?.id === 'other'}
                                  className="focus:outline-nonebg-white ml-2 flex-grow resize-none resize-none rounded rounded-md border border-gray-300 py-2 px-3 text-blueberry shadow-sm focus:border-gray-300 focus:outline-none focus:ring-0 "
                                />
                              ) : null}
                            </div>
                          );
                        })}
                        <button
                          type="submit"
                          className="text-md mt-4 rounded-full bg-raspberry py-3 px-8 text-milk duration-200 hover:scale-105 hover:bg-blueberry"
                        >
                          <h1 className="text-lg font-semibold">Submit</h1>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="flex flex-col items-center gap-y-3 bg-blueberry px-6 py-14 lg:px-20">
                    <img className="block w-1/2 lg:w-1/3 " src={fullLogoMain2} alt="Unwrap" />
                    <h1 className="text-center text-xl font-medium text-milk ">Thanks, we take feedback very seriously.</h1>
                    <h2 className="text-md text-center font-medium text-milk ">We hope to help with yours someday!</h2>
                  </div>
                </div>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

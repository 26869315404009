import { useContext, useEffect, useState } from 'react';
import Badge from '../../../baseComponents/Badge';
import { DigestSeriesDataType, DigestDataActions, DigestDataDispatch } from '../../../reducers/digests/digestDataReducer';
import { PlusIcon } from '@heroicons/react/24/solid';
import Tippy from '@tippyjs/react';
import Button, { ButtonVariant } from '../../baseComponents/Button';
import Modal from '../../baseComponents/Modal';
import { Dialog } from '@headlessui/react';
import Form from '../../../baseComponents/Form';
import { RowLabel } from '../../baseComponents/RowWrapper';
import { VirtualizedComboBox } from '../VirtualizedComboBox';
import { getDropDownItemFromEnumSelection, getDropDownSelectionsFromEnum, getEnumValue } from '../../../reducers/utilities/enumHandling';
import {
  Channel_Type,
  GetDigestSeriesChannelsDocument,
  useCreateDigestSeriesChannelMutation,
  useDeleteDigestSeriesChannelMutation,
  useGetDigestSeriesChannelsQuery,
  useUpdateAutoPopulateSgListMutation,
} from '../../../generated/graphql';
import toast from 'react-hot-toast';
import AdjustableLoadingIcon from '../../../baseComponents/AdjustableLoadingIcon';
import Toggle from '../Toggle';
import { DigestDataDispatchContext } from '../../../context/digestDataContext';

const DigestSeriesMainSection = (props: { digestSeriesObject: DigestSeriesDataType }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { digestSeriesObject } = props;
  const dispatchDigestData = useContext(DigestDataDispatchContext) as DigestDataDispatch;
  const [updateAutoPopulateSGList] = useUpdateAutoPopulateSgListMutation();
  const [autoPopulateSGList, setAutoPopulateSGList] = useState(digestSeriesObject.autoPopulateSGList);

  useEffect(() => {
    setAutoPopulateSGList(digestSeriesObject.autoPopulateSGList);
  }, [digestSeriesObject]);

  const handleToggleSwitch = (state: boolean) => {
    setAutoPopulateSGList(!state);
    dispatchDigestData({
      type: DigestDataActions.UpdateAutoPopulateSGList,
      payload: {
        digestSeriesId: digestSeriesObject.id,
        autoPopulateSGList: !state,
      },
    });
    updateAutoPopulateSGList({ variables: { autoPopulateSgList: !state, updateAutoPopulateSgListId: digestSeriesObject.id } });
  };

  return (
    <div className="flex flex-col gap-y-2 bg-milk text-blueberry ">
      <CreateDigestSeriesChannelModal isModalOpen={isModalOpen} setOpen={setIsModalOpen} seriesId={digestSeriesObject.id} />
      {/* Title section */}
      <h1 className="text-3xl font-bold">{digestSeriesObject.title}</h1>
      <div className="text-md font-normal text-gray-400">
        {digestSeriesObject.organization.name}: ({digestSeriesObject.organization.id})
      </div>
      {/* Teams section */}
      <h1 className="text-2xl font-bold mt-10">Teams</h1>
      <div className="flex flex-row gap-x-4 gap-y-4">
        {digestSeriesObject.teams.map((team) => {
          return <Badge key={team.id} badge={{ text: `${team.name} (${team.id})`, id: team.id.toString() }} />;
        })}
      </div>
      {/* Digest Series Type */}
      <h1 className="text-2xl font-bold mt-10">Digest Series Type</h1>
      <div className="flex flex-row gap-x-4 gap-y-4">
        {digestSeriesObject.digestSeriesType}
      </div>
      {/* Channels */}
      <DigestSeriesChannels digestSeriesObject={digestSeriesObject} setIsModalOpen={setIsModalOpen} />
      <h2 className="text-xl font-bold mt-5">Auto Populate Email List</h2>
      <Toggle
        initialState={digestSeriesObject.autoPopulateSGList}
        value={autoPopulateSGList}
        onSwitch={handleToggleSwitch}
      />
    </div>
  );
};

const DigestSeriesChannels = (props: { digestSeriesObject: DigestSeriesDataType; setIsModalOpen: (open: boolean) => void }) => {
  const { digestSeriesObject, setIsModalOpen } = props;
  const channels = useGetDigestSeriesChannelsQuery({ variables: { seriesId: digestSeriesObject.id } });
  const [deleteChannel, deleteData] = useDeleteDigestSeriesChannelMutation();
  return (
    <>
      <h1 className="text-2xl font-bold mt-10">Digest Delivery</h1>
      <div className="flex flex-row gap-x-4 gap-y-4">
        Channels:
        {/* show list of channels for the series  */}
        {channels.loading && <AdjustableLoadingIcon color={'text-milk'} width={4} height={4} />}
        {channels.data?.digestSeriesChannels.map((channel) => {
          return (
            <Badge
              key={channel.id}
              badge={{ text: `${channel.subChannelName} (${channel.subChannelType})`, id: channel.id.toString() }}
              loading={deleteData.loading}
              onRemove={() => {
                deleteChannel({
                  variables: { channelId: channel.id, seriesId: digestSeriesObject.id },
                  refetchQueries: [{ query: GetDigestSeriesChannelsDocument, variables: { seriesId: digestSeriesObject.id } }],
                  awaitRefetchQueries: true,
                });
              }}
            />
          );
        })}
        <Tippy theme="light" delay={200} content={<p className="text-center">Add Channel</p>}>
          <div
            className="text-sm items-center p-0.5 bg-blueberry-lighter rounded-full cursor-pointer text-milk hover:bg-blueberry "
            onClick={() => setIsModalOpen(true)}
          >
            {/* <p>Add Sentence to Group</p> */}

            <PlusIcon id="add-group-sentence" className="h-5 w-5" />
          </div>
        </Tippy>
      </div>
    </>
  );
};

const CreateDigestSeriesChannelModal = (props: { seriesId: number; isModalOpen: boolean; setOpen: (open: boolean) => void }) => {
  const { seriesId, isModalOpen, setOpen } = props;
  const [channelType, setChannelType] = useState<Channel_Type>(Channel_Type.Sendgrid);
  const [channelValue, setChannelValue] = useState<string>('');
  const [createChannel, createResult] = useCreateDigestSeriesChannelMutation();

  const canCreateChannel = () => {
    return channelValue.length > 0;
  };

  const createDigestSeriesChannel = async () => {
    const result = await createChannel({
      variables: {
        channelType: channelType,
        seriesId,
        slack: channelType === Channel_Type.Slack ? { channelName: channelValue } : undefined,
        sendGrid: channelType === Channel_Type.Sendgrid ? { listId: channelValue } : undefined,
      },
      // refetch - channels
      refetchQueries: [{ query: GetDigestSeriesChannelsDocument, variables: { seriesId } }],
      awaitRefetchQueries: true,
    });
    if (result.data?.createDigestSeriesChannel) {
      toast.success(`Created channel ${result.data.createDigestSeriesChannel.subChannelName}`);
      setOpen(false);
    }
    if (result.errors) {
      toast.error(`Error creating channel: ${result.errors}`);
    }
    setChannelValue('');
  };

  const getFormButtons = () => {
    return (
      <div className="mt-4 grid grid-cols-3 justify-end gap-x-4 text-center">
        <div className="col-span-1">
          <Button expandWidth={true} variant={ButtonVariant.Cancel} onClick={() => setOpen(false)} text="Cancel"></Button>
        </div>
        <div className="col-span-2">
          <Button
            variant={ButtonVariant.Primary}
            text="Save"
            expandWidth={true}
            disabled={createResult.loading || !canCreateChannel()}
            onClick={() => {
              createDigestSeriesChannel();
            }}
            loadingConfirm={createResult.loading}
          ></Button>
        </div>
      </div>
    );
  };

  return (
    <Modal open={isModalOpen} setOpen={setOpen}>
      <div className="mt-3 text-center sm:mt-5 text-blueberry">
        {/* Title bar */}
        <Dialog.Title as="h1" className="text-3xl font-medium ">
          Create New Channel
        </Dialog.Title>
        <div className="flex flex-col gap-y-8 pt-5 h-full px-6">
          <div className="flex flex-row gap-x-2 items-center justify-between w-full">
            <RowLabel label="Channel Type" />
            <VirtualizedComboBox
              comboBoxData={getDropDownSelectionsFromEnum(Channel_Type)}
              setSelectedItem={(item) => {
                if (item) {
                  //@ts-ignore
                  setChannelType(getEnumValue(Channel_Type, item));
                }
              }}
              selectedItem={getDropDownItemFromEnumSelection(Channel_Type, channelType)}
              placeholder="Choose Channel"
            />
          </div>
          {channelType === Channel_Type.Sendgrid ? (
            <GetSendgridChannelValue channelValue={channelValue} setChannelValue={setChannelValue} />
          ) : (
            <GetSlackChannelValue channelValue={channelValue} setChannelValue={setChannelValue} />
          )}
        </div>
        <Form
          bottomRow={getFormButtons()}
          onSubmit={(e) => {
            // we don't want the modal to immediately close when the submit button is hit. this just prevents the modal closing prematurely.
            e.preventDefault();
          }}
        ></Form>
      </div>
    </Modal>
  );
};

const GetSendgridChannelValue = (props: { channelValue: string; setChannelValue: (value: string) => void }) => {
  const { channelValue, setChannelValue } = props;
  return (
    <div className="flex flex-row gap-x-2 items-center justify-between w-full">
      <RowLabel label="Sendgrid List Id" />
      <input
        type="text"
        className="rounded-md border border-gray-300 p-2 w-1/2"
        placeholder="Enter the sendgrid list id"
        value={channelValue}
        onChange={(e) => setChannelValue(e.target.value)}
      />
    </div>
  );
};

const GetSlackChannelValue = (props: { channelValue: string; setChannelValue: (value: string) => void }) => {
  const { channelValue, setChannelValue } = props;
  return (
    <div className="flex flex-row gap-x-2 items-center justify-between w-full">
      <RowLabel label="Slack Channel Name" />
      <input
        type="text"
        className="rounded-md border border-gray-300 p-2 w-1/2"
        placeholder="#channel-name"
        value={channelValue}
        onChange={(e) => setChannelValue(e.target.value)}
      />
    </div>
  );
};

export default DigestSeriesMainSection;

import React from 'react';

interface SkeletonBarProps {
  width?: string;
  height?: string;
  className?: string;
}

export const SkeletonBar: React.FC<SkeletonBarProps> = ({ width = 'w-full', height = 'h-6', className = '' }) => {
  return (
    <div
      className={`rounded-md bg-gray-200 relative 
        before:absolute before:inset-0
        before:-translate-x-full
        before:animate-[shimmer_2s_infinite]
        before:bg-gradient-to-r
        before:from-transparent before:via-white/60 before:to-transparent
        isolate overflow-hidden
        ${width} ${height} ${className}`}
    ></div>
  );
};
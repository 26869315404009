import { Context, createContext, Dispatch } from 'react';
import {
  SuggestedQuestionsDispatch,
  SuggestedQuestionsState, SuggestedQuestionsStatus, UpdateSuggestedQuestionsAction,
} from '../reducers/assistant/SuggestedQuestionsDispatch';


export const defaultSuggestedQuestionsState = {
  load: true,
  status: SuggestedQuestionsStatus.loading,
  questions: [],
  page: 0,
};
export const SuggestedQuestionsDataContext = createContext<SuggestedQuestionsState>(defaultSuggestedQuestionsState);

const defaultProvider : Dispatch<UpdateSuggestedQuestionsAction> = (({}) => {
  return defaultSuggestedQuestionsState;
});

export const SuggestedQuestionsDispatchContext: Context<Dispatch<UpdateSuggestedQuestionsAction>> = createContext<SuggestedQuestionsDispatch>(defaultProvider);
import { NavLink } from 'react-router-dom';
import { AppRoutes } from '../../Routes';
import LoadingSpinner from '../../v2/components/LoadingSpinner';
import { useValidTeamAppContext } from '../../v2/contexts/AppContext';
import Button, { ButtonVariant } from '../baseComponents/Button';
import { ScrollToTop } from '../baseComponents/ScrollToTop';
import { FeedbackEntriesList } from '../components/FeedbackEntriesList';
import { useFeedbackHook } from '../hooks/FeedbackHook';
import { useFilterHook } from '../hooks/FilterHook';
import { PageWrapper } from './PageWrapper';
import { SmallSpinner } from '../components/SmallSpinner';
import { useReplyHook } from '../hooks/ReplyHook';
import { FilterManager } from '../sections/Filters/ManagerV2/FilterManager';
import { FilterManagerDisplayMode } from '../sections/Filters/FiltersUtil';

interface FeedbackPageProps {
  pageName: string;
}

export const FeedbackPage = ({ pageName }: FeedbackPageProps) => {
  const { curTeamId: teamId, curOrgId: orgId } = useValidTeamAppContext();
  const filterHook = useFilterHook({ teamId, orgId });
  const feedbackHook = useFeedbackHook({ teamId, orgId, filterInput: filterHook.filters, repliableOnly: false });
  const { entries, entriesCount, totalEntriesCount } = feedbackHook;
  const { validIntegrations } = useReplyHook({});
  return (
    <PageWrapper title={'Feedback'}>
      <ScrollToTop />
      <div className="flex flex-col items-center justify-center">
        <div className="flex w-full max-w-[105rem] flex-col ">
          <div className="border-b border-gray-200 pb-2">
            <FilterManager filterHook={filterHook} pageName={pageName} dataTypeToFilter={'entries'} displayMode={FilterManagerDisplayMode.FeedbackPage} />
          </div>
          {feedbackHook.loadingStatuses.fetchingEntries && entries.length === 0 ? (
            <div className="flex flex-col items-center justify-center">
              <LoadingSpinner />
            </div>
          ) : (
            <div className="mt-2 flex flex-col items-center">
              {filterHook.filters?.queryString?.[0] ? (
                <p className="mb-2 text-sm lg:text-xs">
                  You're using simple search. For AI-powered smart search,{' '}
                  <NavLink data-testid="visit-explore-page" to={AppRoutes.v3FullPath.explore} className="font-semibold text-blue-800">
                    {' '}
                    visit the Explore page.{' '}
                  </NavLink>
                </p>
              ) : null}
              <div className="w-full">
                <FeedbackEntriesList entries={entries} validIntegrations={validIntegrations} />
              </div>
              {feedbackHook.loadingStatuses.fetchingAmounts ? (
                <div className="mt-4 flex flex-col items-center justify-center gap-y-2">
                  <SmallSpinner />
                </div>
              ) : (
                <div className="mt-4 flex flex-col items-center justify-center gap-y-2">
                  <h1 className="text-sm text-blueberry">
                    {totalEntriesCount === 0 ? (
                      'No feedback found on this view'
                    ) : entriesCount === 0 ? (
                      `No entries match the selected filters`
                    ) : (
                      <span>
                        Showing <span id="entries-shown">{entries?.length.toLocaleString('en-US')}</span> of{' '}
                        <span id="entries-matching">{entriesCount.toLocaleString('en-US')}</span> results matching filters
                        {entriesCount !== 0 ? <span id="total-entries"> ({totalEntriesCount.toLocaleString('en-US')} total)</span> : null}
                      </span>
                    )}
                  </h1>
                  {entries?.length !== entriesCount ? (
                    <Button
                      id="load-more"
                      variant={ButtonVariant.Primary}
                      text={'Load More'}
                      loadingText={'Loading...'}
                      onClick={() => feedbackHook.fetchMoreEntries(50)}
                      loadingConfirm={feedbackHook.loadingStatuses.fetchingEntries}
                    />
                  ) : null}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </PageWrapper>
  );
};

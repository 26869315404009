import { useState } from 'react';
import {
  Feedback_Integration_Type,
  Integration_Category,
  Integration_Type,
  Integration_Type_Requirement,
  useFeedbackIntegrationMutation,
} from '../../../../../generated/graphql';
import { useValidTeamAppContext } from '../../../../../v2/contexts/AppContext';
import Form from '../../../../../baseComponents/Form';
import FormInput from '../../../../../baseComponents/FormInput';
import SelectOrDragFile from '../../../FileUploaders/SelectOrDragFile';
import Button, { ButtonVariant } from '../../../../baseComponents/Button';
import { ApolloError } from '@apollo/client';
import { getIntegrationRequirementsToSend } from './forms/helpers';

interface ReplyIntegrationFormProps {
  modalOpen: boolean;
  setModalOpen: (open: boolean) => void;
  integration: Integration_Type;
  requirements: Integration_Type_Requirement[];
  setRequirements: (reqs: Integration_Type_Requirement[]) => void;
  setSuccessModalOpen?: (open: boolean) => void;
  skipValidation?: boolean;
}

export const ReplyIntegrationForm = ({ setModalOpen, requirements, setRequirements, integration, setSuccessModalOpen }: ReplyIntegrationFormProps) => {
  const { curTeamId: teamId } = useValidTeamAppContext();
  const [setFeedbackIntegration, mutation] = useFeedbackIntegrationMutation();
  const replyRequirements = requirements.filter((req) => req.category === 'reply');
  const fileRequirement = replyRequirements.find((requirement) => requirement.key.startsWith('play_store'));
  const [error, setError] = useState<string | undefined>(undefined);
  const handleUpload = async (file: File | undefined) => {
    if (!file) return;
    try {
      const result = (await handleFileUpload(file)) as { [index: string]: string };
      const updatedRequirements = requirements.map((requirement) => {
        if (requirement.key in result) {
          return {
            ...requirement,
            value:
              requirement.value.length === 1
                ? requirement.value.map((value) => {
                    return { ...value, value: result[requirement.key], requirement: requirement };
                  })
                : [{ value: result[requirement.key], id: 0, requirement: requirement }],
          };
        } else {
          return requirement;
        }
      });
      setRequirements(updatedRequirements);
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      }
    }
  };
  const handleFileUpload = (file: File | undefined) => {
    if (!file) return;
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = function (event) {
        try {
          // Parse JSON file content
          const jsonData = JSON.parse((event.target?.result ?? '') as string);
          const fileRequirements = replyRequirements.filter((req) => req.key.startsWith('play_store_'));
          // Extract necessary fields
          const result: { [index: string]: string } = {};
          fileRequirements.forEach((requirement) => {
            const key = requirement.key;
            const req = requirement.key.replace('play_store_', '');
            if (jsonData[req]) {
              result[key] = jsonData[req];
            }
          });
          if (Object.keys(result).length === fileRequirements.length) {
            resolve(result);
          } else {
            reject(new Error('Not all required fields were found in the JSON file.'));
          }
        } catch (error) {
          if (error instanceof Error) {
            reject(new Error('Failed to parse the file as JSON.' + error.message));
          }
        }
      };
      reader.onerror = function () {
        reject(new Error('Failed to read the file.'));
      };
      // Start reading the file
      reader.readAsText(file);
    });
  };

  const updateRequirementValue = (requirementToUpdate: Integration_Type_Requirement, newValue: string) => {
    setRequirements(
      requirements.map((requirement) => {
        if (requirement === requirementToUpdate) {
          return {
            ...requirement,
            value:
              requirement.value.length === 1
                ? requirement.value.map((value) => {
                    return { ...value, value: newValue, requirement: requirement };
                  })
                : [{ value: newValue, id: 0, requirement: requirement }],
          };
        }
        // if not the target requirement, just return it as is
        return requirement;
      })
    );
  };

  const updateFeedbackIntegration = async () => {
    const integs = integration.teamIntegration;
    await setFeedbackIntegration({
      variables: {
        input: {
          feedbackIntegrationId: integs[0]?.id ?? undefined,
          integrationTypeId: integration.id,
          teamId: teamId,
          requirements: getIntegrationRequirementsToSend(requirements.filter((req) => req.category === Integration_Category.Reply)),
        },
      },
      refetchQueries: ['Integrations'],
      onCompleted() {
        setModalOpen(false);
        setTimeout(() => {
          setSuccessModalOpen?.(true);
        }, 301);
      },
      onError(err: ApolloError) {
        setError(err.message);
      },
    });
  };

  const ButtonRow = () => {
    return (
      <div className="mt-8 flex flex-row justify-between gap-x-4 text-center">
        <Button
          variant={ButtonVariant.Tertiary}
          onClick={() => {
            setModalOpen(false);
          }}
          text="Cancel"
        ></Button>
        <Button
          variant={ButtonVariant.Primary}
          text="Submit"
          onClick={() => {
            updateFeedbackIntegration();
          }}
        ></Button>
      </div>
    );
  };
  return (
    <Form bottomRow={<ButtonRow />}>
      {error ? <p className="text-red-600">{error}</p> : <></>}
      {/* we expect exactly ONE file upload per integration */}
      {fileRequirement ? (
        <div key={fileRequirement.id}>
          <p className="mb-3 text-sm">{fileRequirement.description}</p>
          <SelectOrDragFile setFileToUpload={(file) => handleUpload(file)} />
        </div>
      ) : (
        <></>
      )}
      <div>
        {replyRequirements.map((requirement) => {
          if (requirement.key.includes('play_store')) return;
          return (
            <FormInput
              key={requirement.id}
              type={requirement.obfuscate ? 'password' : 'text'}
              description={requirement.description ?? ''}
              label={requirement.title ?? ''}
              placeholder={requirement.example ?? ''}
              value={requirement.value[0]?.value}
              onChange={(e) => {
                updateRequirementValue(requirement, e.target.value);
              }}
            />
          );
        })}
      </div>
    </Form>
  );
};

import { BookmarkIcon } from '@heroicons/react/24/outline';
import { BookmarkIcon as BookmarkIconSolid } from '@heroicons/react/24/solid';
import { GroupFull } from '../../../../../v2/hooks/GroupHook';
import { ExploreGroupHook } from '../../../../hooks/ExploreGroupHook';
import { HomeGroupHook } from '../../../../hooks/HomeGroupHook';
import { ToolbarBaseButton } from './ToolbarBaseButton';
import { useContext, useState } from 'react';
import { Resource, Action } from '../../../../../generated/graphql';
import { PermissionsContext } from '../../../../../v2/contexts/PermissionsContext';

export function PinGroupButton(props: { group: GroupFull; groupHook: ExploreGroupHook | HomeGroupHook; buttonRounding?: 'left' | 'right' }) {
  const [pinLoading, setPinLoading] = useState(false);
  const {hasPermission} = useContext(PermissionsContext);
  const disabledTooltip = 'You do not have permission to pin groups. Please contact your administrator and request the contributor role.';
  const handlePinGroup = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    setPinLoading(true);
    if (!props.group) return;
    props.groupHook.togglePinGroup(props.group.id, () => setPinLoading(false));
  };

  let icon = <BookmarkIcon className="h-5 w-5 self-center" id="pin-group" onClick={handlePinGroup} />;
  if (props.group?.pinnedByUser) icon = <BookmarkIconSolid className="h-5 w-5 self-center" id="unpin-group" onClick={handlePinGroup} />;

  const tooltipLabel = props.group?.pinnedByUser ? 'Unpin Group' : 'Pin Group';
  return (
    <ToolbarBaseButton
      id="toolbar-pin-group-button"
      icon={icon}    
      dataCy="toolbar-pin-group-button"
      label={tooltipLabel}
      onClick={handlePinGroup}
      loading={pinLoading}
      disabled={!hasPermission(Resource.PinnedGroups, Action.Update)}
      disabledTooltip={disabledTooltip}
      buttonRounding={props.buttonRounding}
    />
  );
}

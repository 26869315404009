import { toast } from 'react-hot-toast';
import { useState } from 'react';
import {
  ExternalTicketData,
  LinearTeam,
  useGetLinearTeamsLazyQuery,
  useGetLinearTicketsLazyQuery,
} from '../../../../generated/graphql';
import { useValidTeamAppContext } from '../../../../v2/contexts/AppContext';
import { BrowsingSection } from './BrowsingSection';
import { TicketLinker } from './TicketLinker';
import { toastTicketBrowsingError, toastTicketBrowsingPersistent, toastTicketBrowsingSuccess } from './helpers';

export const LinearBrowsing = ({
  linkTicket,
  linkingTicket,
}: {
  linkTicket: ({ ticketData }: { ticketData: ExternalTicketData }) => Promise<void>;
  linkingTicket?: boolean;
}) => {
  const { curOrgId: orgId } = useValidTeamAppContext();

  const [selectedTeam, setSelectedTeam] = useState<LinearTeam | null>(null);
  const [selectedTicketData, setSelectedTicketData] = useState<ExternalTicketData | null>(null);

  const [getLinearTeams, linearTeams] = useGetLinearTeamsLazyQuery({});
  const [getLinearTickets, linearTickets] = useGetLinearTicketsLazyQuery({});

  const allTeams = linearTeams.data?.getLinearTeams ?? [];
  const allTickets = linearTickets.data?.getLinearTickets ?? [];

  const searchTeam = async (queryString: string | undefined) => {
    if (!queryString) {
      setSelectedTeam(null);
      return;
    }
    const id = toastTicketBrowsingPersistent();
    await getLinearTeams({
      variables: { queryString, orgId },
      onCompleted: () => toastTicketBrowsingSuccess(),
      onError: () => toastTicketBrowsingError(),
    });
    toast.dismiss(id);
  };

  const searchTicket = async (queryString: string | undefined) => {
    if (!queryString) {
      setSelectedTicketData(null);
      return;
    }
    const id = toastTicketBrowsingPersistent();
    await getLinearTickets({
      variables: { queryString, orgId, linearTeamId: selectedTeam?.providerUniqueId },
      onCompleted: () => toastTicketBrowsingSuccess(),
      onError: () => toastTicketBrowsingError(),
    });
    toast.dismiss(id);
  };

  return (
    <div className="flex flex-col gap-y-2 px-2">
      <BrowsingSection
        title="Team"
        searchPlaceholder="Search Linear teams (optional)"
        comboBoxPlaceholder="team"
        onSearch={searchTeam}
        data={allTeams}
        selectedItem={selectedTeam ?? undefined}
        setSelectedItem={setSelectedTeam}
        loading={linearTeams.loading}
      />
      <BrowsingSection<ExternalTicketData>
        title="Issue"
        searchPlaceholder="Search Linear issues"
        comboBoxPlaceholder="issue"
        onSearch={searchTicket}
        data={allTickets}
        selectedItem={selectedTicketData}
        setSelectedItem={setSelectedTicketData}
        loading={linearTickets.loading}
      />
      <TicketLinker
        selectedTicketData={selectedTicketData}
        linkTicket={linkTicket}
        integrationLogo={'https://unwrap-public-assets.s3.us-east-2.amazonaws.com/linear-dark-logo.svg'}
        integrationTitle="Linear"
        loading={linkingTicket}
      />
    </div>
  );
};

import IntegrationCard from '../components/IntegrationCard';
import { useIntegrationsQuery, Integration_Type, Feedback_Integration_Type, useAmountOfEntriesQuery } from '../../generated/graphql';
import LoadingSpinner from '../../v2/components/LoadingSpinner';
import { PageWrapper } from './PageWrapper';
import { useValidTeamAppContext } from '../../v2/contexts/AppContext';
import { useEffect, useState } from 'react';
import InformationModal from '../components/Modals/InformationModal';
import YesCancelModal from '../components/Modals/YesCancelModal';
import { set } from 'lodash';
interface IntegrationsPageProps {
  pageName: string;
}

export interface IIntegrationRedirect {
  authToken: string;
  scraperKey: string;
  teamId: number;
  url_zendesk?: string;
  additionalObjects: { [key: string]: string };
}

/**
 * This controls which modal is open on the integrations page
 */
export enum IntegrationModalState {
  NoModal = 'no-modal',
  Success = 'success',
  Warning = 'warning',
  Card = 'card',
}

export default function IntegrationsPage({ pageName }: IntegrationsPageProps) {
  const { curTeamId: teamId, curOrgId: orgId } = useValidTeamAppContext();
  const [modalState, setModalState] = useState<IntegrationModalState | undefined>(IntegrationModalState.NoModal);
  const [currentModalId, setCurrentModalId] = useState<number | undefined>(undefined);
  const [redirect, setRedirect] = useState<IIntegrationRedirect | undefined>(undefined);
  const { data, loading, error } = useIntegrationsQuery({
    variables: { teamId: teamId, orgId, feedbackIntegrationType: Feedback_Integration_Type.DataImport },
  });
  const { data: amountOfEntries } = useAmountOfEntriesQuery({ variables: { teamId } });
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const urlObject = paramsToObject(urlSearchParams.entries());
    // pull out an authToken, teamId, and key from query
    const { authToken, key: scraperKey, teamId: teamIdQuery, ...others } = urlObject;
    // we have a valid redirect if all these be true
    let redir: IIntegrationRedirect | undefined = undefined;
    if (authToken && scraperKey && teamIdQuery && Number(teamIdQuery) === teamId) {
      redir = {
        authToken,
        scraperKey,
        teamId,
        additionalObjects: others,
      };
    }
    const url_zendesk = urlSearchParams.get('url_zendesk');
    if (redir && url_zendesk) redir.url_zendesk = url_zendesk;
    setRedirect(redir);
    //clear url params
    window.history.replaceState({}, '', window.location.pathname);
  }, []);

  if (error) return <>error</>;
  return (
    <PageWrapper title={'Integrations'}>
      <InformationModal
        modalOpen={modalState === IntegrationModalState.Success}
        callbackModal={() => {
          setModalState(undefined);
        }}
        text={'Integration connected successfully!'}
        subtext={'Your integration was created successfully! Please check your dashboard for insights in ~60 minutes.'}
      />
      <YesCancelModal
        text={`Are you sure you want to import this data into this view?`}
        subtext={`This integration already has feedback entries from other sources.\n\nYou should only import additional data into this View if you intend to analyze this data together with the data already in this view. Otherwise, please go to your Organizations page to create a new View first.`}
        confirmText="Yes, add CSV"
        callbackModal={() => {
          setModalState(undefined);
          setCurrentModalId(undefined);
        }}
        modalOpen={modalState === IntegrationModalState.Warning}
        confirmButton={() => {
          setModalState(IntegrationModalState.Card);
        }}
        closeOnConfirm={false}
        understandCheck={false}
      />

      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="grid grid-cols-2 gap-x-8 gap-y-8 pb-4 lg:grid-cols-3 xl:grid-cols-4">
          {data?.integrations?.map((integration, index) => (
            <IntegrationCard
              //@ts-ignore
              integration={integration}
              key={index}
              redirect={redirect}
              setSuccessModalOpen={() => {
                setModalState(IntegrationModalState.Success);
                setCurrentModalId(undefined);
              }}
              modalOpen={currentModalId === integration.id && modalState === IntegrationModalState.Card}
              setModalOpen={(openModal) => {
                handleOpenIntegrationCard(
                  //@ts-ignore
                  integration,
                  openModal,
                  amountOfEntries?.amountOfEntries ?? 0,
                  (state) => setModalState(state),
                  (val) => setCurrentModalId(val)
                );
              }}
            />
          ))}
        </div>
      )}
    </PageWrapper>
  );
}

const handleOpenIntegrationCard = (
  integration: Integration_Type,
  openModal: boolean,
  totalEntries: number,
  setModalState: (state: IntegrationModalState | undefined) => void,
  setCurrentModalId: (val: number | undefined) => void
) => {
  const showWarningForCSVImport = integration.scraperKey === 'CSVScraper' && totalEntries > 0;
  if (!openModal) {
    setModalState(undefined);
    setCurrentModalId(undefined);
  } else {
    if (showWarningForCSVImport) {
      setModalState(IntegrationModalState.Warning);
      setCurrentModalId(integration.id);
    } else {
      // open integration card
      setCurrentModalId(integration.id);
      setModalState(IntegrationModalState.Card);
    }
  }
};

export function paramsToObject(entries: IterableIterator<[string, string]>) {
  const result: Record<string, string> = {};
  for (const [key, value] of entries) {
    // each 'entry' is a [key, value] tupple
    result[key] = value;
  }
  return result;
}

/**
 * Tooling for handling enums and IDropDownItems
 *
 *
 */

import { IDropDownItem } from '../../baseComponents/DropDown';
import { EMPTY_DROPDOWN_ITEM } from './consts';

/**
 * Gets a list of drop down items given an enumeration.
 *
 * This is meant to be used when you have an enum from our graphql api and you need to choose one of the enum options.
 * You call this to get a list of the options and you call `getEnumValue` to get the actual chosen value from the selected IDropDownItem
 * @param enumeration
 * @returns
 */
export const getDropDownSelectionsFromEnum = <T extends Record<string, keyof any>>(enumeration: T): IDropDownItem[] => {
  return Object.keys(enumeration)
    .filter((key) => isNaN(Number(key))) // filters out the reverse mapping in numeric Enums
    .map((key, id) => ({ name: toPresentableCase(key), id })); // id is the order of the enum enumeration
};

export const getDropDownItemFromEnumSelection = <T extends Record<string, keyof any>>(enumeration: T, value: T[keyof T]): IDropDownItem => {
  const selections = getDropDownSelectionsFromEnum(enumeration);
  const enumAsArray = Object.values(enumeration);
  const selection = selections.find((item) => enumAsArray[item.id] === value);
  if (!selection) {
    throw new Error(`Could not find the appropriate selection value for ${value.toString()} in ${enumeration}`);
  }
  return selection;
};

/**
 * Takes a string 'TitleCase' and returns 'Title Case'. Takes a string snake_case and returns Snake Case
 *
 */
export const toPresentableCase = (titleCase: string) => {
  return titleCase
    .replace(/([A-Z])/g, ' $1') // insert a space before all capital letters
    .trim() // remove the leading space if it exists.
    .replace('_', ' ')
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

/**
 * Returns the actual enum selection given the enum type and the selected drop down item
 * @param enumeration
 * @param item
 * @returns
 */
export const getEnumValue = <T extends Record<string, string | number>>(enumeration: T, item: IDropDownItem): T[keyof T] => {
  assertDropDownItemValidEnumSelection(enumeration, item);
  const enumValues = Object.values(enumeration);
  return enumValues[item.id] as T[keyof T];
};

/**
 * This function and getDropDownSelectionsFromEnum work in tandem, that function assigns id's to be the index of the enum and this function makes sure that the id that comes back
 * matches the enum
 * @param enumeration
 * @param item
 */
export const assertDropDownItemValidEnumSelection = <T extends Record<string, keyof any>>(enumeration: T, item: IDropDownItem) => {
  if (item.id === EMPTY_DROPDOWN_ITEM.id) {
    // handle the case where an empty option is selected
    return;
  }

  const options = getDropDownSelectionsFromEnum(enumeration);
  const selected = options.find((option) => option.id === item.id);
  if (!selected) {
    throw new Error(`Cannot find option ${item.id} from the enumeration provided`);
  }
  if (selected.name !== item.name) {
    throw new Error(`The selected item ${item} does not match the option recieved from the enumeration with the same id ${selected}`);
  }
  return;
};

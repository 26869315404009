import { Context, createContext, Dispatch } from 'react';
import {
  AssistantAction,
  AssistantDispatch,
  AssistantState,
  AssistantStatus,
} from '../reducers/assistant/AssistantDispatch';


export const defaultAssistantState = {
  status: AssistantStatus.idle,
  summaries: [
  ],
  components: {
    'chartDummyId': {
      'type': 'chart',
      props: {
        'foo': {
          'bar': 'baz',
        }
      },
    },
  },
  question: '',
  userInput: '',
  questionId: 0
};
export const AssistantDataContext = createContext<AssistantState>(defaultAssistantState);

const defaultProvider : Dispatch<AssistantAction> = (({}) => {
  return defaultAssistantState;
});

export const AssistantDispatchContext: Context<Dispatch<AssistantAction>> = createContext<AssistantDispatch>(defaultProvider);
import React, { useContext, useState } from 'react';
import SearchInput from '../../baseComponents/SearchInput';
import { SuggestedQuestions } from './SuggestedQuestions';
import { AssistantActions } from '../../../reducers/assistant/AssistantDispatch';
import { AssistantDataContext, AssistantDispatchContext } from '../../../context/assistantContext';
import { Events, logEventWithContext } from '../../../v2/AnalyticsUtil';

export const UserInput = () => {

  const dispatch = useContext(AssistantDispatchContext);
  const {userInput} = useContext(AssistantDataContext);

  return (
    <div className="flex flex-col flex-wrap items-center justify-center m-5 my-8">
      <div className="w-3/4 row justify-center">
        <SearchInput
          onSearch={async (query) => {
            if (!userInput || userInput.length < 1) return;
            dispatch({ type: AssistantActions.SUBMIT_QUESTION, payload: { question: userInput } });
            logEventWithContext(Events.QueryAssistant, {});
          }}
          placeholder="Ask for insights..."
          queryString={userInput}
          onChange={(e) => {
            dispatch({ type: AssistantActions.UPDATE_USER_INPUT, payload: { userInput: e.target.value } });
          }}
        />
      </div>
      <SuggestedQuestions />
    </div>
  );
};

import { ReactNode, useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { BoardProvider, useBoardState } from '../../../context/boardContext';
import { Action, Resource, useGetBoardQuery } from '../../../generated/graphql';
import { AppRoutes } from '../../../Routes';
import { useValidTeamAppContext } from '../../../v2/contexts/AppContext';
import { PermissionsContext } from '../../../v2/contexts/PermissionsContext';
import { useIsMount } from '../../../v2/util';
import Button, { ButtonShape, ButtonSize, ButtonVariant } from '../../baseComponents/Button';
import LoadingSpinner from '../../baseComponents/LoadingSpinner';
import { WidgetsSection, WidgetsSectionSkeleton } from '../../components/boards/board/WidgetsSection';
import { FilterHook, useFilterHook } from '../../hooks/FilterHook';
import { buildFilterInputFromSavedFilterInput } from '../ChartsPage';
import { PageWrapper } from '../PageWrapper';
import { BoardFilterSection } from './sections/BoardFilterSection';
import { BoardHeaderSection } from './sections/BoardHeaderSection';
import { AddBoardWidgetModal } from '../../components/Modals/AddBoardWidgetModal';
import { BoardHeaderSectionSkeleton } from './sections/BoardHeaderSectionSkeleton';
import { BoardFilterSectionSkeleton } from './sections/BoardFilterSectionSkeleton';

export const BoardPage = () => {
  const { curTeamId: teamId } = useValidTeamAppContext();
  const { boardId: URLBoardId } = useParams();
  const boardId = Number(URLBoardId);
  const navigate = useNavigate();

  const { data: boardRes, loading: loadingBoard } = useGetBoardQuery({
    variables: {
      teamId,
      boardId,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const isFirstRender = useIsMount();
  useEffect(() => {
    if (!isFirstRender) navigate(AppRoutes.v3FullPath.boards, { replace: true });
  }, [teamId]);

  if (loadingBoard)
    return (
      <PageWrapper title="">
        <SkeletonPage
          backButton={
            <Button
              id="back-button"
              variant={ButtonVariant.Tertiary}
              shape={ButtonShape.Pill}
              size={ButtonSize.Small}
              text="Back"
              onClick={() => navigate(AppRoutes.v3FullPath.boards)}
              icon={<p>{'<'}</p>}
              iconPosition="left"
            />
          }
        />
      </PageWrapper>
    );

  if (!boardRes?.getBoard) {
    toast.error('Board not found');
    navigate(AppRoutes.v3FullPath.boards);
    return <></>;
  }

  return (
    <PageWrapper title="">
      <BoardProvider initialState={{ board: boardRes.getBoard }}>
        <BoardPageBody />
      </BoardProvider>
    </PageWrapper>
  );
};

const BoardPageBody = () => {
  const { curTeamId: teamId, curOrgId: orgId } = useValidTeamAppContext();
  const state = useBoardState();

  const filterHook = useFilterHook({
    teamId,
    orgId,
    startingFilterInput: buildFilterInputFromSavedFilterInput(state.board.filterInput ?? {}),
    respectUrlDatesOverStartingFilterInput: true,
  });

  return (
    <>
      <div className="flex flex-col gap-y-3">
        <BoardTopSection filterHook={filterHook} />
        <WidgetsSection filterHook={filterHook} />
      </div>
    </>
  );
};

const BoardTopSection = ({ filterHook }: { filterHook: FilterHook }) => {
  const [addWidgetModalOpen, setAddWidgetModalOpen] = useState(false);
  const { hasPermission } = useContext(PermissionsContext);

  return (
    <div className="flex flex-col gap-y-3">
      <AddBoardWidgetModal modalOpen={addWidgetModalOpen} setModalOpen={setAddWidgetModalOpen} />
      <BoardHeaderSection />
      <BoardFilterSection
        filterHook={filterHook}
        leftSideUI={
          <Button
            variant={ButtonVariant.Bordered}
            shape={ButtonShape.Pill}
            text="Add widget +"
            onClick={() => setAddWidgetModalOpen?.(true)}
            disabled={!hasPermission(Resource.Boards, Action.Create)}
          />
        }
      />
    </div>
  );
};

const SkeletonPage = ({ backButton }: { backButton?: ReactNode }) => {
  return (
      <div className="flex flex-col gap-y-3">
        <BoardHeaderSectionSkeleton backButton={backButton} />
        <BoardFilterSectionSkeleton />
    </div>
  );
};

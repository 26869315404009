import { Dispatch, ReactNode, createContext, useContext, useReducer } from 'react';
import { BoardAction, BoardReducer, BoardState } from '../reducers/boards/boardReducer';
import { initial } from 'lodash';

const BoardStateContext = createContext<BoardState | undefined>(undefined);
const BoardDispatchContext = createContext<Dispatch<BoardAction> | undefined>(undefined);

export const BoardProvider = ({ children, initialState }: { children: ReactNode; initialState: BoardState }) => {
  if (!initialState.board) throw new Error('BoardProvider must be initialized with a board');

  const [state, dispatch] = useReducer(BoardReducer, initialState);
  return (
    <BoardStateContext.Provider value={state}>
      <BoardDispatchContext.Provider value={dispatch}>{children}</BoardDispatchContext.Provider>
    </BoardStateContext.Provider>
  );
};

export const useBoardState = () => {
  const context = useContext(BoardStateContext);
  if (context === undefined) throw new Error('useBoardState must be used within an BoardProvider');

  return context;
};

export const useBoardDispatch = () => {
  const context = useContext(BoardDispatchContext);
  if (context === undefined) throw new Error('useBoardDispatch must be used within an BoardProvider');

  return context;
};

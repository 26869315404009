import { ArrowDownTrayIcon } from '@heroicons/react/24/solid';
import { toast } from 'react-hot-toast';
import { exportGroupWithProgressUpdate } from '../../eventHandlers/exports/groupExport';
import { GroupFull } from '../../v2/hooks/GroupHook';
import { ISettingsItem } from './SettingsMenu';
import { FilterHook } from '../hooks/FilterHook';
import { ProgressState, Status } from '../../exports/ProgressMonitor';
import { useState } from 'react';

interface ExportSettingsItemProps {
  id: number;
  group: GroupFull;
  curTeamId: number;
  filterHook: FilterHook;
  exportProgress: ProgressState;
  updateExportProgress: React.Dispatch<React.SetStateAction<ProgressState>>;
}

function isExporting(exportProgress: ProgressState): boolean {
  return exportProgress.status === Status.initializing || exportProgress.status === Status.processing;
}

/**
 * A settings item that exports to CSV. Can be reused in different settings menus.
 * @returns
 */
export const createExportSettingsItem = ({
  id,
  group,
  curTeamId,
  filterHook,
  exportProgress,
  updateExportProgress,
}: ExportSettingsItemProps): ISettingsItem => {
  const exportText = getExportText(exportProgress);

  return {
    id,
    name: exportText,
    group: 'actions',
    htmlId: 'export-group-csv',
    keepMenuOpen: true,
    disabled: isExporting(exportProgress),
    icon:
      exportProgress.status === Status.initializing || exportProgress.status === Status.processing ? (
        <div className="h-5 w-5  animate-spin rounded-full border-t-2 border-b-2 border-blueberry" />
      ) : (
        <ArrowDownTrayIcon className="h-5 w-5" />
      ),
    onClick: () => {
      exportGroupWithProgressUpdate(
        {
          groupTitle: group.title ?? '',
          teamId: curTeamId,
          groupIds: [group.id],
          filterInput: filterHook.filters,
        },
        exportProgress,
        updateExportProgress
      );
    },
  };
};

const getExportText = (exportProgress: ProgressState): string => {
  switch (exportProgress.status) {
    case Status.initializing:
      return 'Exporting...0%';
    case Status.processing:
      return `Exporting... ${exportProgress.percent}%`;
    default:
      return 'Export to CSV';
  }
};

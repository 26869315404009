import { useEffect } from 'react';
import { FilterInput, useAmountOfEntriesLazyQuery, useAmountOfEntriesQuery, useFeedbackEntriesLazyQuery } from '../../generated/graphql';
import { getTotalPageLoadEvent } from '../../latencyTracker';

interface FeedbackHookProps {
  teamId: number;
  orgId: number;
  userId?: number;
  filterInput?: FilterInput;
  repliableOnly?: boolean;
}

export type FeedbackHook = ReturnType<typeof useFeedbackHook>;

export const useFeedbackHook = ({ teamId, orgId, userId, filterInput, repliableOnly }: FeedbackHookProps) => {
  const [getFeedbackEntries, feedbackEntries] = useFeedbackEntriesLazyQuery({
    notifyOnNetworkStatusChange: true,
    nextFetchPolicy: 'cache-only',
    variables: { teamId, take: 50, filterInput, repliableOnly: repliableOnly ?? false },
  });

  const { data: origAmountOfEntriesData, loading: origAmountOfEntriesLoading } = useAmountOfEntriesQuery({
    fetchPolicy: 'network-only',
    variables: { teamId, repliableOnly: repliableOnly ?? false },
  });

  const [getAmountOfEntries, amountOfEntries] = useAmountOfEntriesLazyQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: { teamId, filterInput: filterInput ?? {}, repliableOnly: repliableOnly ?? false },
  });

  useEffect(() => {
    if (!feedbackEntries.data || feedbackEntries.data?.entries?.length === 0) {
      Promise.all([getAmountOfEntries(), getFeedbackEntries()]);
      const event = getTotalPageLoadEvent({ view: 'feedback' });
      window.dispatchEvent(event);
    } else {
    }
  }, []);

  const fetchMoreEntries = (amount?: number) => {
    feedbackEntries.fetchMore({ variables: { skip: feedbackEntries.data?.entries?.length, take: amount } });
  };

  const paginateEntries = async (amount: number, page: number) => {
    const skip = (page - 1) * amount;
    feedbackEntries.refetch({
      skip: skip,
      take: amount,
      teamId,
      filterInput,
    });
  };

  return {
    fetchMoreEntries,
    paginateEntries,
    entries: feedbackEntries.data?.entries ?? [],
    loadingStatuses: {
      fetchingEntries: feedbackEntries.loading,
      fetchingAmounts: amountOfEntries.loading || origAmountOfEntriesLoading,
    },
    entriesCount: amountOfEntries.data?.amountOfEntries.amount ?? 0,
    totalEntriesCount: origAmountOfEntriesData?.amountOfEntries.amount ?? 0,
  };
};
